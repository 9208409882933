import React, { useEffect } from "react"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
  LocalightAngleTitle,
  LocalightIconBig
} from "../../components/Client/Icons"

const Splash = props => {
  useEffect(() => {
    window.location.href = "https://join.localight.com"
  }, [])
  return (
    <div
      style={{
        backgroundColor: "#152A38",
        width: "100%  ",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingTop: 100
      }}
    >
      <div>
        <LocalightIconBig />
        <LocalightAngleTitle />
      </div>
    </div>
  )
}

export default Splash
