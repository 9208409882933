import React from "react"
import Link from "@mui/material/Link"

const OnboardStepsFinancial = ({ number, setStep }) => {
  return (
    <div className="single-layout-left-alternate steps">
      <Link
        href="/"
        underline="none"
        className="single-layout-logo w-inline-block"
      >
        <img
          src="/static/Localight-Logo-2021-wht.png"
          width="300"
          sizes="(max-width: 767px) 100vw, (max-width: 991px) 29vw, 27vw"
          srcSet="/static/Localight-Logo-2021-wht-p-500.png 500w, /static/Localight-Logo-2021-wht-p-800.png 800w, /static/Localight-Logo-2021-wht.png 1120w"
          alt=""
        />
      </Link>
      <div className="onboarding-steps-container">

        <div
          underline="none"
          aria-current="page"
          className={`onboarding-steps w-inline-block ${
            number === 1 && "w--current"
          }`}
        >
          <h6 className="white white-h6">Step 1</h6>
          <div className="text-large-steps">Welcome</div>
        </div>
        <div
          onClick={() => setStep(2)}
          underline="none"
          className={`onboarding-steps w-inline-block ${
            number === 2 && "w--current"
          }`}
        >
          <h6 className="white white-h6">Step 2</h6>
          <div className="text-large-steps">Connect Bank</div>
        </div>
        <div
          underline="none"
          className={`onboarding-steps w-inline-block ${
            number === 3 && "w--current"
          }`}
        >
          <h6 className="white white-h6">Step 3</h6>
          <div className="text-large-steps">Verify Business Details</div>
        </div>
        
        <div
          underline="none"
          className={`onboarding-steps w-inline-block ${
            number === 4 && "w--current"
          }`}
        >
          <h6 className="white white-h6">Step 4</h6>
          <div className="text-large-steps">Add Beneficial Owners</div>
        </div>
        


        <div underline="none" className={`onboarding-steps onboarding-steps-last w-inline-block ${
         number === 5 && "w--current" }`}>
           <h6 className="white white-h6">Step 5</h6>
           <div className="text-large-steps">Finalize</div>
        </div>

      </div>
    </div>
  )
}

export default OnboardStepsFinancial
