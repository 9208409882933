import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import Slide from "@mui/material/Slide"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import { useSwipeable } from "react-swipeable"
import Button from "@mui/material/Button"

import LottieAnimation from "../Animation"

import LocalightLogoIcon from "../../../assets/images/LocalightLogoIcon.svg"
import LocalightLogoIconReverse from "../../../assets/images/Localight-Logo-reverse.svg"
import BusinessAvatar from "../../../assets/images/BusinessAvatar.png"
// import ArrowUp from '../../../assets/images/ArrowUp.png';
// import PaidCheck from '../../../assets/images/PaidCheck.png';
import slideUpAnimation from "../../../assets/animations/slideUpAnimation.json"
import checkAnimation from "../../../assets/animations/checkAnimation.json"
import { getPathFromUrl } from "../../../utils/getURL"
import moment from "moment"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentModal = ({
  navigateProfile,
  lastTransaction,
  onSendMoney,
  customer,
  customerBanks,
  business,
  amount,
  open = false,
  handleClose
}) => {
  const [locationVerified, setLocationVerified] = useState(false)
  const [swipeUp, setSwipeUp] = useState(false)

  //const { latitude, longitude } = usePosition()

  const doSwipe = () => {
    setSwipeUp(true)
    //doLocationVerify(latitude, longitude)
    doLocationVerify()
  }

  const handlers = useSwipeable({
    onSwipedUp: doSwipe,
    onTap: doSwipe,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  const doLocationVerify = (latitude, longitude) => {
    // if (latitude && longitude) {
    //   onSendMoney(customerBanks?.dwolla_bank_id, business?.id, amount)
    //   setTimeout(() => {
    //     setLocationVerified(true)
    //   }, 1500)
    // } else {
    onSendMoney(customerBanks?.dwolla_bank_id, business?.id, amount)
    setTimeout(() => {
      setLocationVerified(true)
    }, 1500)
    // }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="payment-dialog"
      aria-describedby="payment-dialog-customer"
    >
      {!locationVerified ? (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            height: "100%",
            minHeight: "100vh",
            opacity: 1,
            background: "#152A38 0% 0% no-repeat padding-box"
          }}
        >
          {!swipeUp ? (
            <Container maxWidth="sm" disableGutters>
              <Box
                sx={{
                  minWidth: 240,
                  p: 2,
                  justifyContent: "center",
                  display: "flex"
                }}
                onClick={handleClose}
              >
                <img src={LocalightLogoIcon} alt="client-logo" width={90} />
              </Box>

              <Stack
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ marginTop: 2, p: 4 }}
              >
                <Avatar
                  alt="Business profile picture"
                  src={getPathFromUrl(business?.image) || BusinessAvatar}
                  sx={{
                    width: 110,
                    height: 110,
                    margin: 1,
                    border: "3px dotted #B1D5DD"
                  }}
                />

                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    opacity: 1,
                    letterSpacing: "-0.4px",
                    lineHeight: 1.3,
                    fontSize: "20px",
                    fontWeight: "bold"
                  }}
                >
                  {business?.name}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#56FFF6",
                  opacity: 1,
                  letterSpacing: "-0.9px",
                  lineHeight: 1.3,
                  my: 2,
                  fontSize: "45px",
                  fontWeight: "100"
                }}
              >
                {"$" + amount}
              </Typography>

              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Box
                  {...handlers}
                  sx={{
                    p: 2,
                    height: "200px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  <LottieAnimation
                    loop
                    height={200}
                    animationData={slideUpAnimation}
                  />
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    opacity: 1,
                    letterSpacing: "-0.4px",
                    lineHeight: 1.3,
                    fontSize: "16px",
                    fontWeight: "300"
                  }}
                >
                  Tap to confirm and pay.
                </Typography>
              </Stack>
            </Container>
          ) : (
            <Container maxWidth="sm" disableGutters>
              <Box
                sx={{
                  minWidth: 240,
                  p: 2,
                  justifyContent: "center",
                  display: "flex"
                }}
                onClick={handleClose}
              >
                <img src={LocalightLogoIcon} alt="client-logo" width={90} />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#56FFF6",
                  opacity: 1,
                  letterSpacing: "-0.42px",
                  lineHeight: 1.3,
                  my: 2,
                  fontSize: "21px",
                  fontWeight: "bold"
                }}
              >
                Verifying location…
              </Typography>

              <Stack
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ marginTop: 2, p: 4 }}
              >
                <Avatar
                  alt="Business profile picture"
                  src={getPathFromUrl(business?.image) || BusinessAvatar}
                  sx={{
                    width: 110,
                    height: 110,
                    margin: 1,
                    border: "3px dotted #B1D5DD"
                  }}
                />

                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    opacity: 1,
                    letterSpacing: "-0.4px",
                    lineHeight: 1.3,
                    fontSize: "20px",
                    fontWeight: "bold"
                  }}
                >
                  {business?.name}
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    opacity: 1,
                    letterSpacing: "-0.42px",
                    lineHeight: 1.3,
                    my: 2,
                    fontSize: "45px",
                    fontWeight: "100"
                  }}
                >
                  {"$" + amount}
                </Typography>
              </Stack>
            </Container>
          )}
        </Container>
      ) : (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            height: "100%",
            minHeight: "100vh",
            opacity: 1,
            background: "#edf3f4 0% 0% no-repeat padding-box"
          }}
        >
          <Container maxWidth="sm" disableGutters>
            <Box
              sx={{
                minWidth: 240,
                p: 2,
                justifyContent: "center",
                display: "flex"
              }}
              onClick={handleClose}
            >
              <img
                src={LocalightLogoIconReverse}
                alt="client-logo"
                width={120}
              />
            </Box>
            <Stack
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: 1, p: 1 }}
            >
              <Avatar
                alt="Business profile picture"
                src={getPathFromUrl(business?.image) || BusinessAvatar}
                sx={{
                  width: 110,
                  height: 110,
                  margin: 1,
                  border: "3px dotted #B1D5DD"
                }}
              />

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  opacity: 1,
                  letterSpacing: "-0.4px",
                  lineHeight: 1.3,
                  fontSize: "20px",
                  fontWeight: "bold"
                }}
              >
                {customer?.first_name || "Stephen"}{" "}
                {!lastTransaction?.data ? "failed to pay" : "paid"}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  opacity: 1,
                  letterSpacing: "-0.4px",
                  lineHeight: 1.3,
                  fontSize: "20px",
                  fontWeight: "bold"
                }}
              >
                {business?.name}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  opacity: 1,
                  letterSpacing: "-0.42px",
                  lineHeight: 1.3,
                  my: 1,
                  fontSize: "45px",
                  fontWeight: "100"
                }}
              >
                {"$" + amount}
              </Typography>
            </Stack>
            <Box
              sx={{
                p: 1,
                height: "200px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              {lastTransaction?.status === "S" && (
                <LottieAnimation
                  height={150}
                  width={150}
                  animationData={checkAnimation}
                  loop={false}
                />
              )}
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                color: "#000000",
                opacity: 1,
                letterSpacing: "-0.4px",
                lineHeight: 1.3,
                fontSize: "16px",
                fontWeight: "300"
              }}
            >
              Show screen to staff.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                color: "#000000",
                opacity: 1,
                letterSpacing: "-0.4px",
                lineHeight: 1.3,
                fontSize: "16px",
                fontWeight: "300"
              }}
            >
              {" "}
              Time:{" "}
              {lastTransaction?.data &&
                moment(lastTransaction?.data?.created_on).format("LT, MMM D")}
            </Typography>
            {lastTransaction && lastTransaction?.status !== "S" && (
              <Typography
                sx={{
                  textAlign: "center",
                  color: "red",
                  opacity: 1,
                  letterSpacing: "-0.4px",
                  lineHeight: 1.3,
                  fontSize: "12px",
                  fontWeight: "300"
                }}
              >
                There's an error, please go back & try again.
              </Typography>
            )}

            <Container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                display: "flex"
              }}
            >
              <Button
                variant="outlined"
                color="success"
                onClick={navigateProfile}
                sx={{
                  margin: 1
                }}
              >
                View Purchases
              </Button>
            </Container>
          </Container>
        </Container>
      )}
    </Dialog>
  )
}

export default PaymentModal
