import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import LocalightLogo from "../../../assets/images/Localight-Logo.svg"
import LocalightScript from "../../../assets/images/Localight-Script.svg"
import HeaderContainer from "./HeaderContainer"
import IconButton from "@mui/material/IconButton"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import { request } from "../../../utils/http"

const Header = ({
  navigateToHome,
  navigateJoinIn,
  navigateProfile,
  customer,
  customerType,
  navigateToDashbaord
}) => {
  const [points, setPoints] = useState(0)
  const getPoints = () => {
    request
      .get("/api/v1/get-customer-points")
      .then(res => {
        console.log(res)
        setPoints(res.data.total_points)
      })
      .catch(e => console.log(e.response.data))
  }

  useEffect(() => {
    getPoints()
  }, [])

  return (
    <HeaderContainer>
      <Box
        onClick={navigateToHome}
        sx={{
          p: 1
        }}
      >
        <img src={LocalightLogo} alt="client-logo" />
        <img src={LocalightScript} alt="client-script" />
      </Box>

      {customer?.id ? (
        customerType === "business" ? (
          <IconButton
            onClick={navigateToDashbaord}
            aria-label="profile"
            size="large"
            sx={{ color: "#fff" }}
          >
            <span style={{ fontSize: "18px" }}> {customer?.first_name} </span>{" "}
            <AccountCircleRoundedIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {customerType === "customer" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  alignSelf: "flex-end",
                  marginRight: 20
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <LocationOnIcon sx={{ color: "#84694D" }} />
                  <p style={{ color: "#FFF", marginBottom: 0 }}>{points}</p>
                </div>

                <p style={{ color: "#84694D" }}>POINTS</p>
              </div>
            ) : null}
            <IconButton
              onClick={navigateProfile}
              aria-label="profile"
              size="large"
              sx={{ color: "#fff" }}
            >
              <span style={{ fontSize: "18px" }}> {customer?.first_name} </span>{" "}
              <AccountCircleRoundedIcon fontSize="inherit" />
            </IconButton>
          </div>
        )
      ) : (
        <Button
          onClick={navigateJoinIn}
          sx={{
            m: 1,
            borderRadius: 20,
            backgroundColor: "#ff4824",
            color: "#FFF"
          }}
        >
          Join for Free
        </Button>
      )}
    </HeaderContainer>
  )
}

export default Header
