import React, { useEffect } from "react"
import Link from "@mui/material/Link"
import Avatar from "@mui/material/Avatar"
import { useDispatch, useSelector } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import moment from "moment"
import { GET_TRANSACTIONS } from "../../store/payment/constants"

const CustomersContainer = props => {
  const dispatch = useDispatch()
  const transactions = useSelector(state => state.paymentReducer.transactions)

  useEffect(() => {
    dispatch({ type: GET_TRANSACTIONS })
  }, [])

  return (
    <>
      <div className="app-main-layout">
        <div className="app-main-layout-header">
          <div className="app-main-layout-wrapper header">
            <div className="page-heading">
              <h2 className="heading-3">Customers</h2>
            </div>
            <div className="page-tabs-menu">
              <div
                aria-current="page"
                className="page-tab-link traffic w--current"
              >
                Overview
              </div>
              {/* <div className="page-tab-link revenue">Purchases</div> */}
              {/* <div className="page-tab-link projects">Offers</div> */}
            </div>
          </div>
        </div>
        <div className="app-main-layout-wrapper">
          <div className="app-main-layout-content">
            <div className="page-tabs-content">
              <div className="section">
                <div className="section-inner">
                  {transactions && !!transactions?.data?.length && (
                    <div className="card no-padding receipt-table">
                      <div className="receipt-row">
                        <div className="card-setting-text caption">
                          Recent Customers
                        </div>
                        <div className="card-setting-text caption">
                          {" "}
                          Time &amp; Date
                        </div>
                        <div className="card-setting-text caption">Amount</div>
                        <div className="card-setting-switcher">
                          <div className="receipt-download hidden"></div>
                        </div>
                      </div>

                      {transactions?.data?.map(item => (
                        <div className="receipt-row">
                          <div className="customer-photo-container">
                            <Avatar>
                              {item?.customer_name.substring(0, 1)}
                            </Avatar>
                            {/* <div className="online-status-2 on"></div> */}
                          </div>
                          <div className="card-setting-text">
                            {item?.customer_name}
                          </div>
                          <div className="card-setting-text">
                            {moment(item?.created_on).format("LT, MMM D")}
                          </div>
                          <div className="card-setting-text primary-text">
                            ${parseFloat(item?.amount).toFixed(2)}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {transactions?.data?.length === 0 &&
                    transactions?.status !== "L" && (
                      <div className="card no-padding receipt-table">
                        <div className="receipt-row">No customers found.</div>
                      </div>
                    )}
                  {transactions?.status === "L" && (
                    <div className="card no-padding receipt-table">
                      <div className="receipt-row">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="w-row">
              <div className="w-col w-col-6">
                <div className="hint">
                  © Copyright Localight Inc. All rights reserved.
                </div>
              </div>
              <div className="footer-right w-col w-col-6">
                <div className="hint">
                  <Link underline="none" href="#" className="footer-link">
                    Spotlight Business
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomersContainer
