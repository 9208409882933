import { put, call, spawn, all, takeEvery } from "redux-saga/effects"
import { AuthApiServices } from "./services"
import * as types from "./constants"
import * as actions from "./actions"
import * as StoreUtil from "../storeUtil"
import { persistor } from "../../store"
import { GET_COMPANY, GET_CATEGORIES } from "../company/constants"
import { GET_CUSTOMER } from "../customer/constants"
import { GET_TRANSACTIONS } from "../payment/constants"
import { push } from "connected-react-router"

function* doOtpLoginWorker(action) {
  try {
    StoreUtil.logAction("Login Customer", action)
    console.log("login...")
    const result = yield call(AuthApiServices.doCustomerLogin, action)

    if (result?.token) {
      yield put(actions.doOtpLoginSucceeded(result))
      yield put({ type: GET_CUSTOMER, id: result?.customer_id })
    }
  } catch (err) {
    StoreUtil.logError("Loing Failed:", err)
    yield put(actions.doOtpLoginFailed(err))
  }
}
function* doOtpLoginWatcher() {
  yield takeEvery(types.DO_OTP_LOGIN, doOtpLoginWorker)
}

function* doLoginWorker(action) {
  try {
    StoreUtil.logAction("Login", action)
    console.log("login...")
    const result = yield call(AuthApiServices.doLogin, action)

    console.log("this result", result)

    if (result?.token) {
      yield put({ type: GET_COMPANY })
      yield put({ type: GET_CATEGORIES })
      yield put({ type: GET_TRANSACTIONS })
      yield put(push("/dashboard"))
      yield put(actions.doLoginSucceeded(result))
    }
  } catch (err) {
    StoreUtil.logError("Loing Failed:", err)
    yield put(actions.doLoginFailed(err))
  }
}
function* doLoginWatcher() {
  yield takeEvery(types.DO_LOGIN, doLoginWorker)
}

function* doLogoutWorker(action) {
  try {
    StoreUtil.logAction("Logout", action)
    localStorage.clear()
    const result = yield call(AuthApiServices.doLogout, action)
    console.log("DOING LOGOUT")
    console.log(result)
    if (result.success) {
      persistor.purge()
      yield put(actions.doLogoutSuccess(result))
    }
  } catch (err) {
    StoreUtil.logError("Logout Failed:", err)
    yield put(actions.doLoginFailed(err))
  }
}
function* doLogoutWatcher() {
  yield takeEvery(types.DO_LOGOUT, doLogoutWorker)
}

function* doFBLoginWorker(action) {
  try {
    StoreUtil.logAction("FBLogin", action)
    const result = yield call(AuthApiServices.doFacebookLogin, action)
    yield put(actions.doFBLoginSucceeded(result))
  } catch (err) {
    StoreUtil.logError("FBLogin: ", err)
    yield put(actions.doFBLoginFailed(err))
  }
}
function* doFBLoginWatcher() {
  yield takeEvery(types.DO_FBLOGIN, doFBLoginWorker)
}

function* doGoogleLoginWorker(action) {
  try {
    StoreUtil.logAction("GoogleLogin", action)
    const result = yield call(AuthApiServices.doGoogleLogin, action)
    if (result?.access_token) {
      yield put(actions.doGoogleLoginSucceeded(result))
      yield put({ type: GET_COMPANY })
      yield put({ type: GET_CATEGORIES })
      yield put(push("/dashboard"))
    }
  } catch (err) {
    StoreUtil.logError("GoogleLogin: ", err)
    yield put(actions.doGoogleLoginFailed(err))
  }
}
function* doGoogleLoginWatcher() {
  yield takeEvery(types.DO_GOOGLELOGIN, doGoogleLoginWorker)
}

function* doRegisterWorker(action) {
  try {
    StoreUtil.logAction("Register", action)
    const result = yield call(AuthApiServices.doRegister, action)
    if (result?.id) {
      yield put(actions.doRegisterSucceeded(result))
      yield put({ type: "SET_STATUS", status: "S" })
      yield put(push("/dashboard/login"))
    }
  } catch (err) {
    StoreUtil.logError("Register: ", err)
    yield put(actions.doRegisterFailed(err))
  }
}
function* doRegisterWatcher() {
  yield takeEvery(types.DO_REGISTER, doRegisterWorker)
}

function* doChangeProfile(action) {
  try {
    StoreUtil.logAction("Change profile", action)
    const result = yield call(AuthApiServices.doChangeProfile, action)
    yield result
  } catch (err) {
    console.log(err)
    yield null
  }
}
function* doChangeProfilerWatcher() {
  yield takeEvery(types.CHANGE_PROFILE, doChangeProfile)
}

function* doChangePassword(action) {
  try {
    StoreUtil.logAction("Change pwd", action)
    const result = yield call(AuthApiServices.doChangePassword, action)
    console.log("password changed", result)
    return result
  } catch (err) {
    console.log(err)
    yield null
  }
}
function* doChangePasswordWatcher() {
  yield takeEvery(types.CHANGE_PASSWORD, doChangePassword)
}

function* doPwdResetWorker(action) {
  try {
    StoreUtil.logAction("PwdReset", action)
    const result = yield call(AuthApiServices.doPwdReset, action)
    yield put(actions.doPwdResetSucceeded(result))
  } catch (err) {
    StoreUtil.logError("PwdReset", err)
    yield put(actions.doPwdResetFailed(err))
  }
}
function* doPwdResetWatcher() {
  yield takeEvery(types.DO_PWDRESET, doPwdResetWorker)
}

// export default authRootSagas;

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* authRootSagas() {
  const sagas = [
    // Example watcher
    // getBalanceWatcher
    doOtpLoginWatcher,
    doLoginWatcher,
    doRegisterWatcher,
    doPwdResetWatcher,
    doFBLoginWatcher,
    doGoogleLoginWatcher,
    doChangeProfilerWatcher,
    doChangePasswordWatcher,
    doLogoutWatcher
  ]
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}
