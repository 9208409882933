import React, { useEffect, useState } from "react"
// import Link from '@mui/material/Link';
import { useDispatch, useSelector } from "react-redux"
import Container from "@mui/material/Container"
import { DO_OTP_LOGIN } from "../../../store/auth/constants"
import {
  REMOVE_BANK_INFO,
  GET_PLAID_LINK_TOKEN,
  SEND_MONEY_REQUEST,
  GET_USER_BANK
} from "../../../store/payment/constants"
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import MerchantHero from '../../../assets/images/MerchantHero.png';
// import { CompanyApiServices } from '../../../store/company/services';
import CustomerJoin from "../../../components/Client/CustomerJoin"
import {
  SET_CUSTOMER_BUSINESS,
  GET_CUSTOMER
} from "../../../store/customer/constants"
import { push } from "connected-react-router"

import { CompanyApiServices } from "../../../store/company/services"

const AuthContainer = props => {
  const dispatch = useDispatch()
  const loginStatus = useSelector(state => state.authReducer.login.status)
  const customerToken = useSelector(state => state.authReducer.token)
  const banks = useSelector(state => state.paymentReducer.customerBanks.data)
  const plaidLink = useSelector(state => state.paymentReducer.plaidLink.data)
  const businessID = useSelector(state => state.customerReducer.business.id)
  const customer = useSelector(state => state.customerReducer.customer.data)
  const user = useSelector(state => state.authReducer.user)
  const customerType = useSelector(state =>
    state.authReducer.user && state.authReducer.user.type
      ? state.authReducer.user.type
      : ""
  )
  const plaid = useSelector(state => state.paymentReducer.plaid.data)
  const lastTransaction = useSelector(
    state => state.paymentReducer.lastTransaction
  )

  const customerID = user?.customer_id || null

  useEffect(() => {
    if (!plaidLink && customerToken && loginStatus === "S")
      dispatch({ type: GET_PLAID_LINK_TOKEN })
  }, [dispatch, plaidLink, customerToken, loginStatus])

  const removeBankInfo = () => {
    dispatch({ type: REMOVE_BANK_INFO })
  }

  useEffect(() => {
    getBusinessess()
  }, [])

  const [business, setBusiness] = useState(null)
  const [businesses, setBusinessess] = useState(null)

  useEffect(() => {
    if (businessID) getBusiness(businessID)
  }, [businessID])

  useEffect(() => {
    if (customerID && !customer) {
      dispatch({ type: GET_CUSTOMER, id: customerID })
    }
    if (customerID) {
      dispatch({ type: GET_USER_BANK, id: customerID })
    }
  }, [customerID, customer, dispatch])

  const getBusiness = async businessID => {
    const result = await CompanyApiServices.getCompanyDetails({
      id: businessID
    })
    console.log(result, "business info")
    if (result) {
      setBusiness(result)
    }
  }

  const getBusinessess = async () => {
    const result = await CompanyApiServices.getAllCompany()
    console.log(result, "businessess")
    if (result.length) {
      setBusinessess(result)
    }
  }

  const onBusinessChange = id => {
    dispatch({ type: SET_CUSTOMER_BUSINESS, id: id })
  }

  const onSendMoney = (bank_id, company_id, amount) => {
    dispatch({
      type: SEND_MONEY_REQUEST,
      bank_id: bank_id || banks[0]?.dwolla_bank_id,
      company_id: company_id,
      amount: parseFloat(amount)
    })
  }

  const doCustomerLogin = (otp_code, mobile_number) => {
    dispatch({
      type: DO_OTP_LOGIN,
      otp_code: otp_code,
      mobile_number: mobile_number
    })
  }

  const navigateProfile = () => {
    dispatch(push(`/customers/transactions`))
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
        minHeight: "100vh",
        opacity: 1,
        background: "#152A38 0% 0% no-repeat padding-box"
      }}
    >
      <CustomerJoin
        customer={customer}
        navigateProfile={navigateProfile}
        customerID={customerID}
        onSendMoney={onSendMoney}
        lastTransaction={lastTransaction}
        business={business}
        onBusinessChange={onBusinessChange}
        businesses={businesses}
        plaidLink={plaidLink}
        plaid={plaid}
        customerType={customerType}
        customerBanks={banks}
        doCustomerLogin={doCustomerLogin}
        removeBankInfo={removeBankInfo}
      />
    </Container>
  )
}

export default AuthContainer
