const baseURL = process.env.REACT_APP_API_URL

export const appConfig = {
  // todo add library to handle env variables
  baseURL:
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "" ||
    window.location.hostname === "https://localight-25800-staging.botics.co"
      ? "https://localight-25800-staging.botics.co"
      : "https://localight-25800.botics.co",
  clientID: "wAHzz52cGHwFoZya7VjkQvRjwhDDfTUVEJ5qYqHn",
  clientSecret:
    "YwCtCu9CLJTdJ4UvacJZJNob0N6x3IcRHyVxt3N2Z3USEQaiAqVLHsWGdtPEq9JhQMrvzvrBA4a1y1yZyWIDqG3QAAEFXD0molDe6rg6UGsPvdtBVb8oRzQ4plV99DNb",
  defaultTimeout: 0,
  pubPublishKey: "",
  pubSubscribeKey: ""
}
