import React from "react";

import 'react-spring-bottom-sheet/dist/style.css';

import BusinessContainer from '../../containers/Client/BusinessContainer';

const BusinessLanding = (props) => {


    return <>
        <BusinessContainer {...props} />
    </>
};

export default BusinessLanding;
