import React from 'react'
import Lottie from 'react-lottie';


const LottieAnimation = ({loop=false, height=300, width=300, animationData, isStopped=false, isPaused=false}) => {

    const defaultOptions = {
        loop: loop,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return  <Lottie options={defaultOptions}
    height={height}
    width={width}
    isStopped={isStopped}
    isPaused={isPaused}/>
}

export default LottieAnimation;
