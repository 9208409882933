import * as types from "./constants"

export const updateCompany = (data) => ({
  type: types.UPDATE_COMAPNY, data
});

export const getCompany = () => ({
  type: types.GET_COMPANY
})

export const setCompany = response => ({
  type: types.SET_COMAPNY, response
})

export const gsuCompanyFailed = error => ({
  type: types.GSU_COMPANY_FAILED, error
})

export const addCompany = (data) => ({
  type: types.ADD_COMAPNY, data
});

export const getLocation = () => ({
  type: types.GET_LOCATION
})

export const setLocation = response => ({
  type: types.SET_LOCATION, response
})

export const updateLocation = data => ({
  type: types.UPDATE_LOCATION, data
})


export const gsuLocationFailed = (error) => ({
  type: types.GSU_LOCATION_FAILED, error
})



export const getCategories = () => ({
  type: types.GET_CATEGORIES
})

export const setCategories = response => ({
  type: types.SET_CATEGORIES, response
})

export const getCategoriesFailed = error => ({
  type: types.GS_CATEGORIES_FAILED, error
})


export const setProfilePicture = (data) => ({
  type: types.SET_PROFILE_PIC, data
})


export const setCoverPicture = (data) => ({
  type: types.SET_COVER_PIC, data
})