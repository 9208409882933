import * as types from "./constants"
import * as status from "../constants"
import * as StoreUtil from "../storeUtil"

const initialState = {
  errorMessage: null,
  customer: {
    data: null,
    status: null
  },
  business: {
    id: null,
    data: null
  }
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER:
      return {
        ...state,
        customer: {
          status: status.LOADING,
          data: null
        }
      }
    case types.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          status: status.SUCCESS,
          data: action.response
        }
      }
    case types.GET_CUSTOMER_FAILED:
      return {
        ...state,
        customer: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        }
      }
    case types.SET_CUSTOMER_BUSINESS:
      return {
        ...state,
        business: {
          id: action.id
        }
      }
    case types.REMOVE_CUSTOMER_BUSINESS:
      return {
        ...state,
        business: {
          id: null,
          data: null
        }
      }
    case types.DO_LOGOUT:
      return {
        ...state,
        customer: {}
      }
    default: {
      return state
    }
  }
}

export default customerReducer
