let x = 0
let y = 0
let week = 0
let daily = 0
let monthly = 0
export function date_calc(tdata2){
  let week_total = 0
  let day_total = 0
  let month_total = 0
  
  let tdata = require('./sampdata.json')

  let num = 0
  const dayOfYear1 = date =>{x = (Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24))}
  const dayOfYear2 = date =>{y = (Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24))}
  
  tdata.forEach(item =>   
    {
      let in_date = item['created_on']
      //console.log("num is: "  + num)
      let year = in_date.substring(0, 4 ) 
      let month = in_date.substring(5,7)
      let day = in_date.substring(8,10)
      
      //console.log(in_date)
      //console.log(year, month, day)
      //console.log(month+'/'+day+'/'+year)


      var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      let year2 = utc.substring(0,4)
      utc = utc.substring(5,10) + "/" + year2
      //console.log('utc: '+utc)
      dayOfYear1(new Date(month+'/'+day+'/'+year))

      
      dayOfYear2(new Date(utc))
      
      
      let difference = x - y
      
      

      //negative means the date happened before also resets every year
      if (year === year2){
        if (difference >= -7 && difference <=0){
          //console.log('In the week')
          week_total = parseInt(tdata[num]['amount']) + week_total

        }
        if(difference >= -30 && difference <=0){
          //console.log('In the month')
          month_total = parseInt(tdata[num]['amount']) + month_total
        }
        if(difference >=0 && difference <=0){
          //console.log('In the Day')
          day_total = parseInt(tdata[num]['amount']) + day_total
        }
      }
      //console.log("week = " + week_total)
      //console.log("month = " + month_total)
      //console.log("day = " + day_total)
      week = ("$" + week_total + ".00")
      monthly = ("$" + month_total + ".00")
      daily = ("$" + day_total + ".00")
      
      //console.log(difference)

      //console.log(x)
      //console.log(y)
    num = num + 1
    })
    //console.log('hello')
    return ([daily, week, monthly])
}


 