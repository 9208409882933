import React from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useForm } from "react-hook-form";

const LocationAddDialog = ({open, handleClose}) => {

  const { handleSubmit, register, formState: { errors }  } = useForm();

  const submitCompanyLocation = async (data) => {

  }

  return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new location</DialogTitle>
        <form id="location-form" name="location-form" onSubmit={handleSubmit(submitCompanyLocation)}>
        <DialogContent>
          <DialogContentText>
            Please fill out the address to add a new location to the existing company.
          </DialogContentText>
            <div className="w-form">
              <label htmlFor="location_name" className="label">Location Name</label>
                    <input 
                      type="text"  
                      aria-invalid={errors.location_name ? "true" : "false"}
                      {...register("location_name", {
                        required: "Company city is required.",
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="location_name"  
                      placeholder="Location name" 
                      id="location_name"
                      />
                      {errors.location_name &&
                      <div className="error-message">
                        <div>{errors.location_name.message}</div>
                      </div>
                      }
                    <label htmlFor="company_locatedin" className="label">Located in</label>
                    <input 
                      type="text"  
                      aria-invalid={errors.company_locatedin ? "true" : "false"}
                      {...register("company_locatedin", {
                        required: "Company location is required.",
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="company_locatedin"  
                      placeholder="Located in" 
                      id="company_locatedin"
                      />
                      {errors.company_locatedin &&
                      <div className="error-message">
                        <div>{errors.company_locatedin.message}</div>
                      </div>
                      }
                    <label htmlFor="company_address" className="label">Complete Address</label>
                    <input 
                      type="text"  
                      aria-invalid={errors.company_address ? "true" : "false"}
                      {...register("company_address", {
                        required: "Company address is required.",
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="company_address"  
                      placeholder="Complete Address" 
                      id="company_address"
                      />
                      {errors.company_address &&
                      <div className="error-message">
                        <div>{errors.company_address.message}</div>
                      </div>
                      }
                    <label htmlFor="location_description" className="label">Location description</label>
                    <textarea 
                      aria-invalid={errors.location_description ? "true" : "false"}
                      {...register("location_description", {
                        required: "Location description is required.",
                      })}
                      className="textarea w-input" 
                      maxLength="5000" 
                      name="location_description"  
                      placeholder="Enter Location details" 
                      id="location_description"
                      />
                      {errors.location_description &&
                      <div className="error-message">
                        <div>{errors.location_description.message}</div>
                      </div>
                      }
              </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} variant="outlined">Add</Button>
        </DialogActions>
        </form>
      </Dialog>
  );
}

export default LocationAddDialog;