import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../../utils/http"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import ReactInputDateMask from "react-input-date-mask"
import loadingAnimation from "../../assets/animations/loadingAnimation15.json"
import LottieAnimation from "../../components/Client/Animation"
//import { formValues } from "./VerifiedCustomer/index.js"

const BeneficialOwner = ({ customer, postDwollaCustomer, formValues, setStep, setDisplayBeneficialOwners, setBeneficialOwnerAdded, checked }) => {

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm()

  const [owners, setOwners] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [selectedForDocumentUpload, setSelectedForDocumentUpload] = useState("");
  const [showDocumentUpload, setShowDocumentUpload] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [agree, setAgree] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const [selectedFile, setSelectedFile] = useState()
  const [documentType, setDocumentType] = useState("license")

  const [isDateIsValid, setIsDateIsValid] = useState(false)
  const [fileName, setFileName] = useState("Upload Document")
  const [documentsUploaded, setDocumentsUploaded] = useState([])
  const [isControllerOwnerResponded, setIsControllerOwnerResponded] = useState(false)

  const updateDate = date => {
      if (dateIsValid(date)){
          setIsDateIsValid(true)
          setStartDate(Date.parse(date))
      }
    }

  const dateIsValid = date => {
      return !Number.isNaN(new Date(date).getTime());
    }

  const checkDocumentUploaded = (id) => {
    console.log(documentsUploaded);
    return documentsUploaded.includes(String(id));
  }

  const isControllerOwnerResponse = response => {
    if (response === true) {
        setIsControllerOwnerResponded(true)
        if (formValues.businessType === 'soleProprietorship') {
            const owner = {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                dateOfBirth: formValues.dateOfBirth,
                ssn: formValues.ssn,
                address: {
                    address1: formValues.address1,
                    city: formValues.city,
                    stateProvinceRegion: formValues.state,
                    postalCode: formValues.postalCode,
                    country: formValues.country
                }
            }
            postBeneficialOwner(owner)
        } else {
            const owner = {
                firstName: formValues.controller.firstName,
                lastName: formValues.controller.lastName,
                dateOfBirth: formValues.controller.dateOfBirth,
                ssn: formValues.controller.ssn,
                address: {
                    address1: formValues.controller.address.address1,
                    city: formValues.controller.address.city,
                    stateProvinceRegion: formValues.controller.address.stateProvinceRegion,
                    postalCode: formValues.controller.address.postalCode,
                    country: formValues.controller.address.country
                }
            }
            postBeneficialOwner(owner)
        }
    } else {
        setIsControllerOwnerResponded(true)
    }
  }

  const submitCustomerInfo = async data => {
    setIsLoading(true)
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      ssn: data.ssn,
      dateOfBirth: moment(startDate).format("YYYY-MM-DD"),
      address: {
        address1: data.address,
        city: data.city,
        stateProvinceRegion: data.state,
        country: data.country,
        postalCode: data.postalCode
      }
    }
    postBeneficialOwner(payload)
  }

  useEffect(() => {
    if (formValues.businessType !== 'soleProprietorship'){
        getBeneficialOwners()
    }
  }, [])

  const postDwollaDocuments = id => {
    const formData = new FormData()
    formData.append("documentType", documentType)
    formData.append("file", selectedFile)

    request
      .post("/api/v1/dwolla/beneficial/" + id + "/documents", formData)
      .then(data => {})
      .catch(e => {
        console.log(e)
      })
  }

  const getBeneficialOwners = () => {
    request
      .get("/api/v1/dwolla/beneficial/owner")
      .then(data => {
        setOwners(data.data._embedded["beneficial-owners"])
        setIsLoading(false)
        reset()
        setShowForm(false)

        if (data.data._embedded["beneficial-owners"]) {
            if (data.data._embedded["beneficial-owners"].length > 0) {
               if (setBeneficialOwnerAdded) {
                   setBeneficialOwnerAdded(true)
               }
            }
        }

      })
      .catch(e => {
        console.log(e)
      })
  }

  const changeHandler = event => {
    setSelectedFile(event.target.files[0])
    setFileName(event.target.files[0].name)
  }

  const postBeneficialOwner = data => {
    const header = {
      "Content-Type": "application/json"
    }
    request
      .post("/api/v1/dwolla/beneficial/owner", data, { headers: header })
      .then(data => {
        console.log(data)
        getBeneficialOwners()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const resetForm = () => {
    document.getElementById("verify-customer").reset();
    console.log("reset done")
    setShowForm(false);
  }

  console.log('Is it currently loading? ', isLoading)

  const listOwners = owner => {
      return (
            <div className="card-list">
                <div className="card-item title" >{owner.firstName} {owner.lastName}</div>
                <input
                  id="fileid"
                  className="input"
                  type="file"
                  name="file"
                  accept="image/jpg, image/jpeg, application/pdf, image/png"
                  onChange={changeHandler}
                  style={{display: "none"}}
                />
                {owner.verificationStatus === "document" ? (

                    <div>
                    {selectedForDocumentUpload === owner.id ? (
                            <div className="document-upload-box"><div className="mb-10 mt-10">Document Type</div>
                            <select
                              className="input w-input"
                              onChange={e => {
                                setDocumentType(e.target.value)
                                console.log(e.target.value)
                              }}
                            >
                              <option defaultChecked="true" value={"license"}>
                                License
                              </option>
                              <option value={"passport"}>Passport</option>
                              <option value={"idCard"}>ID Card</option>
                            </select>
                            <label for="fileid" className="card-item document-upload mb-10" style={{ textAlign: "center" }}>{fileName}</label>
                            <div>
                              <button
                                onClick={(() => {
                                setDocumentsUploaded( arr => [...arr, String(owner.id)])
                                setSelectedForDocumentUpload("")
                                }
                                )}
                                className="button button-primary w-button mb-10"
                              >
                                Submit
                              </button>
                            </div>
                            </div>
                        ) : (
                            <div>
                            {checkDocumentUploaded(owner.id) === true ? (
                                <div className="document-upload-box">
                                    <div className="mb-10 mt-10">Document Uploaded</div>
                                </div>
                            ) : (
                                <button
                                onClick={() => {
                                    setSelectedFile("")
                                    setSelectedForDocumentUpload(owner.id)
                                }} className="card-item document-upload-aqua" style={{textAlign: "center" }}>Document Needed</button>
                            )}
                            </div>
                        )}
                    </div>
                    ) : (
                    <div className="card-item verified" style={{ width: "40%", textAlign: "center" }}>Verified</div>
                )}
            </div>
      )
//      || isLoading && !checked
  }

  return (
    <div>
        { isLoading && (formValues.businessType !== 'soleProprietorship' || !checked) ? (
        <div className="w-form" >
              <div style={{marginTop: "111px", marginBottom: "170px"}}>
                    <LottieAnimation
                        height={300}
                        width={300}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                </div>
          </div>
          ) : (
    <div className="w-form">
      <form
        id="verify-customer"
        name="verify-customer"
        data-name="verify customer"
        onSubmit={handleSubmit(submitCustomerInfo)}
      >
        {setStep && (isLoading === false && owners.length === 0 && isControllerOwnerResponded === false ) || (formValues.businessType === 'soleProprietorship') ? (
        <div style={{marginTop: "250px", marginBottom: "300px"}}>
        {(formValues.businessType !== 'soleProprietorship') ? (
            <div>
                <h4 style={{ textAlign: "center", alignContent: "center"}}>Is Controller {formValues.controller.firstName} {formValues.controller.lastName} an Owner?</h4>
                <div style={{display: "flex", alignContent: "center"}}>
                <button onClick={() => {
                isControllerOwnerResponse(true);
                setIsLoading(true);
                }} style={{width: "47.5%", marginRight: "5%"}} className="button button-primary button-block w-button">
                    Yes
                </button>
                <button onClick={() => isControllerOwnerResponse(false)} style={{width: "47.5%"}} className="button button-primary-alternate button-block w-button">
                    No
                </button>
                </div>
            </div>
        ) : (
            <div>
                <h3 style={{ textAlign: "center", alignContent: "center", marginBottom: "20px"}}>Almost done!</h3>
                <h4 style={{ textAlign: "center", alignContent: "center"}}>Looks like you are a Sole Proprietor,</h4>
                <h4 style={{ textAlign: "center", alignContent: "center"}}>we will add you as the sole beneficial owner.</h4>
                <button onClick={() => {
                if (setStep) {
                    setStep(5)
                }
                }} style={{width: "100%", marginTop: "20px"}} className="button button-primary button-block w-button">
                    Got it !
                </button>
            </div>
        )}
        </div>
        ) : (
        <div>
        {owners.length > 0 ? (
        <div style={{ width: "100%", marginTop: "30px" }}>
            <h3>{ formValues.businessType === 'soleProprietorship' ? "Beneficial Owner" : "Beneficial Owners"}</h3>
            {owners.map((owner, index) => {
                return listOwners(owner)
            })}
        </div>
        ) : null}
        <div className="card-container pd-0">
            {!showForm && owners.length !== 0 ? (
                <button type="button" onClick={() => setShowForm(!showForm)} className="button button-primary-alternate button-block w-button">
                    + Add Beneficial Owner
                </button>
                ) : (
            <div className="card-list-form">
                        <h3 className="mb-30" style={{ textAlign: "center"}}>New Beneficial Owner</h3>
                            <div className="form-group" style={{ width: "48%", display: "inline-block", marginRight: "2%" }} >
                                  <label htmlFor="firstName" className="label">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.firstName ? "true" : "false"}
                                    {...register("firstName", {
                                      required: "The owner's First name is required."
                                    })}
                                    className="input w-input"
                                    maxLength="256"
                                    name="firstName"
                                    placeholder="Your first name"
                                    id="firstName"
                                  />
                                  {errors.firstName && (
                                    <div className="error-message">
                                      <div>{errors.firstName.message}</div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="form-group"
                                  style={{ width: "48%", display: "inline-block", marginLeft: "2%" }}
                                >
                                  <label htmlFor="lastName" className="label">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.lastName ? "true" : "false"}
                                    {...register("lastName", {
                                      required: "The owner's Last name is required."
                                    })}
                                    className="input w-input"
                                    maxLength="256"
                                    name="lastName"
                                    placeholder="Your last name"
                                    id="lastName"
                                  />
                                  {errors.lastName && (
                                    <div className="error-message">
                                      <div>{errors.lastName.message}</div>
                                    </div>
                                  )}
                                </div>
                                <div className="mb-10">
                                    <label htmlFor="dob" className="label">
                                      Date of Birth
                                    </label>
                                    <ReactInputDateMask
                                    mask='mm-dd-yyyy'
                                    showMaskOnFocus={true}
                                    className={"w-input"}
                                    onChange={(date) => updateDate(date)}
                                    value={""}
                                    showMaskOnHover={true}/>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="ssn" className="label">
                                    SSN (Full)
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.ssn ? "true" : "false"}
                                    {...register("ssn", {
                                      required: "The owner's SSN (Social Security Number) is required."
                                    })}
                                    className="input w-input"
                                    maxLength="9"
                                    name="ssn"
                                    placeholder="SSN e.g. 123-56-7890"
                                    id="ssn"
                                  />
                                  {errors.ssn && (
                                    <div className="error-message">
                                      <div>{errors.ssn.message}</div>
                                    </div>
                                  )}
                                </div>

                                <div className="form-group">
                                  <label htmlFor="address" className="label">
                                    Address
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.address ? "true" : "false"}
                                    {...register("address", {
                                      required: "The owner's Address is required."
                                    })}
                                    className="input w-input"
                                    maxLength="256"
                                    name="address"
                                    placeholder="Enter Business Address"
                                    id="address"
                                  />
                                  {errors.address && (
                                    <div className="error-message">
                                      <div>{errors.address.message}</div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="form-group"
                                  style={{ width: "30%", display: "inline-block", marginRight: "3%" }}
                                >
                                  <label htmlFor="city" className="label">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.city ? "true" : "false"}
                                    {...register("city", {
                                      required: "The owner's City is required."
                                    })}
                                    className="input w-input"
                                    maxLength="256"
                                    name="city"
                                    placeholder="City"
                                    id="city"
                                  />
                                  {errors.city && (
                                    <div className="error-message">
                                      <div>{errors.city.message}</div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="form-group"
                                  style={{ width: "30%", display: "inline-block", margin: "1.5%" }}
                                >
                                  <label htmlFor="state" className="label">
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.state ? "true" : "false"}
                                    {...register("state", {
                                      required: "The owner's State is required."
                                    })}
                                    className="input w-input"
                                    maxLength="256"
                                    name="state"
                                    placeholder="e.g. UT"
                                    id="state"
                                  />
                                  {errors.state && (
                                    <div className="error-message">
                                      <div>{errors.state.message}</div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="form-group"
                                  style={{ width: "30%", display: "inline-block", marginLeft: "3%" }}
                                >
                                  <label htmlFor="postalCode" className="label">
                                    Postal Code
                                  </label>
                                  <input
                                    type="text"
                                    aria-invalid={errors.postalCode ? "true" : "false"}
                                    {...register("postalCode", {
                                      required: "The owner's Postal Code is required."
                                    })}
                                    className="input w-input"
                                    maxLength="256"
                                    name="postalCode"
                                    placeholder="Postal Code"
                                    id="postalCode"
                                  />
                                  {errors.postalCode && (
                                    <div className="error-message">
                                      <div>{errors.postalCode.message}</div>
                                    </div>
                                  )}
                                </div>
                                <label htmlFor="country" className="label">
                                  Country
                                </label>
                                <input
                                  type="text"
                                  aria-invalid={errors.country ? "true" : "false"}
                                  {...register("country", {
                                    required: "The owner's Country is required"
                                  })}
                                  className="input w-input"
                                  maxLength="2"
                                  name="country"
                                  placeholder="e.g. US"
                                  id="country"
                                />
                                {errors.country && (
                                  <div className="error-message">
                                    <div>{errors.country.message}</div>
                                  </div>
                                )}

                                {isDateIsValid ? (
                                    <button
                                        type="submit"
                                        style={{ alignSelf: "center" }}
                                        className="button button-primary-alternate mt-20 button-block w-button"
                                      >
                                        Add Beneficial Owner
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        style={{ alignSelf: "center" }}
                                        disabled="true"
                                        className="button button-disabled mt-20 button-block w-button"
                                      >
                                        Complete Form to Continue
                                    </button>
                                )}
                            <button onClick={() => setShowForm(false)} className="button button-primary-cancel noBorders button-block w-button">
                                Cancel
                            </button>
                            </div>)}
        </div>
        {(owners.length > 0 && showForm === false) ? (
            <div style={{ marginTop: "250px"}}></div>
        ) : null}
        {(owners.length === 1 && showForm === false) ? (
                    <div style={{ marginTop: "170px"}}></div>
                ) : null}
        {(owners.length === 3 && showForm === false) ? (
                    <div style={{ marginTop: "90px"}}></div>
                ) : null}
        {(owners.length > 3 && showForm === false) ? (
                            <div style={{ marginTop: "30px"}}></div>
                        ) : null}
        { setStep ? (
        <div className="mb-20">
          <input
            name="isGoing"
            type="checkbox"
            checked={agree}
            style={{ margin: 10 }}
            onChange={() => setAgree(!agree)}
          />
          I hereby certify that the information provided for the Beneficial Owners is complete and correct.
        </div> ) : (
          <p className="text-left" >By submitting this form, I hereby certify that the information provided for the Beneficial Owners is complete and correct.</p>
        )}

                                <div className="text-center">
                                    { setStep ? (
                                        <div>
                                        {agree ? (
                                            <button
                                              style={{marginBottom: "30px"}}
                                              onClick={() => {
                                                setStep(5)
                                              }}
                                              className="button button-primary mt-20 button-block w-button"
                                            >
                                              I'm Done Adding Owners
                                            </button>
                                        ) : (
                                            <div className="text-center">
                                          <button
                                            type="button"
                                            disabled="true"
                                            className="button button-disabled mt-20 button-block w-button"
                                          >
                                            Agree to Continue
                                          </button>
                                              </div>
                                        )}
                                        </div>
                                    ) : null}

                                    {setDisplayBeneficialOwners ? (
                                        <button
                                          style={{marginBottom: "30px"}}
                                          onClick={() => {
                                          setDisplayBeneficialOwners(false)
                                          }}
                                          className="button button-primary mt-20 button-block w-button"
                                        >
                                          I'm Done Adding Owners
                                        </button>
                                    ) : null}
                                  </div>
        </div>

      )}
      </form>
      <div className="success-message w-form-done">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="error-message w-form-fail">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  )}
  </div>
  )
}

export default BeneficialOwner
