import React, { useState, useEffect } from "react"
import Link from "@mui/material/Link"
import { useForm } from "react-hook-form"
import { request } from "../../utils/http"
import { useSelector } from "react-redux"
import { GET_TRANSACTIONS } from "../../store/payment/constants"
import { GET_COMPANY } from "../../store/company/constants"
import { useDispatch } from "react-redux"

const MyAccountsContainer = props => {
  const dispatch = useDispatch()
  const companies = useSelector(state => state.companyReducer.company?.data)
  const transactions = useSelector(state => state.paymentReducer.transactions)
  // const [companyID, setCompanyID] = useState(null)
  const [soleOrEIn, setSoleOrEin] = useState("")
  const [hasAccountInformation, setHasAccountInformation] = useState(null)
  const [displayOrumForm, setDisplayOrumForm] = useState(null)
  const [isRequestComplete, setIsRequestComplete] = useState(null)
  // start request successful as true, and set to false if any API fail
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(true)
  const [isRequestBeingProcessed, setIsRequestBeingProcessed] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    // call the GET_COMPANY and GET_TRANSACTIONS at each refresh, so "companies" and "transactions" are updated 
    dispatch({ type: GET_TRANSACTIONS })
    dispatch({ type: GET_COMPANY })
    console.log("getting transactions", transactions.data)
    getBusinessOrum()
  }, [])

  useEffect(() => {
    if (companies && companies[0]?.id) {
      console.log('Success in retrieving companyID', companies[0])
      setHasAccountInformation(true)
      // isRequestBeingProcessed allow us to avoid requesting multiple times the same transactions 
      // refreshing this useEffect() in transactions allow us to do the request once the transactions have been retrieved
      if (!isRequestBeingProcessed) { requestTransferWithExistingAccountInformation() }
    }
  }, [transactions])

  const requestTransferWithExistingAccountInformation = () => {
    console.log("transactions requestTransferWithExistingAccountInformation", transactions.data)
    // Ansa part will be called on each time (because customer based, not orum's)
    const transactionsData = transactions.data
    var idLastTransaction = 0
    for (var i = 0; i < transactionsData.length; i++) {
      const transactionDataOriginal = transactionsData[i]
      if (transactionDataOriginal.status == 'paid') {
        idLastTransaction = transactionDataOriginal.id
        console.log("idLastTransaction", idLastTransaction)
      }
    }
    if (idLastTransaction == 0) {
      console.log('No more transactions to request')
      // to handle refreshes after the request is completed
      setIsRequestComplete(true)
    } else {
      setIsRequestBeingProcessed(true)
      // to handle having idLastTransaction==0 on first useEffect(), then not anymore once transactions are retrieved
      setIsRequestComplete(false)
    }
    for (var i = 0; i < transactionsData.length; i++) {
      const transactionDataOriginal = transactionsData[i]
      if (transactionDataOriginal.status == 'paid') {
        console.log('current transaction in requestTransferWithExistingAccountInformation', transactionDataOriginal)
        console.log('current customer_id in requestTransferWithExistingAccountInformation', transactionDataOriginal?.customer_id)
        request
          .get('/api/v1/customers/' + transactionDataOriginal?.customer_id + '/get-customer', {
            headers: { "Content-Type": "application/json" }
          })
          .then(function (response) {
            const customerData = response.data
            console.log('success while getting customer', customerData)
            const transactionData = {
              email: customerData.email,
              phone: customerData.mobile_number,
              firstName: customerData.first_name,
              lastName: customerData.last_name,
              ansaId: customerData.ansa_customer_id,
              // Careful: Customer ID and not User ID
              databaseCustomerId: transactionDataOriginal.customer_id.toString(),
              // mutlply by 100 because amount is in cents for Ansa
              amount: transactionDataOriginal.amount * 100,
              label: transactionDataOriginal.business_name,
              databaseCompanyId: transactionDataOriginal.company_id.toString(),
              transactionId: transactionDataOriginal.id.toString(),
              idLastTransaction: idLastTransaction
            }
            console.log('transactionData', transactionData)
            createCustomerAnsa(transactionData)
          })
          .catch(function (error) {
            console.error('error while handling request', error)
            setIsRequestComplete(true)
            setIsRequestSuccessful(false)
          })
      }
    }
  }

  const reconciliationAnsa = () => {
    // filters to edit if different reconciliation to handle
    // Start datetime for filtering transactions
    // start_datetime = ‘2023-12-27 23:46:24.600000+00:00’
    // End datetime for filtering transactions
    // end_datetime = ‘2024-01-30 23:59:59.900000+00:00’
    const filterStatus = 'processing_lc'
    const filterUpdatedDateStart = new Date('2023-12-27 23:46:24.600000+00:00')
    const filterUpdatedDateEnd = new Date('2024-01-30 23:59:59.900000+00:00')

    request
      .get('/api/v1/transactions/get_localcash_requested_all_merchants')
      .then(function (response) {
        console.log('Success while getting ALL transactions with status = requesting localcash', response.data)
        const transactionsData = response.data
        var idLastTransaction = 0
        for (var i = 0; i < transactionsData.length; i++) {
          const transactionDataOriginal = transactionsData[i]
          // we can change the field to "updated_on" if we want to know when the merchant requested the transaction (instead of when the customer made the transaction)
          // ALSO edit it inside the 2nd for loop
          const dateField = transactionDataOriginal.created_on
          if (transactionDataOriginal.status == filterStatus) {
            // && new Date(dateField) >= filterUpdatedDateStart
            // && new Date(dateField) <= filterUpdatedDateEnd) {
            idLastTransaction = transactionDataOriginal.id
            console.log("idLastTransaction", idLastTransaction)
          }
        }
        if (idLastTransaction == 0) {
          console.log('No more transactions to request')
          // to handle refreshes after the request is completed
          setIsRequestComplete(true)
        } else {
          setIsRequestBeingProcessed(true)
          // to handle having idLastTransaction==0 on first useEffect(), then not anymore once transactions are retrieved
          setIsRequestComplete(false)
        }

        var totalAmount = 0
        var transactionsArray = []
        var numberOfTransactions = 0
        // var totalAmountElse = 0
        // var transactionsArrayElse = []
        // var numberOfTransactionsElse = 0
        for (var i = 0; i < transactionsData.length; i++) {
          const transactionDataOriginal = transactionsData[i]
          // we can change the field to "updated_on" if we want to know when the merchant requested the transaction (instead of when the customer made the transaction)
          // we added the condition on last name to handle transactions that failed because of no last name in the previous iteration
          const dateField = transactionDataOriginal.created_on
          if (transactionDataOriginal.status == filterStatus) {
            // && new Date(dateField) >= filterUpdatedDateStart
            // && new Date(dateField) <= filterUpdatedDateEnd) {
            console.log('current transaction in reconciliationAnsa', transactionDataOriginal)
            console.log('current updated_on in reconciliationAnsa', transactionDataOriginal?.updated_on)
            request
              .get('/api/v1/customers/' + transactionDataOriginal?.customer_id + '/get-customer', {
                headers: { "Content-Type": "application/json" }
              })
              .then(function (response) {
                const customerData = response.data
                console.log('success while getting customer', customerData)
                // if (customerData.last_name == '') {
                  const transactionData = {
                    email: customerData.email,
                    phone: customerData.mobile_number,
                    firstName: customerData.first_name,
                    lastName: customerData.last_name,
                    ansaId: customerData.ansa_customer_id,
                    // Careful: Customer ID and not User ID
                    databaseCustomerId: transactionDataOriginal.customer_id.toString(),
                    // mutlply by 100 because amount is in cents for Ansa
                    amount: transactionDataOriginal.amount * 100,
                    label: transactionDataOriginal.business_name,
                    databaseCompanyId: transactionDataOriginal.company_id.toString(),
                    transactionId: transactionDataOriginal.id.toString(),
                    idLastTransaction: idLastTransaction
                  }
                  console.log('transactionData', transactionData)
                  totalAmount = totalAmount + parseFloat(transactionDataOriginal.amount)
                  numberOfTransactions = numberOfTransactions + 1
                  console.log('transaction', transactionDataOriginal)
                  transactionsArray.push(transactionDataOriginal)
                  console.log('totalAmount', totalAmount)
                  console.log('numberOfTransactions', numberOfTransactions)
                  console.log('transactionsArray', transactionsArray)
                  // We removed the actual calls for testing first
                  createCustomerAnsa(transactionData)


                // } else {
                //   console.log('transaction ELSE', transactionDataOriginal)
                //   totalAmountElse = totalAmountElse + parseFloat(transactionDataOriginal.amount)
                //   numberOfTransactionsElse = numberOfTransactionsElse + 1
                //   transactionsArrayElse.push(transactionDataOriginal)
                //   console.log('totalAmountElse', totalAmountElse)
                //   console.log('numberOfTransactionsElse', numberOfTransactionsElse)
                //   console.log('transactionsArrayElse', transactionsArrayElse)
                // }


              })
              .catch(function (error) {
                console.error('error while handling request', error)
                setIsRequestComplete(true)
                setIsRequestSuccessful(false)
              })
          }
        }
      })
      .catch(function (error) {
        console.error('error while getting ALL transactions with status = requesting localcash', error)
      })
  }

  const submitAccountInformation = dataForm => {
    console.log('Data in submitAccountInformation from form', dataForm)
    // Orum requests for creation only - will use data from form]
    authenticateOrum(dataForm)
  }

  const createCustomerAnsa = transactionData => {
    // Email & Phone need to both be unique 
    // Check first if customer already exists
    if (transactionData.ansaId == null) {
      console.log('Customer does not exist in Ansa, creating it')
      // get customer ansa
      // we added this condition to handle customers without last name in our database
      var lastName = 'L.'
      if (transactionData.lastName != '') { lastName = transactionData.lastName }
      const createData = {
        email: transactionData.email,
        phone: transactionData.phone,
        firstName: transactionData.firstName,
        lastName: lastName,
        // Careful: Customer ID and not User ID
        databaseCustomerId: transactionData.databaseCustomerId
      }
      console.log('createData', createData)
      request
        .post("/api/v1/businesses/create_customer_ansa", createData, {
          headers: { "Content-Type": "application/json" }
        })
        .then(function (response) {
          // response.text?
          console.log('success while creating customer for Ansa', response.data.join(''))
          // we can update our split method to the JSON.parse(response.data.join(''))['id'] way
          const customerId = response.data.join('').split("id\":\"").at(1).split("\",").at(0)
          console.log('customerId is:', customerId)
          const newTransactionData = {
            // Amount is in cents
            amount: transactionData.amount,
            customerId: customerId,
            label: transactionData.label,
            databaseCompanyId: transactionData.databaseCompanyId,
            storeLocationId: 'long-beach',
            transactionId: transactionData.transactionId,
            idLastTransaction: transactionData.idLastTransaction
          }
          const addAnsaIdData = {
            customer_id: transactionData.databaseCustomerId,
            ansa_id: customerId,
          }
          request
            .post("/api/v1/customers/1/add_ansa_id_customer", addAnsaIdData, {
              headers: { "Content-Type": "application/json" }
            })
            .then(function (response) {
              // response.text?
              console.log('success while adding Ansa ID to Customer', response.data)
              fundBalanceAnsa(newTransactionData)
            })
            .catch(function (error) {
              console.error('error while adding Ansa ID to Customer', error.response.data)
              setIsRequestSuccessful(false)
            })

        })
        .catch(function (error) {
          console.error('error while creating customer for Ansa', error)
          console.error('error while creating customer for Ansa', error.response.data)
          setIsRequestSuccessful(false)
        })
    } else {
      const newTransactionData = {
        // Amount is in cents
        amount: transactionData.amount,
        customerId: transactionData.ansaId,
        label: transactionData.label,
        databaseCompanyId: transactionData.databaseCompanyId,
        storeLocationId: 'long-beach',
        transactionId: transactionData.transactionId,
        idLastTransaction: transactionData.idLastTransaction
      }
      console.log('Customer does exist in Ansa', newTransactionData)
      fundBalanceAnsa(newTransactionData)
    }
  }

  const fundBalanceAnsa = newTransactionData => {
    console.log("data in fundBalanceAnsa", newTransactionData)
    const fundData = {
      // Amount is in cents
      amount: newTransactionData.amount,
      customerId: newTransactionData.customerId
    }
    request
      .post("/api/v1/businesses/fund_balance_ansa", fundData, {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('success while funding balance for Ansa', response.data)
        // Warning: if you name the function "useBalanceAnsa" React will treat it as a React Hook, and it will create issues 
        functionUseBalanceAnsa(newTransactionData)
      })
      .catch(function (error) {
        console.error('error while funding balance for Ansa', error.response.data)
        setIsRequestSuccessful(false)
      })
  }

  const functionUseBalanceAnsa = newTransactionData => {
    // Email & Phone need to both be unique 
    request
      .post("/api/v1/businesses/use_balance_ansa", newTransactionData, {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('success while using balance for Ansa', response.data)
        const updateStatusData = {
          transaction_id: newTransactionData.transactionId,
        }
        console.log('updateStatusData', updateStatusData)
        request
          .post("/api/v1/transactions/update_localcash_requested_unique", updateStatusData, {
            headers: { "Content-Type": "application/json" }
          })
          .then(function (response) {
            console.log('success while updating transaction status', response)
            if (newTransactionData.transactionId == newTransactionData.idLastTransaction) {
              console.log("Success while completing the request", [newTransactionData.transactionId, newTransactionData.idLastTransaction])
              dispatch({ type: GET_TRANSACTIONS })
              setIsRequestComplete(true)
            } else {
              console.log("More transactions to request", [newTransactionData.transactionId, newTransactionData.idLastTransaction])
            }
          })
          .catch(function (error) {
            console.error('error while updating transaction status', error.response.data)
            setIsRequestSuccessful(false)
          })
      })
      .catch(function (error) {
        console.error('error while using balance for Ansa', error.response.data)
        setIsRequestSuccessful(false)
      })
  }

  const getBusinessOrum = () => {
    // POST request to authenticate Orum, and get a token 
    let authTokenOrum = null
    if (companies[0]?.orum_customer_id === undefined || companies[0]?.orum_customer_id === null) {
      console.log('no Orum Customer ID for this merchant')
      setDisplayOrumForm(true)
    } else {
      console.log('Orum Customer ID for this merchant', companies[0]?.orum_customer_id)
    }
    request
      .post("/api/v1/businesses/authenticate_orum", {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('success while authenticating for Orum', response.data)
        authTokenOrum = response.data.join('').split(",").at(1).split("\"").at(3)
        // Will have to switch between "ein" and "tin"(ssn)
        const dataGetBankOrum = {
          auth_token_orum: authTokenOrum,
          // test IDs
          // a7a60dfb-98b8-40d3-9948-39abdbe915ca
          // 61e4a3a6-d713-40f3-8812-e86b09923236
          orum_merchant_id: companies[0]?.orum_customer_id
        }
        console.log('dataGetBankOrum', dataGetBankOrum)
        request
          .post("/api/v1/businesses/get_bank_orum", dataGetBankOrum, {
            headers: { "Content-Type": "application/json" }
          })
          .then(function (response) {
            console.log('success while getting bank from Orum', JSON.parse(response.data.join('')))
            const jsonResponse = JSON.parse(response.data.join(''))['external_accounts']
            // console.log('success while getting bank from Orum', jsonResponse)
            if (jsonResponse === undefined || jsonResponse.length == 0) {
              console.log('no bank account in Orum for this merchant', jsonResponse.length)
              // setDisplayOrumForm(true) if the bank account was not created
              setDisplayOrumForm(true)
            } else {
              console.log('bank account found in Orum for this merchant', jsonResponse.length)
              if (jsonResponse[0].status === 'verified') {
                console.log('bank account is verified')
              } else {
                console.log('bank account is not verified. If it has been more than a week, there might be a problem with the account')
                // in the future, we could send an alert to localight team members when that happen more than a week after the creation of the bank account
              }
            }
          })
          .catch(function (error) {
            console.error('error while creating business for Orum', error.response.data)
            // if Orum ID is unknown for example
            setDisplayOrumForm(true)
          })
      })
      .catch(function (error) {
        console.error('error while authenticating for Orum', error.response.data)
      })
  }

  const authenticateOrum = dataForm => {
    // POST request to authenticate Orum, and get a token 
    let authTokenOrum = null
    request
      .post("/api/v1/businesses/authenticate_orum", {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('success while authenticating for Orum', response.data)
        authTokenOrum = response.data.join('').split(",").at(1).split("\"").at(3)
        // console.log('authTokenOrum', authTokenOrum)
        console.log('companies name', companies[0]?.name)
        const company_slug = convertToSlug(companies[0]?.name)
        console.log('company_slug', company_slug)
        console.log('companies id', companies[0]?.id)
        console.log('companies email', companies[0]?.locations[0]?.personnels[0]?.email)
        console.log("dataForm", dataForm)
        // WARNING: put back only 2 hyphens on final version (adding more for tests to bypass no-duplicates-rule)
        dataForm = { ...dataForm, customer_reference_id: company_slug + "--" + companies[0]?.id }
        dataForm = { ...dataForm, email: companies[0]?.locations[0]?.personnels[0]?.email }
        dataForm = { ...dataForm, authTokenOrum: authTokenOrum }
        console.log('dataCreateBusinessOrum', dataForm)
        createBusinessOrum(dataForm)
      })
      .catch(function (error) {
        console.error('error while authenticating for Orum', error.response.data)
      })
  }

  const createBusinessOrum = dataForm => {
    // Skip the create_business_orum call if the orum_customer_id already exists in the DB 
    if (companies[0]?.orum_customer_id === undefined || companies[0]?.orum_customer_id === null) {
      let tax_id_type = ''
      let tax_id = ''
      if (dataForm.sole_or_ein === "sole_proprietor") {
        tax_id_type = 'tin'
        // we add five "0" before the SSN because the field requires 9 digits (Orum's fix)
        tax_id = '00000' + dataForm.ssn
      }
      if (dataForm.sole_or_ein === "ein") {
        tax_id_type = 'ein'
        tax_id = dataForm.ein
      }
      const dataCreateBusinessOrum = {
        auth_token_orum: dataForm.authTokenOrum,
        legal_name: dataForm.legal_business,
        tax_id: tax_id,
        tax_id_type: tax_id_type,
        address1: dataForm.street_address,
        city: dataForm.city,
        state: dataForm.state,
        zip5: dataForm.zip,
        customer_reference_id: dataForm.customer_reference_id,
        email: dataForm.email
      }
      console.log('dataCreateBusinessOrum', dataCreateBusinessOrum)
      request
        .post("/api/v1/businesses/create_business_orum", dataCreateBusinessOrum, {
          headers: { "Content-Type": "application/json" }
        })
        .then(function (response) {
          console.log('success while creating business for Orum', JSON.parse(response.data.join('')))
          const orum_id = JSON.parse(response.data.join(''))['business']['id']
          const dataAddOrumId = {
            orum_id: orum_id,
            company_id: companies[0]?.id
          }
          request
            .post("/api/v1/businesses/add_orum_id_company", dataAddOrumId, {
              headers: { "Content-Type": "application/json" }
            })
            .then(function (response) {
              console.log('success while adding Orum ID to Company', response.data)
              // call the GET_COMPANY after adding Orum ID, so "companies" is updated 
              dispatch({ type: GET_COMPANY })
            })
            .catch(function (error) {
              console.error('error while adding Orum ID to Company', error.response.data)
            })

          const dataCreateExternalAccountOrum = {
            auth_token_orum: dataCreateBusinessOrum.auth_token_orum,
            customer_reference_id: dataCreateBusinessOrum.customer_reference_id,
            legal_name: dataCreateBusinessOrum.legal_name,
            account_reference_id: dataCreateBusinessOrum.customer_reference_id + "--bank",
            account_number: dataForm.account_number,
            routing_number: dataForm.routing_number,
            account_holder_name: dataForm.account_holder
          }
          console.log('dataCreateExternalAccountOrum', dataCreateExternalAccountOrum)
          createExternalAccountOrum(dataCreateExternalAccountOrum)
        })
        .catch(function (error) {
          console.error('error while creating business for Orum', error.response.data)
        })
      // going straight to createExternalAccountOrum if the orum customer ID already exists in DB
    } else {
      const dataCreateExternalAccountOrum = {
        auth_token_orum: dataForm.authTokenOrum,
        customer_reference_id: dataForm.customer_reference_id,
        legal_name: dataForm.legal_business,
        account_reference_id: dataForm.customer_reference_id + "--bank",
        account_number: dataForm.account_number,
        routing_number: dataForm.routing_number,
        account_holder_name: dataForm.account_holder
      }
      console.log('dataCreateExternalAccountOrum', dataCreateExternalAccountOrum)
      createExternalAccountOrum(dataCreateExternalAccountOrum)
    }
  }

  const createExternalAccountOrum = data => {
    // POST request to create external account in Orum, use the auth token 
    console.log('data in createExternalAccountOrum', data)
    request
      .post("/api/v1/businesses/create_external_account_orum", data, {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('success while creating external account for Orum', response.data)
        // On success:
        const dataTestVerifyExternalAccountOrum = {
          auth_token_orum: data.auth_token_orum,
          account_number: data.account_number,
          routing_number: data.routing_number,
          account_holder_name: data.account_holder_name
        }
        verifyExternalAccountOrum(dataTestVerifyExternalAccountOrum)
      })
      .catch(function (error) {
        console.error('error while creating external account for Orum', error.response.data)
      })
  }

  const verifyExternalAccountOrum = data => {
    // POST request to verify external account in Orum, use the auth token 
    console.log('data in verifyExternalAccountOrum', data)
    request
      .post("/api/v1/businesses/verify_external_account_orum", data, {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('success while verifying external account for Orum', response.data)
        setDisplayOrumForm(false)
      })
      .catch(function (error) {
        console.error('error while verifying external account for Orum', error.response.data)
      })
  }

  // this function is tested here but will be moved to a separate autonomous file for cronjobs
  const createTransferOrum = () => {
    // testing to get the company by ID to check the "nested foreign keys" and retrieve elements like slug etc
    const dataGetCompanyById = {
      // can manually set this to get any company wanted
      company_id: '2269'
    }
    request
      .post("/api/v1/businesses/get_company_by_id", dataGetCompanyById, {
        headers: { "Content-Type": "application/json" }
      })
      .then(function (response) {
        console.log('Success while getting company by ID', response.data)
      })
      .catch(function (e) {
        console.log('Error while getting company by ID', e)
      })


    // Get list of transactions being processed/requested, oldests first, for ALL merchants
    request
      .get('/api/v1/transactions/get_localcash_requested_all_merchants')
      .then(function (response) {
        console.log('Success while getting ALL transactions with status = requesting localcash', response.data)
        const transactionsRequested = response.data
        // auth to Orum
        let authTokenOrum = null
        request
          .post("/api/v1/businesses/authenticate_orum", {
            headers: { "Content-Type": "application/json" }
          })
          .then(function (response) {
            const responseJson = JSON.parse(response.data.join(''))
            // console.log('success while authenticating for Orum', responseJson)
            authTokenOrum = responseJson['auth']['token']
            console.log('authTokenOrum', authTokenOrum)

            // check current orum balance (API call)
            let orumBalance = 0
            const dataGetBalanceOrum = {
              auth_token_orum: authTokenOrum
            }
            request
              .post("/api/v1/businesses/get_balance_orum", dataGetBalanceOrum, {
                headers: { "Content-Type": "application/json" }
              })
              .then(function (response) {
                const responseJson = JSON.parse(response.data.join(''))
                console.log('success while getting balance for Orum', responseJson)
                orumBalance = responseJson['balance']['available']
                console.log('orumBalance', orumBalance)

                // skip create transfer call if not enough Orum balance for the requested amount
                for (const index in transactionsRequested) {
                  const oneTransaction = transactionsRequested[index]
                  console.log('oneTransaction', oneTransaction)
                  if (oneTransaction.amount < orumBalance) {
                    // console.log('customer_id', oneTransaction.customer_id)
                    console.log('business_orum_customer_id', oneTransaction.business_orum_customer_id)
                    console.log('business name', oneTransaction.business_name)
                    const business_slug = convertToSlug(oneTransaction.business_name)
                    console.log('business_slug', business_slug)
                    const dataTransfer = {
                      auth_token_orum: authTokenOrum,
                      transfer_reference_id: oneTransaction.customer_name + '--'
                        + oneTransaction.customer_id + '--'
                        + business_slug + '--'
                        + oneTransaction.id + '--'
                        + new Date().toISOString(),
                      // amout * 100 because in cents
                      amount: parseInt(parseFloat(oneTransaction.amount) * 100),
                      // we will have to get account_reference_id (generated by us) from business_orum_customer_id or our DB (follow same structure)
                      customer_reference_id: business_slug + "--" + oneTransaction.company_id,
                      account_reference_id: business_slug + "--" + oneTransaction.company_id + "--bank"
                    }
                    console.log('dataTransfer', dataTransfer)
                    request
                      .post("/api/v1/businesses/create_transfer_orum", dataTransfer, {
                        headers: { "Content-Type": "application/json" }
                      })
                      .then(function (response) {
                        console.log('success while creating transfer for Orum', response.data)
                        // update transaction status to "completed" (DB call)
                        const updateStatusData = {
                          transaction_id: oneTransaction.id,
                        }
                        console.log('updateStatusData', updateStatusData)
                        request
                          .post("/api/v1/transactions/update_localcash_completed_unique", updateStatusData, {
                            headers: { "Content-Type": "application/json" }
                          })
                          .then(function (response) {
                            console.log('success while updating transaction status', response)
                          })
                          .catch(function (error) {
                            console.error('error while updating transaction status', error.response.data)
                          })
                      })
                      .catch(function (error) {
                        console.error('error while creating transfer for Orum', JSON.parse(error.response.data.join('')))
                        // don't want to update transaction status to "failed", because leaving it 
                        // to "processing" allows it to be tested again on next "cornjob" (in case it failed because bank has not been verified yet)
                      })
                  }
                }
              })
              .catch(function (error) {
                console.error('error while getting balance for Orum', error)
                console.error('error while getting balance for Orum', error?.response?.data)
              })
          })
          .catch(function (error) {
            console.error('error while authenticating for Orum', error.response.data)
          })
      })
      .catch(function (e) {
        console.log('Error while getting all transactions with status = requesting localcash', e)
      })
  }

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  const handleSoleOrEinChange = data => {
    // console.log('sole_or_ein_handler_data', data)
    setSoleOrEin(data.target.value)
  }

  const sendSubmissionForm = () => {
    window.open('https://secure.jotform.com/232477193612155?merchantName=' + encodeURIComponent(companies[0].name))
  }

  return (
    // Display the form only if hasAccountInformation is False
    // Else, display "Please wait while we submit your transfer request"
    <>

      <div className="app-main-layout">
        <div className="app-main-layout-header">
          <div className="app-main-layout-wrapper header">
            <div className="page-heading">
              <h2 className="heading-3">Transfer to Bank</h2>
            </div>
            <div className="page-tabs-menu">
              <div
                aria-current="page"
                className="page-tab-link payment-details w--current"
              >
                Transfer to Bank
              </div>
            </div>
          </div>
        </div>
        <div className="app-main-layout-wrapper">
          <div className="app-main-layout-content">
            <div className="section">
              <div className="row w-row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="col w-col w-col-6">
                  {companies && companies[0]?.name === 'a-newest-merchant-company' ? (
                    <button
                      onClick={reconciliationAnsa}
                      className="button button-primary w-button"
                    >
                      ADMIN ONLY - Reconciliation Ansa
                    </button>
                  ) : null
                  }
                  {displayOrumForm ? (
                    <div className="card">
                      <div className="card-heading vertical">
                        <h4>Deposit Your LocalCash™</h4>
                      </div>
                      <div className="w-form">
                        <form
                          id="transfer-to-bank-form"
                          name="transfer-to-bank-form"
                          data-name="Transfer To Bank Form"
                          onSubmit={handleSubmit(submitAccountInformation)}
                        >
                          <div className="form-group">
                            <label className="label" style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 20 }}>
                              Bank Account Information
                            </label>
                            <label className="label">
                              Routing Number
                            </label>
                            <input
                              type="number"
                              aria-invalid={
                                errors.routing_number ? "true" : "false"
                              }
                              {...register("routing_number", {
                                required: "Routing Number is required.",
                                minLength: {
                                  value: 9,
                                  message: "length has to be 9"
                                },
                                maxLength: {
                                  value: 9,
                                  message: "length has to be 9"
                                }
                              })}
                              className="input w-input"
                              maxLength="9"
                              name="routing_number"
                              placeholder="Enter your routing number"
                              id="routing_number"
                            />
                            {errors.routing_number && (
                              <div className="error-message">
                                <div>{errors.routing_number.message}</div>
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Account Number
                            </label>
                            <input
                              type="number"
                              aria-invalid={
                                errors.account_number ? "true" : "false"
                              }
                              {...register("account_number", {
                                required: "Account Number is required.",
                                minLength: {
                                  value: 5,
                                  message: "minimum length is 5"
                                },
                                maxLength: {
                                  value: 17,
                                  message: "maximum length is 17"
                                }
                              })}
                              className="input w-input"
                              maxLength="17"
                              name="account_number"
                              placeholder="Enter your account number"
                              id="account_number"
                            />
                            {errors.account_number && (
                              <div className="error-message">
                                <div>{errors.account_number.message}</div>
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Account Holder Name
                            </label>
                            <input
                              type="text"
                              aria-invalid={
                                errors.account_holder ? "true" : "false"
                              }
                              {...register("account_holder", {
                                required: "Account Holder Name is required.",
                                maxLength: {
                                  value: 21,
                                  message: "maximum length is 21"
                                }
                              })}
                              className="input w-input"
                              // 21 seems to be the maxLength set by VISA
                              maxLength="21"
                              name="account_holder"
                              placeholder="Enter your account holder name"
                              id="account_holder"
                            />
                            {errors.account_holder && (
                              <div className="error-message">
                                <div>{errors.account_holder.message}</div>
                              </div>
                            )}
                          </div>
                          <label className="label" style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 20, marginTop: 40 }}>
                            Legal Address
                          </label>

                          <div className="form-group">
                            <label className="label">
                              Legal Business Name
                            </label>
                            <input
                              type="text"
                              aria-invalid={
                                errors.legal_business ? "true" : "false"
                              }
                              {...register("legal_business", {
                                required: "Legal Business Name is required.",
                                maxLength: {
                                  value: 50,
                                  message: "maximum length is 50"
                                }
                              })}
                              className="input w-input"
                              maxLength="50"
                              name="legal_business"
                              placeholder="Enter your legal business name"
                              id="legal_business"
                            />
                            {errors.legal_business && (
                              <div className="error-message">
                                <div>{errors.legal_business.message}</div>
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="label">
                              Street Address
                            </label>
                            <input
                              type="text"
                              aria-invalid={
                                errors.street_address ? "true" : "false"
                              }
                              {...register("street_address", {
                                required: "Street Address is required.",
                                maxLength: {
                                  value: 100,
                                  message: "maximum length is 100"
                                }
                              })}
                              className="input w-input"
                              maxLength="100"
                              name="street_address"
                              placeholder="Enter your street address"
                              id="street_address"
                            />
                            {errors.street_address && (
                              <div className="error-message">
                                <div>{errors.street_address.message}</div>
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="label">
                              City
                            </label>
                            <input
                              type="text"
                              aria-invalid={
                                errors.city ? "true" : "false"
                              }
                              {...register("city", {
                                required: "City is required.",
                                maxLength: {
                                  value: 50,
                                  message: "maximum length is 50"
                                }
                              })}
                              className="input w-input"
                              maxLength="50"
                              name="city"
                              placeholder="Enter your city"
                              id="city"
                            />
                            {errors.city && (
                              <div className="error-message">
                                <div>{errors.city.message}</div>
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="label">
                              State
                            </label>
                            <select
                              aria-invalid={
                                errors.state ? "true" : "false"
                              }
                              {...register("state", {
                                required: "State is required.",
                                maxLength: {
                                  value: 2,
                                  message: "maximum length is 2"
                                }
                              })}
                              className="input w-input"
                              maxLength="2"
                              name="state"
                              placeholder="Select your state"
                              id="state"
                            >
                              <option value="" selected="selected">Select a State</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                            {errors.state && (
                              <div className="error-message">
                                <div>{errors.state.message}</div>
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="label">
                              Postal / Zip Code
                            </label>
                            <input
                              type="number"
                              aria-invalid={
                                errors.city ? "true" : "false"
                              }
                              {...register("zip", {
                                required: "Postal / Zip Code is required.",
                                minLength: {
                                  value: 5,
                                  message: "length has to be 5"
                                },
                                maxLength: {
                                  value: 5,
                                  message: "length has to be 5"
                                }
                              })}
                              className="input w-input"
                              maxLength="5"
                              name="zip"
                              placeholder="Enter your postal / zip code"
                              id="zip"
                            />
                            {errors.zip && (
                              <div className="error-message">
                                <div>{errors.zip.message}</div>
                              </div>
                            )}
                          </div>

                          <label className="label" style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 20, marginTop: 40 }}>
                            Are you a sole proprietor or an EIN (LLC, C-corp, etc.)?
                          </label>

                          <div className="form-group">
                            <label className="label">
                              Sole Proprietor or EIN?
                            </label>
                            <select
                              aria-invalid={
                                errors.sole_or_ein ? "true" : "false"
                              }
                              {...register("sole_or_ein", {
                                required: "Sole Proprietor or EIN is required.",
                                maxLength: {
                                  value: 20,
                                  message: "maximum length is 20"
                                }
                              })}
                              className="input w-input"
                              maxLength="20"
                              name="sole_or_ein"
                              placeholder="Select your status"
                              id="sole_or_ein"
                              onChange={handleSoleOrEinChange}
                            >
                              <option value="" selected="selected">Sole Proprietor or EIN?</option>
                              <option value="sole_proprietor">Sole Proprietor</option>
                              <option value="ein">EIN</option>
                            </select>
                            {errors.sole_or_ein && (
                              <div className="error-message">
                                <div>{errors.sole_or_ein.message}</div>
                              </div>
                            )}
                          </div>
                          {soleOrEIn == 'sole_proprietor' && (
                            <div className="form-group">
                              <label className="label">
                                Last four numbers of your SSN
                              </label>
                              <input
                                type="number"
                                aria-invalid={
                                  errors.ssn ? "true" : "false"
                                }
                                {...register("ssn", {
                                  required: "Last four numbers of SSN are required.",
                                  minLength: {
                                    value: 4,
                                    message: "length has to be 4"
                                  },
                                  maxLength: {
                                    value: 4,
                                    message: "length has to be 4"
                                  }
                                })}
                                className="input w-input"
                                maxLength="4"
                                name="ssn"
                                placeholder="Enter the last four numbers of your SSN"
                                id="ssn"
                              />
                              {errors.ssn && (
                                <div className="error-message">
                                  <div>{errors.ssn.message}</div>
                                </div>
                              )}
                            </div>
                          )}
                          {/* {console.log("sole_or_ein", soleOrEIn)} */}
                          {soleOrEIn == 'ein' && (
                            <div>
                              <div className="form-group">
                                <label className="label">
                                  EIN
                                </label>
                                <input
                                  type="number"
                                  aria-invalid={
                                    errors.ein ? "true" : "false"
                                  }
                                  {...register("ein", {
                                    required: "EIN is required.",
                                    minLength: {
                                      value: 9,
                                      message: "length has to be 9"
                                    },
                                    maxLength: {
                                      value: 9,
                                      message: "length has to be 9"
                                    }
                                  })}
                                  className="input w-input"
                                  maxLength="9"
                                  name="ein"
                                  placeholder="Enter your EIN"
                                  id="ein"
                                />
                                {errors.ein && (
                                  <div className="error-message">
                                    <div>{errors.ein.message}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <button
                            type="submit"
                            className="button button-primary w-button"
                          >
                            Deposit Your LocalCash™
                          </button>
                        </form>
                        <div className="success-message w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="error-message w-form-fail">
                          <div>
                            Oops! Something went wrong while submitting the form.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {isRequestComplete ? (
                        <div>
                          {isRequestSuccessful ? (
                            <div className="card">
                              <div className="card-heading vertical">
                                <h4>Your request is completed. Please wait while your request is being processed.</h4>
                              </div>
                            </div>
                          ) : (
                            <div className="card">
                              <div className="card-heading vertical">
                                <h4>There is an issue with your request. Please contact us.</h4>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="card">
                          <div className="card-heading vertical">
                            <h4>Your request is being processed, please wait. Please refresh the page if this message is still displayed after a few minutes.</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* <div className="card">
                    <div className="card-heading vertical">
                      <button onClick={sendSubmissionForm} style={{ display: 'flex', justifyContent: 'space-between', fontSize: '25px', fontWeight: 'bold', color: 'black' }}>
                        Send Submission Form
                      </button>
                      <div>
                        <br></br>
                        Complete this form so we can send your transfer once your request's processing is completed. <br></br>
                        You only need to complete this form if you never completed it before or if a Localight member asked you to complete it again.
                      </div>
                    </div>
                  </div> */}

                </div>

              </div>
            </div>
          </div>
          <div className="footer">
            <div className="w-row">
              <div className="w-col w-col-6">
                <div className="hint">
                  © Copyright Localight Inc. All rights reserved.
                </div>
              </div>
              <div className="footer-right w-col w-col-6">
                <div className="hint">
                  <Link underline="none" href="#" className="footer-link">
                    Spotlight Business
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default MyAccountsContainer
