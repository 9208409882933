import React from "react"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"

const SliderTitle = ({ title, subtitle, icon, alt }) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 1,
        px: 1
      }}
    >
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center"
        }}
      >
        {icon && <img src={icon} alt={alt} width={40} height={40} />}
        <Typography
          sx={{
            color: "#62898f",
            fontSize: 15,
            px: 1
          }}
        >
          {title || "Title"}
        </Typography>
      </div>

      {subtitle && (
        <Typography
          sx={{
            color: "#62898f",
            fontSize: 15
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Container>
  )
}

export default SliderTitle
