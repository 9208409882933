import axios from "axios"

import { appConfig } from "../config/app"
import { store } from "../store"
import { AuthApiServices } from "../store/auth/services"

export const request = axios.create({
  baseURL: appConfig.baseURL,
  timeout: appConfig.defaultTimeout,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
  }
})

request.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    if (error && error.response && error.response.status === 401) {
      const token = localStorage.getItem("token")
      if (token != null) {
        request.defaults.headers.common["Authorization"] = "Token " + token
      }
    }

    return Promise.reject(error)
  }
)

export const setHeaderfunction = () => {
  request.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded"
  // todo add auth token from store
  store.subscribe(handleTokenChange)
  // you can add more default values for http requests here
}

function handleTokenChange() {
  let token = localStorage.getItem("token")
  console.log(token)
  if (token != null) {
    request.defaults.headers.common["Authorization"] = "Token " + token
  }
}
