
import React, { memo } from "react";
import Slider from "react-slick";


const Carousel = memo(({ settings, children }) => {
  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
});

export default Carousel;
