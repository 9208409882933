import React, { useEffect } from "react"
import { Provider as ReduxProvider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"

import "./css/localight-dashboard.webflow.css"
import "./css/normalize.css"
import "./css/webflow.css"

import { store, persistor, history } from "./store"
import { PersistGate } from "redux-persist/integration/react"
import { Route, Switch, Redirect } from "react-router-dom"
import CssBaseline from "@mui/material/CssBaseline"

import { setHeaderfunction } from "./utils/http"

// business

import Dashboard from "./pages/Dashboard"
import Customers from "./pages/Customers"
import Payments from "./pages/Payments"
import LocationProfile from "./pages/LocationProfile"
import MyAccounts from "./pages/MyAccounts"
import LoginPage from "./pages/Login"
import SignupPage from "./pages/SignUp"
import OnBoarding from "./pages/OnBoarding"
import PasswordResetPage from "./pages/PasswordReset"
import PasswordResetConfirmPage from "./pages/PasswordReset/reset"
import TransferToBank from "./pages/TransferToBank"

// customers

import Home from "./pages/Home"
import Splash from "./pages/Splash"
import BusinessLanding from "./pages/BusinessLanding"
import CustomerSignup from "./pages/CustomerSignup"
import CustomerProfile from "./pages/Profile"
import { Helmet } from "react-helmet"
import Analytics from "./components/Client/Analytics"

const App = () => {
  console.log("public url: ", process.env.PUBLIC_URL)

  useEffect(() => {
    setHeaderfunction()
  }, [])

  return (
    <ReduxProvider store={store}>
      <Helmet htmlAttributes>
        <html lang="en" />
        <meta name="theme-color" content="#152A38" />
      </Helmet>
      <Analytics />
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <div className="App">
            <CssBaseline />
            <Switch>
              <Route exact path="/dashboard/login" component={LoginPage} />
              <Route exact path="/company/signup" component={SignupPage} />
              <Route
                exact
                path="/forgot-password"
                component={PasswordResetPage}
              />
              <Route
                exact
                path="/reset-password"
                component={PasswordResetConfirmPage}
              />

              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/dashboard/customers" component={Customers} />
              <Route exact path="/dashboard/payments" component={Payments} />
              <Route
                exact
                path="/dashboard/onboarding"
                component={OnBoarding}
              />
              <Route
                exact
                path="/dashboard/location-profile"
                component={LocationProfile}
              />
              <Route
                exact
                path="/dashboard/account-settings"
                component={MyAccounts}
              />
              <Route
                exact
                path="/dashboard/transfer-to-bank"
                component={TransferToBank}
              />

              {/* customers */}

              <Route exact path="/" component={Splash} />
              <Route exact path="/anchor/:anchor" component={Home} />
              <Route
                exact
                path="/customers/signup"
                component={CustomerSignup}
              />
              <Route
                exact
                path="/customers/transactions"
                component={CustomerProfile}
              />
              <Route exact path="/business/:id/*" component={BusinessLanding} />

              {/* <Route component={NotFound} /> */}
            </Switch>
          </div>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  )
}

export default App
