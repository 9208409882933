import React, { useEffect, useState } from "react"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { Link } from "react-router-dom"
import BusinessAvatar from "../../../assets/images/BusinessAvatar.png"
import { Share } from "@capacitor/share"
import { getPathFromUrl } from "../../../utils/getURL"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import {
  CallIcon,
  WebIcon,
  MapIcon,
  ShareIcon,
  LocalightLogoIcon
} from "../Icons"
import { useSelector } from "react-redux"
import { request } from "../../../utils/http"
import Pay from "../../../assets/images/Pay.png"
import Fave from "../../../assets/images/HeartOutline.svg"
import FaveFill from "../../../assets/images/HeartOutlineFill.svg"

const BusinessProfile = ({ business, navigateJoinIn }) => {
  const customer = useSelector(state => state.customerReducer.customer.data)
  const customerType = useSelector(state =>
    state.authReducer.user && state.authReducer.user.type
      ? state.authReducer.user.type
      : ""
  )
  const customerToken = useSelector(state => state.authReducer.token)
  const [points, setPoints] = useState(0)
  const [fave, setFave] = useState(business?.is_favourite)

  const options = [
    {
      name: "Website",
      icon: (
        <Button
          variant="outlined"
          size="small"
          sx={{
            m: 1,
            borderRadius: 20,
            borderColor: "#7EA3AA",
            color: "#7EA3AA",
            minWidth: 120
          }}
          onClick={() => window.open(business.website, "_blank")}
          endIcon={<WebIcon />}
        >
          Website
        </Button>
      )
    },
    {
      name: "Call",
      icon: (
        <Button
          href={`tel:${business.phone_no}`}
          variant="outlined"
          size="small"
          sx={{
            m: 1,
            borderRadius: 20,
            borderColor: "#7EA3AA",
            color: "#7EA3AA",
            minWidth: 120
          }}
          endIcon={<CallIcon />}
        >
          Call
        </Button>
      )
    },
    {
      name: "Map It",
      icon: (
        <Button
          variant="outlined"
          size="small"
          sx={{
            m: 1,
            borderRadius: 20,
            borderColor: "#7EA3AA",
            color: "#7EA3AA",
            minWidth: 120
          }}
          onClick={() => window.open(business.google_map, "_blank")}
          endIcon={<MapIcon />}
        >
          <span>Map</span>
          <span>It</span>
        </Button>
      )
    },
    {
      name: "Share",
      icon: (
        <Button
          variant="outlined"
          size="small"
          sx={{
            m: 1,
            borderRadius: 20,
            borderColor: "#7EA3AA",
            color: "#7EA3AA",
            minWidth: 120
          }}
          onClick={() => shareBusiness()}
          endIcon={<ShareIcon />}
        >
          Share
        </Button>
      )
    }
  ]

  useEffect(() => {
    if (customerType === "customer") {
      getPoints()
    }
  }, [])

  const getPoints = () => {
    request
      .get("api/v1/get-customer-points")
      .then(res => {
        console.log(res)
        setPoints(res.data.total_points)
      })
      .catch(e => console.log(e.response.data))
  }

  const favorite = () => {
    const data = {
      company: business?.id
    }
    request
      .post("/api/v1/businesses/favourite", data, {
        headers: { "Content-Type": "application/json" }
      })
      .then(res => {
        console.log(res)
        setFave(!fave)
      })
      .catch(e => console.log(e.response.data))
  }

  const shareBusiness = async () => {
    const href = window.location
    const share = await Share.share({
      title: "Localight",
      text: "Join Localight and earn 2% to 5% back",
      url: href,
      dialogTitle: "Share with friends"
    })
  }

  return (
    <>
      <Container maxWidth="sm" disableGutters>
        <Box
          sx={{
            backgroundImage: `url(${
              getPathFromUrl(business?.image) ||
              "https://cdn.pixabay.com/photo/2017/11/12/19/17/vegetables-landscape-2943500_1280.jpg"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: 150,
            height: 150,
            borderBottomRightRadius: 40,
            borderBottomLeftRadius: 40
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            spacing={1}
          >
            <Link to={"/anchor/" + business.anchorList}>
              <Avatar
                alt="Localight Icon"
                sx={{
                  m: 2,
                  marginLeft: 2.5,
                  p: 0.5,
                  height: 50,
                  width: 50,
                  bgcolor: "#102B3C",
                  border: "1px solid #4D898BA6"
                }}
              >
                <LocalightLogoIcon />
              </Avatar>
            </Link>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              sx={{
                minHeight: 150,
                p: 1,
                paddingRight: 2
              }}
            >
              {customerType === "customer" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "rgba(78,78,78,0.2)",
                    backdropFilter: "blur(2px)",
                    borderRadius: 30,
                    padding: 5
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      alignSelf: "flex-end",
                      marginRight: 5
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <LocationOnIcon
                        sx={{ color: "#FFF", height: 15, mt: 0.5 }}
                        fontSize={"small"}
                      />
                      <p style={{ color: "#FFF", marginBottom: 0 }}>{points}</p>
                    </div>
                    <p style={{ color: "#FFF", fontSize: 10, marginBottom: 0 }}>
                      {customer?.first_name}
                    </p>
                  </div>

                  <Link to="/customers/transactions">
                    <Avatar
                      alt="Down arrow"
                      sx={{
                        bgcolor: "transparent"
                      }}
                    ></Avatar>
                  </Link>
                </div>
              ) : null}
              <Avatar
                alt="Love Icon"
                onClick={() => favorite()}
                sx={{
                  bgcolor: "transparent"
                  // color: 'red'
                }}
              >
                {fave ? <img src={FaveFill} /> : <img src={Fave} />}
              </Avatar>
            </Stack>
          </Stack>
        </Box>
      </Container>
      <Container
        maxWidth="sm"
        style={{
          backgroundColor: "white",
          borderTopRightRadius: 30,
          borderTopLeftRadius: 30,
          height: 704
        }}
      >
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: 1 }}
        >
          <Avatar
            alt="Business profile picture"
            src={getPathFromUrl(business?.profile_image) || BusinessAvatar}
            sx={{
              width: 85,
              height: 85,
              margin: 1,
              border: "3px dotted #B1D5DD"
            }}
          />
          <Stack>
            <Typography
              sx={{
                textAlign: "left",
                color: "#184552",
                opacity: 1,
                letterSpacing: "-0.42px",
                lineHeight: 1.1,
                fontSize: "21px",
                fontWeight: 600
              }}
            >
              {business?.name}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        ></Stack>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={1}
          sx={{ mx: 1 }}
        >
          <Typography
            sx={{
              textAlign: "left",
              color: "#62898F",
              opacity: 1,
              letterSpacing: "0px",
              lineHeight: 1.1,
              fontSize: "14px",
              fontWeight: "medium"
            }}
          >
            {business?.locations[0]?.complete_address || ""}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          sx={{ my: 2 }}
        >
          {options.map(item => {
            return item.icon
          })}
        </Stack>
        <Stack
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{ my: 2.5 }}
        >
          {business?.is_onboarded ? (
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              <p
                style={{
                  color: "#000",
                  marginTop: 60,
                  fontSize: 20,
                  width: 220,
                  textAlign: "center"
                }}
              >
                Use <b>Localight Pay</b> to get 2% to 5% back
              </p>
              <button
                onClick={() => navigateJoinIn()}
                style={{
                  backgroundColor: "#C79F5F",
                  marginTop: 20,
                  marginBottom: 40,
                  marginLeft: 20,
                  borderRadius: 40,
                  padding: 10,
                  width: 170,
                  alignItems: "center",
                  alignSelf: "center"
                }}
              >
                <img style={{ width: 100, height: 50 }} src={Pay} />
              </button>
            </div>
          ) : (
            <div>
              <p
                style={{
                  color: "#000",
                  marginTop: 60,
                  fontSize: 20,
                  width: 220,
                  textAlign: "center"
                }}
              >
                Fave this business to be notified about offers
              </p>
              <button
                onClick={() =>
                  customerToken != null ? favorite() : navigateJoinIn()
                }
                style={{
                  backgroundColor: "#C79F5F",
                  marginTop: 20,
                  marginBottom: 40,
                  marginLeft: 20,
                  borderRadius: 40,
                  padding: 10,
                  width: 170,
                  flexDirection: "row"
                }}
              >
                {fave ? <img src={FaveFill} /> : <img src={Fave} />}
                <p
                  style={{
                    color: "#FFF",
                    fontSize: 20,
                    marginLeft: 10
                  }}
                >
                  Fave Business
                </p>
              </button>
            </div>
          )}
        </Stack>
      </Container>
    </>
  )
}

export default BusinessProfile
