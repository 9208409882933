import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import TransferToBankContainer from '../../containers/TransferToBankContainer';

const MyAccounts = (props) => {

    return <DashboardLayout>
        <TransferToBankContainer {...props} />
    </DashboardLayout>
};

export default MyAccounts;
