import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import MyAccountsContainer from '../../containers/MyAccountsContainer';

const MyAccounts = (props) => {

    return <DashboardLayout>
        <MyAccountsContainer {...props} />
    </DashboardLayout>
};

export default MyAccounts;
