import { request } from "../../utils/http"
// import qs from 'qs';

function getCompany() {
  return request
    .get("/api/v1/mycompanies")
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function getAllCompany(anchor) {
  return request
    .get("/api/v1/businesses?featured=True&anchor=" + anchor)
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function getCompanybyCategory(anchor, category) {
  return request
    .get("/api/v1/businesses?category=" + category + "&anchor=" + anchor)
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error.response.data
    })
}

function getCompanyDetails(payload) {
  return request
    .get(`/api/v1/business/${payload.id}`)
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function addCompany(payload) {
  let name = payload.data["name"]
  let description = payload.data["description"]
  let phone_no = payload.data["phone"]
  let category = payload.data["category"]
  let located_in = payload.data["located_in"]
  let complete_address = payload.data["complete_address"]
  let location_name = payload.data["location_name"]
  let bio_description = payload.data["bio_description"]
  let first_name = payload.data["first_name"]
  let last_name = payload.data["last_name"]
  let date_of_birth = payload.data["date_of_birth"]
  let gender = payload.data["gender"]
  let email = payload.data["email"]
  let phone = payload.data["phone_no"]

  return request
    .post(
      "/api/v1/mycompanies",
      {
        name: name,
        description: description,
        phone_no: phone_no,
        category: category,
        locations: [
          {
            located_in: located_in,
            complete_address: complete_address,
            location_name: location_name,
            bio_description: bio_description,
            personnels: [
              {
                first_name: first_name,
                last_name: last_name,
                date_of_birth: date_of_birth,
                gender: gender,
                email: email,
                phone_no: phone
              }
            ]
          }
        ]
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function getLocations() {
  return request
    .get("/api/v1/location")
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function getCategories() {
  return request
    .get("/api/v1/category")
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function addCompanyImage(payload) {
//  let profile_image = payload.image
  console.log('profile_image inside services.js:', payload.get("profile_image"))
  return request
    .patch(`/api/v1/mycompanies/${payload.get("id")}/upload-image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

function addCompanyCoverImage(payload) {
//  let image = payload.image
  console.log('(cover) image inside services.js:', payload.get("image"))
  return request
    .patch(`/api/v1/mycompanies/${payload.get("id")}/upload-image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data))
    })
    .catch(function (error) {
      throw error
    })
}

export const CompanyApiServices = {
  getCompany,
  getAllCompany,
  getCompanyDetails,
  addCompany,
  getLocations,
  getCategories,
  addCompanyImage,
  addCompanyCoverImage,
  getCompanybyCategory
}
