export const DO_LOGIN = "DO_LOGIN";
export const DO_LOGIN_SUCCEEDED = "DO_LOGIN_SUCCEEDED";
export const DO_LOGIN_FAILED = "DO_LOGIN_FAILED";

export const DO_FBLOGIN = "DO_FBLOGIN";
export const DO_FBLOGIN_SUCCEEDED = "DO_FBLOGIN_SUCCEEDED";
export const DO_FBLOGIN_FAILED = "DO_FBLOGIN_FAILED";

export const DO_GOOGLELOGIN = "DO_GOOGLELOGIN";
export const DO_GOOGLELOGIN_SUCCEEDED = "DO_GOOGLELOGIN_SUCCEEDED";
export const DO_GOOGLELOGIN_FAILED = "DO_GOOGLELOGIN_FAILED";

export const DO_REGISTER = "DO_REGISTER";
export const DO_REGISTER_SUCCEEDED = "DO_REGISTER_SUCCEEDED";
export const DO_REGISTER_FAILED = "DO_REGISTER_FAILED";

export const DO_PWDRESET = "DO_PWDRESET";
export const DO_PWDRESET_SUCCEEDED = "DO_PWDRESET_SUCCEEDED";
export const DO_PWDRESET_FAILED = "DO_PWDRESET_FAILED";
export const DO_PWDRESET_STATUS = "DO_PWDRESET_STATUS";

export const SET_STATUS = "SET_STATUS";

export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const DO_LOGOUT = "DO_LOGOUT"
export const DO_LOGOUT_SUCCESS = "DO_LOGOUT_SUCCESS";

export const DO_OTP_SIGNUP = "DO_OTP_SIGNUP";
export const DO_OTP_SIGNUP_SUCCEEDED = "DO_OTP_SIGNUP_SUCCEEDED";
export const DO_OTP_SIGNUP_FAILED = "DO_OTP_SIGNUP_FAILED";

export const DO_OTP_LOGIN = "DO_OTP_LOGIN";
export const DO_OTP_LOGIN_SUCCEEDED = "DO_OTP_LOGIN_SUCCEEDED";
export const DO_OTP_LOGIN_FAILED = "DO_OTP_LOGIN_FAILED";