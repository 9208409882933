export const GET_COMPANY = "GET_COMPANY";
export const SET_COMAPNY = "SET_COMAPNY";
export const UPDATE_COMAPNY = "UPDATE_COMAPNY";
export const GSU_COMPANY_FAILED = "GSU_COMPANY_FAILED";
export const ADD_COMAPNY = "ADD_COMAPNY";

export const GET_LOCATION = "GET_LOCATION";
export const SET_LOCATION = "SET_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const GSU_LOCATION_FAILED = "GSU_LOCATION_FAILED";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GS_CATEGORIES_FAILED = "GS_CATEGORIES_FAILED";

export const SET_PROFILE_PIC = 'SET_PROFILE_PIC';
export const SET_COVER_PIC = 'SET_COVER_PIC';