import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import OnboardingContainer from '../../containers/OnboardingContainer';

const OnBoarding = (props) => {

    const [step, setStep] = useState(1);

    return <AuthLayout steps={true} setStep={setStep} number={step} >
        <OnboardingContainer {...props} step={step} setStep={setStep} />
    </AuthLayout>
};

export default OnBoarding;
