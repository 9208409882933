import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import SignupContainer from '../../containers/SignupContainer';

const SignupPage = (props) => {


    return <AuthLayout>
        <SignupContainer {...props} />
    </AuthLayout>
};

export default SignupPage;
