import React from "react"
import Container from "@mui/material/Container"
import Ellipse from "../../../assets/images/Ellipse.svg"
import bg from "../../../assets/images/llbg.png"

const HomeBox = ({ children }) => {
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
        backgroundColor: "#edf3f4",
        opacity: 1
        // backgroundAttachment: "fixed",
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundImage: `url(${bg})`,
        // backgroundRepeat: "no-repeat"
      }}
    >
      {children}
    </Container>
  )
}

export default HomeBox
