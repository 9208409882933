import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import PasswordResetContainer from '../../containers/PasswordResetContainer';

const PasswordResetPage = (props) => {

    return  <AuthLayout>
                <PasswordResetContainer {...props}/>
        </AuthLayout>
};

export default PasswordResetPage;
