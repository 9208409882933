import * as types from "./constants";
import * as status from '../constants';
import * as StoreUtil from '../storeUtil';
// import produce from "immer";

const initialState = {
    errorMessage: null,
    company: {
        data: null,
        status: null,
    },
    categories: {
        data: null,
        status: null,
    },
    locations: {
        data: null,
        status: null,
    },
}

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_COMPANY:
            return {
                ...state,
                company: {
                    status: status.LOADING
                },
            }
        case types.SET_COMAPNY:
            return {
                ...state,
                company: {
                    data: action.response,
                    status: status.SUCCESS
                },
            }
        case types.GSU_COMPANY_FAILED:
            return {
                ...state,
                company: {
                    status: status.FAILED,
                    errorMessage: StoreUtil.getErrorMessage(action)
                },
            }
        case types.GET_LOCATION:
            return {
                ...state,
                locations: {
                    status: status.LOADING
                },
            }
        case types.SET_LOCATION:
            return {
                ...state,
                locations: {
                    data: action.response,
                    status: status.SUCCESS
                }
            }
        case types.GSU_LOCATION_FAILED:
            return {
                ...state,
                locations: {
                    status: status.FAILED,
                    errorMessage: StoreUtil.getErrorMessage(action)
                }
            }

        case types.GET_CATEGORIES:
            return {
                ...state,
                categories: {
                    status: status.LOADING,
                },
            }
        case types.SET_CATEGORIES:
            return {
                ...state,
                categories: {
                    data: action.response,
                    status: status.SUCCESS,
                    errorMessage: null
                }
            }
        case types.GS_CATEGORIES_FAILED:
            return {
                ...state,
                categories: {
                    status: status.FAILED,
                    errorMessage: StoreUtil.getErrorMessage(action)
                }
            }

        case types.SET_PROFILE_PIC:

            return {
                ...state,
                company: {
                    data: action.data,
                },
            }

        case types.SET_COVER_PIC:
            return {
                ...state,
                company: {
                    data: action.data,
                },
            }

        default: {
            return state;
        }
    }
}

export default companyReducer;
