import React, { useState, useEffect } from "react"
import Link from "@mui/material/Link"
import Radio from "@mui/material/Radio"
import Grid from "@mui/material/Grid"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
// import FormLabel from '@mui/material/FormLabel';
// import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form"
import { ADD_COMAPNY } from "../../store/company/constants"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"

const OnboardingContainer = ({ setStep, step, ...props }) => {
  const dispatch = useDispatch()
  const status = useSelector(state => state.authReducer.login.status)
  const categories = useSelector(state => state.companyReducer.categories.data)
  // const companies = useSelector(state => state.companyReducer.company.data);
  const googleStatus = useSelector(
    state => state.authReducer.googleLogin.status
  )

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()

  const [usertype, setUserType] = useState("owner")
  const [companyCategory, setCompanyCategory] = useState([])
  const [gender, setGender] = useState("")

  useEffect(() => {
    console.log("status", status)
    const token = localStorage.getItem("token")
    if (!token) {
      dispatch(push("/dashboard/login"))
    }
  }, [status, dispatch, googleStatus])

  const options = !!categories?.length
    ? categories.map(item => {
        return { value: item.id, label: item.name }
      })
    : []

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
  ]

  const submitCompanyInfo = async data => {
    const payload = {
      name: data.company_name,
      description: data.company_description,
      phone: data.company_phone,
      category: companyCategory.map(item => item.value),
      located_in: data.company_locatedin,
      complete_address: data.company_address,
      location_name: data.company_city,
      bio_description: data.location_description,
      first_name: data.first_name,
      last_name: data.last_name,
      date_of_birth: data.date_of_birth,
      gender: gender?.value,
      email: data.email,
      phone_no: data.phone
    }

    if (gender && companyCategory)
      dispatch({
        type: ADD_COMAPNY,
        data: payload
      })
  }

  return (
    <>
      <div className="single-layout-right">
        <div className="single-layout-right-content">
          <div className="single-layout-logo-right">
            <Link href="#" className="w-inline-block">
              <img
                src="/static/Localight-Logo-reverse.svg"
                width="150"
                alt=""
              />
            </Link>
          </div>
          {step === 1 && (
            <div className="card">
              <h3 className="text-center mb-40">
                Put your business growth on autopilot.
              </h3>
              <p className="text-center text-large">
                Your business needs automatic marketing the brings in new
                customers, reward loyal customers, and increase your revenue.
                Customer payments appear in your Localight dashboard in real
                time.
              </p>
              <p className="text-center text-large">
                Save your time and money!
              </p>
              <div className="text-center">
                <button
                  onClick={() => {
                    setStep(2)
                  }}
                  className="button button-primary mt-20 button-block w-button"
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="card">
              <h3 className="text-center">Account type</h3>
              <p className="mb-40 text-center">
                Please select business owner type
              </p>
              <div className="w-form">
                <div className="row radio-block-container w-row">
                  <Grid
                    container
                    spacing={0}
                    // direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="acctype"
                        name="row-radio-buttons-group"
                        value={usertype}
                        onChange={e => setUserType(e.target.value)}
                      >
                        <FormControlLabel
                          value="owner"
                          control={<Radio />}
                          label="Owner"
                        />
                        <FormControlLabel
                          value="staff"
                          control={<Radio />}
                          label="Staff"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => {
                      setStep(3)
                    }}
                    className="button button-primary mt-20 button-block w-button"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}

          <form
            id="onboarding-form"
            name="onboarding-form"
            onSubmit={handleSubmit(submitCompanyInfo)}
          >
            {step === 3 && (
              <div className="card">
                <h3 className="text-center">Company Details</h3>
                <p className="mb-40 text-center">Setup your company</p>
                <div className="w-form">
                  <label htmlFor="company_name" className="label">
                    Company name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.company_name ? "true" : "false"}
                    {...register("company_name", {
                      required: "Company name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="company_name"
                    placeholder="Enter your company name"
                    id="company_name"
                  />
                  {errors.company_name && (
                    <div className="error-message">
                      <div>{errors.company_name.message}</div>
                    </div>
                  )}
                  <label htmlFor="company_description" className="label">
                    Company description
                  </label>
                  <textarea
                    aria-invalid={errors.company_description ? "true" : "false"}
                    {...register("company_description", {
                      required: "Company description is required."
                    })}
                    className="textarea w-input"
                    maxLength="5000"
                    name="company_description"
                    placeholder="Enter Company description"
                    id="company_description"
                  />
                  {errors.company_description && (
                    <div className="error-message">
                      <div>{errors.company_description.message}</div>
                    </div>
                  )}
                  <label htmlFor="company_phone" className="label">
                    Phone no
                  </label>
                  <input
                    type="tel"
                    aria-invalid={errors.company_phone ? "true" : "false"}
                    {...register("company_phone", {
                      required: "Company phone no is required."
                    })}
                    className="input w-input"
                    maxLength="15"
                    name="company_phone"
                    placeholder="Enter your company phone no"
                    id="company_phone"
                  />
                  {errors.company_phone && (
                    <div className="error-message">
                      <div>{errors.company_phone.message}</div>
                    </div>
                  )}
                  <label htmlFor="company-category" className="label">
                    Company category
                  </label>
                  <Select
                    isMulti
                    options={options}
                    value={companyCategory}
                    onChange={value => setCompanyCategory(value)}
                  />
                  <div className="text-center">
                    <button
                      onClick={() => {
                        setStep(4)
                      }}
                      className="button button-primary mt-20 button-block w-button"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="card">
                <h3 className="text-center">Location</h3>
                <p className="mb-40 text-center">Setup company location</p>
                <div className="w-form">
                  <label htmlFor="company_locatedin" className="label">
                    Located in
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.company_locatedin ? "true" : "false"}
                    {...register("company_locatedin", {
                      required: "Company location is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="company_locatedin"
                    placeholder="Located in"
                    id="company_locatedin"
                  />
                  {errors.company_locatedin && (
                    <div className="error-message">
                      <div>{errors.company_locatedin.message}</div>
                    </div>
                  )}
                  <label htmlFor="company_address" className="label">
                    Complete Address
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.company_address ? "true" : "false"}
                    {...register("company_address", {
                      required: "Company address is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="company_address"
                    placeholder="Complete Address"
                    id="company_address"
                  />
                  {errors.company_address && (
                    <div className="error-message">
                      <div>{errors.company_address.message}</div>
                    </div>
                  )}
                  <label htmlFor="company_city" className="label">
                    City
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.company_city ? "true" : "false"}
                    {...register("company_city", {
                      required: "Company city is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="company_city"
                    placeholder="City name"
                    id="company_city"
                  />
                  {errors.company_city && (
                    <div className="error-message">
                      <div>{errors.company_city.message}</div>
                    </div>
                  )}
                  <label htmlFor="location_description" className="label">
                    Location description
                  </label>
                  <textarea
                    aria-invalid={
                      errors.location_description ? "true" : "false"
                    }
                    {...register("location_description", {
                      required: "Location description is required."
                    })}
                    className="textarea w-input"
                    maxLength="5000"
                    name="location_description"
                    placeholder="Enter Location details"
                    id="location_description"
                  />
                  {errors.location_description && (
                    <div className="error-message">
                      <div>{errors.location_description.message}</div>
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      onClick={() => {
                        setStep(5)
                      }}
                      className="button button-primary mt-20 button-block w-button"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            )}

            {step === 5 && (
              <div className="card">
                <h3 className="text-center">Personal Info</h3>
                <p className="mb-40 text-center">Give your information</p>
                <div className="w-form">
                  <label htmlFor="first_name" className="label">
                    First Name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.first_name ? "true" : "false"}
                    {...register("first_name", {
                      required: "First name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="first_name"
                    placeholder="Your first name"
                    id="first_name"
                  />
                  {errors.first_name && (
                    <div className="error-message">
                      <div>{errors.first_name.message}</div>
                    </div>
                  )}
                  <label htmlFor="last_name" className="label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.last_name ? "true" : "false"}
                    {...register("last_name", {
                      required: "Last name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="last_name"
                    placeholder="Your last name"
                    id="last_name"
                  />
                  {errors.last_name && (
                    <div className="error-message">
                      <div>{errors.last_name.message}</div>
                    </div>
                  )}
                  <label htmlFor="date_of_birth" className="label">
                    Date of birth
                  </label>
                  <input
                    type="date"
                    aria-invalid={errors.date_of_birth ? "true" : "false"}
                    {...register("date_of_birth", {
                      required: "Date of birth is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="date_of_birth"
                    placeholder="Your date of birth"
                    id="date_of_birth"
                  />
                  {errors.date_of_birth && (
                    <div className="error-message">
                      <div>{errors.date_of_birth.message}</div>
                    </div>
                  )}
                  <label htmlFor="p-gender" className="label">
                    Gender
                  </label>
                  <Select
                    options={genderOptions}
                    value={gender}
                    onChange={value => setGender(value)}
                  />
                  <label htmlFor="email" className="label">
                    Email
                  </label>
                  <input
                    type="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register("email", {
                      required: "Email is required.",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format"
                      }
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="email"
                    placeholder="Your mail"
                    id="email"
                  />
                  {errors.email && (
                    <div className="error-message">
                      <div>{errors.email.message}</div>
                    </div>
                  )}
                  <label htmlFor="phone" className="label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    aria-invalid={errors.phone ? "true" : "false"}
                    {...register("phone", {
                      required: "Phone no is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="phone"
                    placeholder="Your phone no"
                    id="phone"
                  />
                  {errors.phone && (
                    <div className="error-message">
                      <div>{errors.phone.message}</div>
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      type="submit"
                      className="button button-primary mt-20 button-block w-button"
                    >
                      Finish
                    </button>
                  </div>
                </div>
                {(errors.phone ||
                  errors.email ||
                  errors.date_of_birth ||
                  errors.last_name ||
                  errors.first_name ||
                  errors.location_description ||
                  errors.company_city ||
                  errors.company_address ||
                  errors.company_locatedin ||
                  errors.company_phone ||
                  errors.company_description ||
                  errors.company_name ||
                  !gender ||
                  !companyCategory) && (
                  <div className="error-message">
                    <div>
                      One or more fields are empty. Please check all the steps.
                    </div>
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

export default OnboardingContainer
