
import React, { useEffect, useState } from "react";
import './func.css'
import {b}  from "../Example.js"
import {min} from "../Example.js"
import "../func.css"
import { request } from "../../../utils/http";
import Popup from 'reactjs-popup';



let rangefun = 0
let a = 0;
function setA(value) {
  a = value;
}


function proportion(val1){
  rangefun =  parseInt(val1) /parseInt(b)
  rangefun = rangefun.toFixed(1)
}
{/* 
const update_data = () => {
  request
    .get("/api/v1/mycompanies/{nachos-mexican-food}")
    .then(data => {
      console.log("DOCS", data.data)
    })
    .catch(e => {
      console.log(e)
    })
}
*/}
const RangeSlider = () => {
  var rangeSlider = document.getElementById("rs-range-line");
  var rangeBullet = document.getElementById("rs-bullet");
  
  const [range, setRange] = React.useState(0);
  const [mtext, allow] = useState("");
  const [maxshow, maxshowallow] = useState("");
  const [resultshow, resultshowallow] = useState("");
  const handlemaxshow = (event) => {
    console.log('handled max show')
    maxshow(event);
  };

  
  const handle_allow = (event) => {
    allow(event)

  };
 let x = 0
    const [step, setStep] = React.useState(0);
    const ref = React.useRef(null);
    const getRange = (ev) => {
      if (x === 0){
        resultshowallow(ev)
      }
      if (x === 0){
        maxshowallow(ev)
      }
      if (ev.target.value < min*5){
        
        setRange(ev.target.value);
        proportion(ev.target.value)


     

        setA(ev.target.value)
        
      }
      else{
        
        ev.target.value = 300
      }

      
    };
    
    
    
    
    React.useEffect(() => {
      const rangeLinePadding = 40;
      const calcStep =
        (ref.current.offsetWidth - rangeLinePadding) / ref.current.max;
      setStep(calcStep);
    }, []);
    
  
    return ( 

    <div style={{height:'60px'}}>
       <h4 className="mt-30 mb-30" style={{ textAlign: "left"}}>
    What would you like it to be?
    </h4>
    <div style={{display:'flex'}}>
      <div style={{display:'flex', flexDirection:'column', width:'10%'}}>
        <div style={{fontSize:'20px', textAlign:'left'}}>${range}</div>
        <div style={{fontSize:'15px', paddingLeft:'3px', textAlign:'left', fontFamily:"sans-serif"}}>TTV</div>
      </div>
      
        
        {/*<div className="slider" id="ttv_slider" style ={{width:"80%", border:'4px', paddingLeft:'20px'}}>
          <input style={{height:'30px', WebkitAppearance:'none',borderColor:'red'}}
            className='ssliderRange'
            type="range"
            
            min={min}
            max={min*3}
            value={range}
            onChange={getRange}
            ref={ref}
          />
          {/* 
          <label className="" htmlFor="range" style={{transform: `translateX(${range * step}px)`,}}>

            <svg style={{top:'10px', left:'0', alignItems:'center'}} xmlns="http://www.w3.org/2000/svg" width="50" height="10" viewBox="0 0 400 400">
              <rect width="100%" height='170' fill="#0D3E56" />
            </svg>
            
            <div style={{fontWeight:'normal',position:'absolute',color:'#7c9caa', top:' 66px', fontSize:'15px', right:'-6px', fontFamily:"sans-serif"}}>TTV</div>

          </label>
          */}
        

        <div style={{display:'flex', flexDirection:'column'}}>
          <div className={maxshow ? "show":"unshow"} style={{fontSize:'20px'}}>
            ${min*3}
          </div>
          
        </div>
        
        


      </div>
      <div>
        <div className={resultshow ? "text-large":"unshow"} style={{paddingTop:'20px', display:'flex'}}>
          Great! That is a {rangefun}x increase. 
        </div>
        <div  className={mtext ? "continue":"friction"} ></div>

        <Popup trigger={<button className="button button-primary mt-20 button-block w-button"> See how </button>} modal>
        
        {close => (
          <div>
            <div style={{height:'1000px', backgroundColor:'white', width:'40vw', left:'-30px', position:'absolute', top:'-50vh'}}> 
              
              <div style={{paddingLeft:'2.5vw', paddingRight:'2.5vw'}}>
                <h3 style={{textAlign:'center', paddingTop:'60px'}}>How it works</h3>
                <div>
                <h4>If the customer spends less than your Target Transaction Value:</h4>
                <h4>We charge 4.99%, that includes</h4>
                <ul>
                  <li>2.99% transaction fee, Credit/Debit</li>
                  <li>2% returns to the customer in Localight Cash</li>
                </ul>
                </div>
                <div>
                <h4>If the customer spends more than your Target Transaction Value:</h4>
                  <ul>
                    <li>2.99% transaction fee, Credit/Debit</li>
                    <li>6.99% funds the Localight Cash Back Rewards (3-6% back to the customer)</li>
                  </ul>
                </div>
                <div>
                  <h4>How does the Localight Cash Rewards program work?</h4>
                  <ul>
                    <li>The customer earns Localight Cash that can only be spent at Localight Businesses.</li>
                    <li>They don’t get discounts that make you lose money, they get cash to spend at your businesses.</li>
                  </ul>
                </div> 
                <button onClick={(close)} style = {{width:'35vw', backgroundColor:'white', justifyItems :'center'}}><button className="button button-primary mt-20 button-block w-button" onClick={handle_allow} >I understand!</button></button>
              </div>

            </div>

            </div>
        )}
      </Popup>
      
      </div>
      

      

      </div>

    );
  
}




  //label --> max value as 300 left side is the value changes with a sliderbar labbleed ttv

  //
  export {a};
  export {rangefun};
  export {b}
  export default RangeSlider;

 