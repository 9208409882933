import React, { useRef, Component , useEffect, PureComponent, useState } from "react"
import { WrapperLandbot, WrapperIframe, WrapperWhatsApp } from "@frubana/react-landbot";
import image from "../../assets/images/Localight-logo-alone square.png"
export default class Chatbot extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        count: false,
      };
    }
    render () {
      if (this.state.count){
        return (
        <div style={{position:'fixed', right:'2vw', zIndex:'1', bottom:'3vh'}}>
          <iframe style={{height:'50vh', borderRadius:'2vh', borderStyle:'solid', borderWidth:'.2vh', borderColor:'black'}} src="https://chats.landbot.io/v3/H-1214264-OFJNM45RFPLLFSLP/index.html" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"/>
          <button style = {{borderRadius:'100px', zIndex:'2', backgroundColor:'transparent', position:"absolute", right:'8%', top:'1%', width:'20px', height:'20px'}}onClick={() => this.setState({count: false})}>
            x
          </button>
        </div>  
        )
      
      }
      else{
        return(
          <div style={{position:'fixed', right:'2vw', zIndex:'1', bottom:'5vh'}}>
            <button style= {{height:'8vh', width:'8vh', backgroundImage:`url(${image})`, backgroundSize:'contain', borderRadius:'50vh', backgroundRepeat:'no-repeat', borderStyle:'solid', borderColor:'black', borderWidth:'.2vh'}}  onClick={() => this.setState({count: true})}>
              
            </button>
          </div>
        )
      }
        
     
    }
  }
