import React, { useEffect, useState } from "react"
// import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"

// import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Header from "../../../components/Client/Header"
import { GET_TRANSACTIONS } from "../../../store/payment/constants"
import moment from "moment"
import { Button } from "@mui/material"
import { DO_LOGOUT } from "../../../store/auth/constants"
import { request } from "../../../utils/http"

const TransactionsContainer = props => {
  const dispatch = useDispatch()
  const customer = useSelector(state => state.customerReducer.customer.data)
  const transactions = useSelector(state => state.paymentReducer.transactions)

  const [points, setPoints] = useState(0)
  const [pointsLedger, setPointsLedger] = useState([])

  useEffect(() => {
    getPoints()
    getPointsLedger()
  }, [])

  const getPoints = () => {
    request
      .get("/api/v1/get-customer-points")
      .then(res => {
        console.log(res)
        setPoints(res.data.total_points)
      })
      .catch(e => console.log(e.response.data))
  }

  const getPointsLedger = () => {
    request
      .get("api/v1/get-point-ledger")
      .then(res => {
        console.log(res)
        setPointsLedger(res.data.points_ledger)
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    dispatch({ type: GET_TRANSACTIONS })
  }, [dispatch])

  const navigateToHome = () => {
    dispatch(push(`/`))
  }

  const doLogout = () => {
    dispatch({ type: DO_LOGOUT })
    dispatch(push(`/`))
  }

  const navigateJoinIn = () => {
    dispatch(push(`/customers/signup`))
  }

  const navigateProfile = () => {
    dispatch(push(`/customers/transactions`))
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
        minHeight: "100vh",
        opacity: 1,
        background: "#edf3f4 0% 0% no-repeat padding-box"
      }}
    >
      <Header
        navigateToHome={navigateToHome}
        navigateJoinIn={navigateJoinIn}
        customer={customer}
        navigateProfile={navigateProfile}
      />
      <Box sx={{ display: "flex", p: 2, flexDirection: "column" }}>
        <Typography
          variant="h6"
          sx={{ color: "#152A38", fontSize: "22px", paddingBottom: 1 }}
        >
          {"Points Earned"}
        </Typography>
        <Typography variant="h6" sx={{ color: "#ff4824", fontSize: "18px" }}>
          {"You have " + points + " points earned"}
        </Typography>
        <Typography
          variant="p"
          sx={{ color: "#86a9af", fontSize: "14px", fontWeight: "bold" }}
        >
          They will be unlocked and ready to spend when you reach $25 worth of
          points (6,250).
        </Typography>
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 1
        }}
      >
        {pointsLedger.length > 0
          ? pointsLedger.map((item, i) => (
              <Box
                sx={{
                  paddingTop: 1,
                  borderBottom: 1,
                  borderColor: "#FFF"
                }}
                key={i}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#152A38",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {parseFloat(item?.fields.customer_points).toFixed(0)} points
                  earned on {moment(item?.fields.created).format("M/D/YYYY")}
                </Typography>
                <Typography
                  variant="p"
                  sx={{ color: "#959595", fontSize: "12px" }}
                >
                  Points expire on{" "}
                  {moment(item?.fields.created)
                    .add(18, "months")
                    .format("M/D/YYYY")}
                </Typography>
              </Box>
            ))
          : null}
      </Container>
      <Box sx={{ display: "flex", p: 2, flexDirection: "column" }}>
        <Typography
          variant="h6"
          sx={{ color: "#152A38", fontSize: "22px", paddingBottom: 1 }}
        >
          {"Transactions"}
        </Typography>
        <Typography variant="h6" sx={{ color: "#86a9af", fontSize: "18px" }}>
          {"Questions about a transaction?"}
        </Typography>
        <Typography
          variant="p"
          sx={{ color: "#86a9af", fontSize: "14px", fontWeight: "bold" }}
        >
          Don't hesitate to{" "}
          <a href="mailto:hello@localight.com" underline="true">
            contact us
          </a>
          , We are here to help.
        </Typography>
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 1
        }}
      >
        {transactions?.data && !!transactions?.data.length ? (
          transactions?.data?.map((item, i) => (
            <Box
              sx={{
                paddingTop: 1,
                borderBottom: 1,
                borderColor: "#FFF"
              }}
              key={i}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#152A38",
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                {item.business_name}
              </Typography>
              <Typography
                variant="p"
                sx={{ color: "#959595", fontSize: "12px" }}
              >
                {item?.business_location_name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "#152A38",
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                ${parseFloat(item?.amount).toFixed(2)} (
                {item?.customer_bank_name})
              </Typography>
              <Typography
                variant="p"
                sx={{ color: "#959595", fontSize: "12px" }}
              >
                {moment(item?.created_on).format("M/D/YYYY, h:mma")}
              </Typography>
            </Box>
          ))
        ) : (
          <Box sx={{ paddingTop: 1 }}>
            <Typography
              variant="h6"
              sx={{ color: "#152A38", fontSize: "14px", fontWeight: "bold" }}
            >
              No transactions yet.
            </Typography>
          </Box>
        )}

        <Button onClick={() => doLogout()}>LOGOUT </Button>
      </Container>
    </Container>
  )
}

export default TransactionsContainer
