import React, { useState, useEffect } from "react"
// import Link from '@mui/material/Link';
// import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux"
import Container from "@mui/material/Container"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { push } from "connected-react-router"
import { anchors } from "../../../utils/anchors"
import Header from "../../../components/Client/Header"
import HomeBox from "../../../components/Client/HomeBox"
import SliderTitle from "../../../components/Client/SliderTitle"
import OfferSlider from "../../../components/Client/OfferSlider"
import FeaturedOfferSlider from "../../../components/Client/OfferSlider/Featured"
import { useParams } from "react-router-dom"
import EateriesIcon from "../../../assets/images/EateriesIcon.svg"
import ShopsIcon from "../../../assets/images/ShopsIcon.svg"
import WellnessIcon from "../../../assets/images/WellnessIcon.svg"
import ServicesIcon from "../../../assets/images/ServicesIcon.svg"
import HospitalityIcon from "../../../assets/images/Hospitality-icon.svg"
import VendorIcon from "../../../assets/images/Vendor-Icon.svg"
import VenueIcon from "../../../assets/images/Venue-Icon.svg"
import MarketIcon from "../../../assets/images/Market-Icon.svg"
import ExperienceIcon from "../../../assets/images/Experience-Icon.svg"

import { CompanyApiServices } from "../../../store/company/services"

const HomeContainer = props => {
  const dispatch = useDispatch()
  let { anchor } = useParams()
  const customer = useSelector(state => state.customerReducer.customer.data)
  const customerType = useSelector(state =>
    state.authReducer && state.authReducer.user
      ? state.authReducer.user.type
      : null
  )

  const [business, setBusiness] = useState([])
  const [eatery, setEatery] = useState([])
  const [shops, setShops] = useState([])
  const [wellness, setWellness] = useState([])
  const [service, setService] = useState([])
  const [vendor, setVendor] = useState([])
  const [experience, setExperience] = useState([])
  const [hospitality, setHospitality] = useState([])
  const [market, setMarket] = useState([])
  const [venue, setVenue] = useState([])

  useEffect(() => {
    getBusiness()
    getEeateries()
    getShops()
    getWellness()
    getService()
    getVendor()
    getExperience()
    getHospitality()
    getMarket()
    getVenue()
  }, [])

  const getBusiness = async () => {
    const result = await CompanyApiServices.getAllCompany(anchor)
    if (result.length) {
      setBusiness(result)
    }
  }

  const getEeateries = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "eatery"
    )
    if (result.length) {
      setEatery(result)
    }
  }

  const getShops = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(anchor, "shop")
    if (result.length) {
      setShops(result)
    }
  }

  const getWellness = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "wellness"
    )
    if (result.length) {
      setWellness(result)
    }
  }

  const getService = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "service"
    )
    if (result.length) {
      setService(result)
    }
  }

  const getVendor = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "vendor"
    )
    if (result.length) {
      setVendor(result)
    }
  }

  const getExperience = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "experience"
    )
    if (result.length) {
      setExperience(result)
    }
  }
  const getHospitality = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "hospitality"
    )
    if (result.length) {
      setHospitality(result)
    }
  }

  const getMarket = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "market"
    )
    if (result.length) {
      setMarket(result)
    }
  }

  const getVenue = async () => {
    const result = await CompanyApiServices.getCompanybyCategory(
      anchor,
      "venue"
    )
    if (result.length) {
      setVenue(result)
    }
  }

  const navigateBusiness = (slug, id, name) => {
    dispatch(push(`/business/${slug}/`))
  }

  const navigateToHome = () => {
    dispatch(push(`/`))
  }

  const navigateJoinIn = () => {
    dispatch(push(`/customers/signup`))
  }

  const navigateToDashbaord = () => {
    dispatch(push(`/dashboard`))
  }

  const navigateProfile = () => {
    dispatch(push(`/customers/transactions`))
  }

  return (
    <>
      <Header
        navigateToHome={navigateToHome}
        navigateJoinIn={navigateJoinIn}
        customer={customer}
        customerType={customerType}
        navigateProfile={navigateProfile}
        navigateToDashbaord={navigateToDashbaord}
      />
      <HomeBox>
        <Container
          sx={{
            py: 1,
            px: 0
          }}
        >
          <SliderTitle
            title="Recommended"
            icon={null}
            subtitle={anchors[anchor]["Near Anchor Region Text"]}
            color={"#62898f"}
          />
          {!!business.length ? (
            <FeaturedOfferSlider
              offers={business}
              navigateBusiness={navigateBusiness}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Container>

        {eatery.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Eateries" icon={EateriesIcon} />
            <OfferSlider data={eatery} navigateBusiness={navigateBusiness} />
          </Container>
        )}
        {shops.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Shops" icon={ShopsIcon} />
            <OfferSlider data={shops} navigateBusiness={navigateBusiness} />
          </Container>
        )}

        {wellness.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Wellness" icon={WellnessIcon} />
            <OfferSlider data={wellness} navigateBusiness={navigateBusiness} />
          </Container>
        )}
        {service.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Services" icon={ServicesIcon} />
            <OfferSlider data={service} navigateBusiness={navigateBusiness} />
          </Container>
        )}
        {vendor.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Vendors" icon={VendorIcon} />
            <OfferSlider data={vendor} navigateBusiness={navigateBusiness} />
          </Container>
        )}
        {experience.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Experience" icon={ExperienceIcon} />
            <OfferSlider
              data={experience}
              navigateBusiness={navigateBusiness}
            />
          </Container>
        )}
        {hospitality.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Hospitality" icon={HospitalityIcon} />
            <OfferSlider
              data={hospitality}
              navigateBusiness={navigateBusiness}
            />
          </Container>
        )}
        {market.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Market" icon={MarketIcon} />
            <OfferSlider data={market} navigateBusiness={navigateBusiness} />
          </Container>
        )}
        {venue.length === 0 ? null : (
          <Container
            sx={{
              py: 1
            }}
          >
            <SliderTitle title="Venue" icon={VenueIcon} />
            <OfferSlider data={venue} navigateBusiness={navigateBusiness} />
          </Container>
        )}
      </HomeBox>
    </>
  )
}

export default HomeContainer
