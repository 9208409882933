import React, { useEffect, useState } from 'react'
import Link from '@mui/material/Link'
import {
  REMOVE_BANK_INFO,
  GET_PLAID_LINK_TOKEN,
  GET_BUSINESS_BANK,
  GET_COMPANY_TRANSACTIONS,
  GET_TRANSACTIONS
} from "../../store/payment/constants"
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from "react-redux"
import { request } from "../../utils/http"
import PlaidButton from '../../components/PlaidButton'
import Chatbot from "../../components/Chatbot/index"
import moment from 'moment'
import { push } from "connected-react-router"

const PaymentsContainer = (props) => {

  const dispatch = useDispatch();
  const banks = useSelector(state => state.paymentReducer.banks.data);
  const companies = useSelector(state => state.companyReducer.company?.data);
  const plaidLink = useSelector(state => state.paymentReducer.plaidLink.data);
  const businessTransactions = useSelector(state => state.paymentReducer.businessTransactions.data);
  const [companyID, setCompanyID] = useState(null)
  const [transactionsList, setTransactionsList] = useState(null)
  const [localCashRequested, setLocalCashRequested] = useState(null)
  const [localCashRequestedCompleted, setLocalCashRequestedCompleted] = useState(null)
  const [listLCCompleted, setListLCCompleted] = useState(null)

  useEffect(() => {
    if (!plaidLink) dispatch({ type: GET_PLAID_LINK_TOKEN });
  }, [dispatch, plaidLink]);

  useEffect(() => {
    companyID && dispatch({ type: GET_COMPANY_TRANSACTIONS, id: companyID });
  }, [companyID, dispatch])

  useEffect(() => {
    getTransactions()
    getLocalCashRequested()
    getLocalCashRequestedCompleted()
  }, [])

  const removeBankInfo = () => {
    dispatch({ type: REMOVE_BANK_INFO });
  }

  useEffect(() => {
    if (companies && companies[0]?.id) {
      setCompanyID(companies[0]?.id);
      dispatch({ type: GET_BUSINESS_BANK, id: companies[0]?.id });
    }
  }, [companies, dispatch]);

  const getBusinessBanks = () => {
    dispatch({ type: GET_BUSINESS_BANK, id: companyID });
  }

  const deposits = businessTransactions && businessTransactions[0];

  function transferToBank() {
    console.log('current company is: ', companies[0].name)
    if (deposits?.pending?.total_amount || localCashRequested > 0) {
      let pending_amount = parseFloat(0).toFixed(2)

      // This is the in-app version
      if (deposits?.pending?.total_amount) {
        pending_amount = parseFloat(deposits?.pending?.total_amount).toFixed(2)
        dispatch({ type: GET_TRANSACTIONS })
        console.log('getting transactions before Transfer to Bank')
        dispatch(push({
          pathname: "/dashboard/transfer-to-bank",
          amount: pending_amount
        }))
      }


      // This is the Jotform version
      // Add a "processing LocalCash transfer" value in case a customer click on the button but doesn't submit the form
      //  window.open('https://secure.jotform.com/232477193612155?merchantEmail='+ encodeURIComponent(companies[0].name)
      //   + '&current-balance=' + encodeURIComponent('$' + pending_amount 
      //     + ' (processing: $' + localCashRequested + ')'), '_blank')
      // // Change status of all transactions that are "paid" to "LocalCash transfer requested"
      // request
      //   .get(`/api/v1/transactions/update_localcash_requested`)
      //   .then(function (response) {
      //     console.log('Success while updating transactions status to requesting localcash', response.data)
      //     getLocalCashRequested()
      //     dispatch({ type: GET_COMPANY_TRANSACTIONS, id:  companyID})
      //   })
      //   .catch(function (e) {
      //     console.log('Error while updating transactions status to requesting localcash', e)
      //   })


    } else {
      console.log('Current balance and LocalCash requested are null')
    }
  }

  function getLocalCashRequested() {
    request
      .get(`/api/v1/transactions/get_localcash_requested`)
      .then(function (response) {
        console.log('Success while getting transactions with status = requesting localcash', response.data)
        var localCashRequestedLocal = 0.0
        for (const element of response.data) {
          console.log('element amount: ', parseFloat(element.amount))
          localCashRequestedLocal += parseFloat(element.amount)
          console.log('localcash requested: ', localCashRequestedLocal)
        }
        setLocalCashRequested(localCashRequestedLocal.toFixed(2))
      })
      .catch(function (e) {
        console.log('Error while getting transactions with status = requesting localcash', e)
      })
  }

  function getLocalCashRequestedCompleted() {
    request
      .get(`/api/v1/transactions/get-completed-localcash-requested`)
      .then(function (response) {
        console.log('Success while getting transactions with status = requesting localcash completed', response.data)
        setListLCCompleted(response.data)
        var localCashRequestedCompletedLocal = 0.0
        for (const element of response.data) {
          console.log('element amount: ', parseFloat(element.amount))
          localCashRequestedCompletedLocal += parseFloat(element.amount)
          console.log('localcash requested: ', localCashRequestedCompletedLocal)
        }
        setLocalCashRequestedCompleted(localCashRequestedCompletedLocal.toFixed(2))
      })
      .catch(function (e) {
        console.log('Error while getting transactions with status = requesting localcash completed', e)
      })
  }

  function getTransactions() {
    request
      .get(`/api/v1/transactions`)
      .then(function (response) {
        console.log("Got transactions list", response?.data)
        setTransactionsList(response?.data)
      })
      .catch(function (e) {
        console.log('Error for transactions list', e)
      })
  }

  return <>
    <div className="app-main-layout">
      <Chatbot />
      <div className="app-main-layout-header">
        <div className="app-main-layout-wrapper header">
          <div className="page-heading">
            <h2 className="heading-3" style={{ color: 'black' }}>Payments</h2>
          </div>
          <div className="page-tabs-menu">
            <div aria-current="page" className="page-tab-link payment-details w--current">Balance & Transfers</div>
            {/* <div className="page-tab-link subscription">Subscription</div> */}
          </div>
        </div>
      </div>
      <div className="app-main-layout-wrapper">
        <div className="app-main-layout-content">
          <div className="section">
            <div className="row w-row" style={{ display: 'flex' }}>
              <div className="col w-col w-col-6">
                <div className="card">
                  {/* This "adding bank" section will return with version 2.1 */}
                  {/* <div>{"Bank Informations"}</div> */}
                  <h3 style={{ marginTop: '0' }}>{"Current Balance"}</h3>
                  <div style={{ fontSize: '40px', color: 'black' }}>{deposits?.pending?.total_amount ? '$' + parseFloat(deposits?.pending?.total_amount).toFixed(2) : '$0.00'}</div>
                  <div style={{ fontSize: '20px', color: 'black' }}>
                    LocalCash received
                  </div>
                  <br></br>
                  <button onClick={transferToBank} style={{ display: 'flex', justifyContent: 'space-between', fontSize: '30px', fontWeight: 'bold', color: 'black' }}>
                    Transfer to Bank
                  </button>
                  <br></br>
                  <div style={{ fontSize: '15px', color: 'black' }}>
                    LocalCash being transferred: ${localCashRequested}
                  </div>
                  <br></br>
                  <div style={{ fontSize: '15px', color: 'black' }}>  Requires business verification </div>
                  <div style={{ fontSize: '15px', color: 'black' }}>
                    Need assistance? &nbsp; <a href="mailto:hello@localight.com" underline="true">
                      Email us
                    </a>
                    &nbsp; or call (949) 202-6850
                  </div>

                  {/* We removed the display of banks at the moment, will come back in version 2.1 */}
                  {/* {!banks?.length ? 
                          // This "adding bank" section will return with version 2.1
                          // plaidLink && <PlaidButton getBusinessBanks={getBusinessBanks} plaid={plaid} plaidLink={plaidLink} companyID={companyID} />
                        null
                          :
                        <>
                        {banks?.map((item) => (
                        <div className="current-method-details mt-20" key={item.company}>
                          <div className="hint">{item.name}</div>
                          <div className="hint">{item.dwolla_bank_id}</div>
                        </div>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <div onClick={removeBankInfo} className="button w-button button-primary  mt-20">Disconnect</div>
                        </Box>
                        </>
                      }   */}
                </div>
              </div>
              <div className="col w-col w-col-6">
                <div className="card">

                  <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                    Recent Transfer History
                  </div>
                  <div className="current-method-details">
                    <br></br>
                    {console.log('the transactionsList is: ', transactionsList)}
                    {listLCCompleted?.length > 0 && listLCCompleted?.map((item) => (
                      // Will have to use the exact same business name in staging & production environment
                      // if(item.business_name!='unlock-localcash-company'){
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* {console.log('Component of the transactionsList: ', item)} */}
                        <div style={{ color: 'black' }}>{item?.created_on ? moment(item?.created_on).format('LL') : ''}</div>
                        <div style={{ color: 'black' }}>{item?.amount ? '$' + parseFloat(item?.amount).toFixed(2) : ''}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="w-row">
            <div className="w-col w-col-6">
              <div className="hint">© Copyright Localight Inc. All rights reserved.</div>
            </div>
            <div className="footer-right w-col w-col-6">
              <div className="hint">
                <Link underline="none" href="#" className="footer-link">Spotlight Business</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default PaymentsContainer;
