import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
import { AuthApiServices } from "../../store/auth/services";
import { push } from 'connected-react-router';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation} from 'react-router-dom';

const PasswordResetConfirmContainer = (props) => {

    const [tokenValid, setTokenValid] = useState(null);
    const queryParams = new URLSearchParams(useLocation().search);
    const token = queryParams.get("token");

    const dispatch = useDispatch();
    const { handleSubmit, register, watch, formState: { errors }  } = useForm();
    let pwd = watch("password");

    const submitResetPassword = async (data) => {
        const result = await AuthApiServices.doPwdResetConfirm({ 
            token: token,
            password: data.password });
  
        console.log(result);
  
        if(result?.status === "OK") dispatch(push('/'));
    }

    const validateToken = async (token) => {
        const result = await AuthApiServices.doPwdResetValidate({ 
            token: token});

        return result;
    }

    useEffect(() => {
        if(token && !tokenValid){
            validateToken(token).then((result) => {
                console.log(result, token);
                setTokenValid('valid');
              })
              .catch((error) => {
                console.log(error, token);
                setTokenValid('invalid');
              });
        }
      }, [token, tokenValid]);


    return <>
        <div className="single-layout-right">
          <div className="single-layout-right-content">
            <div className="single-layout-logo-right">
              <Link href="/" underline="none" className="w-inline-block"><img src="/static/Localight-Logo-reverse.svg" width="150" alt=""/></Link>
            </div>
            <h3 className="text-center">Password Reset</h3>
            <div className="w-form">
            {token && (tokenValid === 'valid') ?
                <form id="passwordreset-form" name="passwordreset-form" onSubmit={handleSubmit(submitResetPassword)}>
                    <label htmlFor="password" className="label"><strong>Create a password</strong></label>
                    <input 
                      type="password" 
                      aria-invalid={errors.password ? "true" : "false"}
                      {...register("password", {
                        required: "Password field is required",
                        minLength: {
                          value: 8,
                          message: "min length is 5"
                        }
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="password"  
                      placeholder="Enter your new password" 
                      id="password"
                      />
                      <input 
                      type="password" 
                      aria-invalid={errors.verify_password ? "true" : "false"}
                      {...register("verify_password", {
                        required: "Password confirm field is required.",
                        minLength: {
                          value: 8,
                          message: "min length is 5"
                        },
                        validate: value => value === pwd || "The passwords do not match"
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="verify_password"  
                      placeholder="Verify your new password" 
                      id="verify_password"
                      />
                      {(errors.password || errors.verify_password) &&
                      <div className="error-message">
                        <div>{errors.password?.message}</div>
                        <div>{errors.verify_password?.message}</div>
                      </div>
                    }
                    <button type="submit" className="button button-primary button-block w-button">Reset password</button>
                </form>
                : 
                <>
                  {token && (tokenValid === 'invalid') &&
                  <div className="error-message text-center">
                    <div>Invalid token! Please try again.</div>
                  </div>
                }
                </>
                }
              
            </div>
          </div>
        </div>
    </>
};

export default PasswordResetConfirmContainer;
