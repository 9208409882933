import React, { useState, useRef, useEffect } from "react"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { alpha, styled } from "@mui/material/styles"
import { Link } from "react-router-dom"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Box from "@mui/material/Box"
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector"
import InputBase from "@mui/material/InputBase"
import FormControl from "@mui/material/FormControl"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import InputAdornment from "@mui/material/InputAdornment"
import PlaidButton from "../PlaidButton"
import Slide from "@mui/material/Slide"
import Checkbox from "@mui/material/Checkbox"
import PaymentModal from "../Payment"
import { formatPhoneNumber } from "react-phone-number-input"
import MoneyInput from "@rschpdr/react-money-input"

import { AuthApiServices } from "../../../store/auth/services"

import { useForm } from "react-hook-form"

import { ThemeProvider, createTheme } from "@mui/material/styles"

import LockIcon from "@mui/icons-material/Lock"

import scanToPay from "../../../assets/images/scanToPay.png"
import PayWithPhone from "../../../assets/images/PayWithPhone.png"
import AllSet from "../../../assets/images/AllSet.png"

import LocalightLogoIcon from "../../../assets/images/LocalightLogoIcon.svg"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import BusinessAvatar from "../../../assets/images/BusinessAvatar.png"

import successAnimationData from "../../../assets/animations/successAmination.json"

import LottieAnimation from "../Animation"

import Select from "react-select"

import { getPathFromUrl } from "../../../utils/getURL"

import { LocalightLogoIconDark, PayIcon } from "../Icons"
import { request } from "../../../utils/http"

const buttonTheme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "14px",
          color: "#FFFFFF",
          backgroundColor: "#45CCC5"
        },
        endIcon: {
          color: "#FFFFF"
        }
      }
    }
  }
})

const payButtonTheme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "25px",
          marginTop: "25px!important",
          justifySelf: "center",
          color: "#56FFF6",
          borderColor: "#FFFFFF",
          backgroundColor: "transparent"
        },
        endIcon: {
          color: "#56FFF6"
        },
        startIcon: {
          color: "#56FFF6"
        }
      }
    }
  }
})

const StyledStepLabel = styled(StepLabel)({
  "& .Mui-active": {
    color: "#FF4824 !important",
    fontSize: "12px"
  },
  "& .Mui-completed": {
    color: "#1696AA !important",
    fontSize: "12px"
  },
  "& .MuiStepLabel-label": {
    color: "#ADADAD",
    fontSize: "12px"
  }
})

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "&.inputAdornedEnd": {
    background: "#45CCC5",
    borderRradius: "20px",
    opacity: 1,
    borderColor: "#45CCC5",
    color: "##FFFFFF",
    backgroundColor: "#45CCC5"
  },
  "& .MuiInputBase-input": {
    borderRadius: 25,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#152A38" : "#2b2b2b",
    color: "#A0A0A0",
    border: "1px solid #FFFFFF",
    fontSize: 22,
    width: "auto",
    minWidth: "315px",
    padding: "5px 12px",
    letterSpacing: "-0.56px",
    marginTop: "8px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#2b2b2b"
    }
  }
}))

const AmountInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "&.inputAdornedEnd": {
    background: "#45CCC5",
    borderRradius: "20px",
    opacity: 1,
    borderColor: "#45CCC5",
    color: "#56FFF6",
    backgroundColor: "#45CCC5"
  },
  "& .MuiInputBase-input": {
    // borderRadius: 25,
    position: "relative",
    backgroundColor: "transparent",
    color: "#56FFF6",
    borderBottom: "3px dashed #56FFF6",
    fontSize: 42,
    width: "150px",
    minWidth: "100px",
    padding: "10px 0px",
    letterSpacing: "-0.56px",
    marginTop: "8px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
      backgroundColor:
        theme.palette.mode === "light" ? "transparent" : "#2b2b2b"
    }
  }
}))

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(75,164,178) 0%,rgb(41,117,129) 50%,rgb(22,150,170) 100%)"
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(75,164,178) 0%, rgb(41,117,129) 50%, rgb(22,150,170) 100%)"
  })
}))

const ColorlibStepIcon = props => {
  const { active, completed, className } = props

  const icons = {
    1: <AccountCircleIcon />,
    2: <LocationOnIcon />,
    3: <FavoriteBorderIcon />
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

const CustomerJoin = ({
  plaid,
  business,
  lastTransaction,
  onSendMoney,
  customerID,
  customer,
  onBusinessChange,
  businesses,
  doCustomerLogin,
  plaidLink,
  customerBanks,
  navigateProfile,
  customerType
}) => {
  const steps = ["REGISTER", "SHOP LOCAL", "EARN REWARDS"]

  const [step, setStep] = useState(0)
  const [nextOffer, setNextOffer] = useState(0)
  const [currentTier, setCurrentTier] = useState(0)

  const [otp, setOtp] = useState("")
  const [verified, setVerified] = useState(false)
  const [showedVerified, setShowedVerified] = useState(true)
  const [showedAllSet, setShowedAllSet] = useState(true)
  const rootContainerRef = useRef(null)
  const [amount, setAmount] = useState(0.0)
  const [showPaySubmit, setShowPaySubmit] = useState(false)

  const [showCompanySelect, setShowCompanySelect] = useState(false)

  console.log(plaidLink)

  const options = !!businesses?.length
    ? businesses.map(item => {
        return { value: item.id, label: item.name }
      })
    : []

  const [checked, setChecked] = useState(false)

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      first_name: "",
      zip_code: "",
      mobile_number: "",
      last_name: "",
      email: ""
    }
  })

  const getNextOffer = () => {
    request
      .get("/api/v1/get-offer/" + business.id)
      .then(res => {
        console.log(res)
        setNextOffer(res.data.amount)
        setCurrentTier(res.data.tier)
      })
      .catch(e => {
        setNextOffer(business.average_transaction_value)
        setCurrentTier(0)
      })
  }

  useEffect(() => {
    if (business && business.id) {
      getNextOffer()
    }
  }, [business])

  let phoneNo = watch("mobile_number")

  const setCustomerBusiness = selected => {
    onBusinessChange(selected.value)
    setShowCompanySelect(false)
  }

  const checkUserMobile = async data => {
    console.log(data)
    const formattedMobileData = {
      first_name: data.first_name,
      zip_code: data.zip_code,
      mobile_number: "1" + data.mobile_number,
      last_name: data.last_name,
      email: data.email
    }
    try {
      const signupResult = await AuthApiServices.doCustomerRegister({
        ...formattedMobileData
      })
      if (signupResult.mobile_number) {
        console.log("signed up!")
      }
    } catch (error) {
      console.log(error)
    }

    try {
      const OtpResult = await AuthApiServices.doSentOtp({
        mobile_number: "1" + phoneNo
      })
      if (OtpResult) {
        console.log(OtpResult)
        setVerified(true)
      }
    } catch (error) {
      console.log(error)
    }
    // setStep(1)
    // console.log("step chaged")
  }

  useEffect(() => {
    if (step === 0 && plaidLink?.link_token) {
      setStep(1)
      setShowedAllSet(false)
    }
  }, [plaidLink, step])

  useEffect(() => {
    if (step === 1 && !!customerBanks?.length) {
      setStep(2)
    }
  }, [customerBanks, step])

  useEffect(() => {
    if (step === 1 && showedVerified) {
      const timer = setTimeout(() => {
        setShowedVerified(false)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [showedVerified, step])

  useEffect(() => {
    if (step === 2 && showedAllSet) {
      const timer = setTimeout(() => {
        setShowedAllSet(false)
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [showedAllSet, step])

  const sendOtp = async () => {
    await doCustomerLogin(otp, "1" + phoneNo)
  }

  const linkBankAccounts = () => {
    setStep(2)
  }

  const submitAmount = () => {
    if (amount) {
      setShowPaySubmit(true)
    }
  }

  const closePayment = () => {
    setShowPaySubmit(false)
  }

  // console.log(phoneNo, otp);

  return (
    <>
      <Container maxWidth="sm" disableGutters ref={rootContainerRef}>
        <Box
          sx={{
            minWidth: 240,
            p: 2,
            justifyContent: "center",
            display: "flex"
          }}
          // onClick={handleDrawerOpen}
        >
          <img src={LocalightLogoIcon} alt="client-logo" width={90} />
        </Box>

        <Stack sx={{ width: "100%", my: 2 }} spacing={4}>
          {customerType === "" ? (
            <Stepper
              alternativeLabel
              activeStep={step}
              connector={<ColorlibConnector />}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StyledStepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StyledStepLabel>
                </Step>
              ))}
            </Stepper>
          ) : null}
        </Stack>
        {step === 0 && (
          <Stack
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: 4, p: 4 }}
          >
            <Typography
              sx={{
                textAlign: "left",
                color: "#FFFFFF",
                opacity: 1,
                letterSpacing: "-0.4px",
                lineHeight: 1.3,
                fontSize: "20px",
                fontWeight: "300"
              }}
            >
              Get ready to rack up points that can be spent at Localight
              businesses.
            </Typography>
            {verified ? (
              <FormControl variant="standard">
                <BootstrapInput
                  type="number"
                  placeholder="Code"
                  autoComplete="one-time-code"
                  id="otp-number"
                  required
                  autoFocus
                  onChange={e => setOtp(e.target.value)}
                  endAdornment={
                    <InputAdornment
                      variant="outlined"
                      sx={{
                        position: "absolute",
                        right: 0,
                        marginTop: "8px"
                      }}
                      position="end"
                    >
                      <ThemeProvider theme={buttonTheme}>
                        <Button
                          variant="outlined"
                          size="small"
                          type="submit"
                          onClick={sendOtp}
                          sx={{
                            m: 1,
                            borderRadius: 20,
                            borderColor: "#45CCC5",
                            color: "##FFFFFF",
                            backgroundColor: "#45CCC5"
                          }}
                          endIcon={<ArrowForwardIosIcon color="#FFFFFF" />}
                        >
                          Confirm
                        </Button>
                      </ThemeProvider>
                    </InputAdornment>
                  }
                />
              </FormControl>
            ) : (
              <form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
                id="join-form"
                name="join-form"
                onSubmit={handleSubmit(checkUserMobile)}
              >
                <FormControl variant="standard">
                  <BootstrapInput
                    placeholder="First name"
                    id="first-name"
                    {...register("first_name", {
                      required: "First name is required."
                    })}
                    autoFocus
                    required
                  />
                </FormControl>
                <FormControl variant="standard">
                  <BootstrapInput
                    placeholder="Last Name"
                    id="last-name"
                    {...register("last_name", {
                      required: "Last name is required."
                    })}
                    required
                  />
                </FormControl>
                <FormControl variant="standard">
                  <BootstrapInput
                    placeholder="Email"
                    id="email"
                    {...register("email", {
                      required: "Email is required."
                    })}
                    required
                  />
                </FormControl>
                <FormControl variant="standard">
                  <BootstrapInput
                    placeholder="Zip Code"
                    id="zip-code"
                    required
                    {...register("zip_code", {
                      required: "Zip Code is required."
                    })}
                  />
                </FormControl>
                {!verified && (
                  <Stack
                    spacing={1}
                    sx={{
                      m: 1
                    }}
                    style={{ width: "320px" }}
                    direction="row"
                  >
                    <Checkbox
                      checked={checked}
                      onChange={e => setChecked(e.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        color: "#ffffff"
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#FFFFFF",
                        opacity: 1,
                        letterSpacing: "-0.4px",
                        lineHeight: 1.3,
                        fontSize: "15px",
                        fontWeight: "300"
                      }}
                    >
                      By entering and tapping Verify, you agree to the{" "}
                      <a
                        href="https://join.localight.com/policies"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#ffffff" }}
                      >
                        <u>Terms of Service</u>
                      </a>
                      , &{" "}
                      <a
                        href="https://join.localight.com/policies#privacy"
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: "#ffffff" }}
                      >
                        <u>Privacy Policy</u>
                      </a>
                      . , as well as Dwolla's{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.dwolla.com/legal/tos/"
                        style={{ color: "#ffffff" }}
                      >
                        <u>Terms of Service</u>
                      </a>{" "}
                      and{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.dwolla.com/legal/privacy/"
                        style={{ color: "#ffffff" }}
                      >
                        <u>Privacy Policy</u>
                      </a>
                    </Typography>
                  </Stack>
                )}
                <FormControl variant="standard">
                  <BootstrapInput
                    type="tel"
                    autoComplete="tel"
                    placeholder="Mobile number"
                    id="mobile-number"
                    {...register("mobile_number", {
                      required: "Mobile number is required."
                    })}
                    inputProps={{
                      maxLength: 10
                    }}
                    required
                    endAdornment={
                      <InputAdornment
                        variant="outlined"
                        sx={{
                          position: "absolute",
                          right: 0,
                          marginTop: "8px"
                        }}
                        position="end"
                      >
                        <ThemeProvider theme={buttonTheme}>
                          <Button
                            variant="outlined"
                            size="small"
                            type="submit"
                            disabled={!checked}
                            // onClick={onCheckMobileSubmit}
                            sx={{
                              m: 1,
                              borderRadius: 20,
                              borderColor: "#45CCC5",
                              color: "##FFFFFF",
                              backgroundColor: "#45CCC5"
                            }}
                            endIcon={<ArrowForwardIosIcon color="#FFFFFF" />}
                          >
                            Verify
                          </Button>
                        </ThemeProvider>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </form>
            )}

            <Box
              sx={{
                backgroundImage: `url(${scanToPay})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: 200,
                height: 200,
                width: "100%",
                borderRadius: 5,
                my: 1
              }}
            />

            <Typography
              sx={{
                textAlign: "left",
                color: "#FFFFFF",
                opacity: 1,
                letterSpacing: "-0.4px",
                lineHeight: 1.3,
                fontSize: "21px",
                fontWeight: "300"
              }}
            >
              Shop Local. Earn Rewards. Repeat.
            </Typography>
          </Stack>
        )}

        {step === 1 && showedVerified && (
          <Slide
            direction="up"
            in={showedVerified}
            container={rootContainerRef.current}
          >
            <Box
              sx={{
                minWidth: 240,
                p: 2,
                my: 10,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
              }}
              // onClick={handleDrawerOpen}
            >
              <Avatar
                alt="lock Arrow"
                sx={{
                  bgcolor: "transparent",
                  border: "1px solid",
                  borderColor: "#ffffff",
                  width: 90,
                  height: 90,
                  my: 1
                }}
              >
                {" "}
                <LockIcon sx={{ fontSize: 48 }} />{" "}
              </Avatar>

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FFFFFF",
                  opacity: 1,
                  letterSpacing: "-0.4px",
                  lineHeight: 1.3,
                  fontSize: "24px",
                  fontWeight: "300"
                }}
              >
                Verified Secure
              </Typography>
            </Box>
          </Slide>
        )}

        {step === 1 && !showedVerified && (
          <Slide
            direction="up"
            in={!showedVerified}
            container={rootContainerRef.current}
          >
            <Stack
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: 4, p: 4 }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FFFFFF",
                  opacity: 1,
                  letterSpacing: "-0.4px",
                  lineHeight: 1.3,
                  fontSize: "20px",
                  fontWeight: "300"
                }}
              >
                Pay with Localight to earn points and unlock offers.
              </Typography>

              {plaidLink?.link_token && (
                <PlaidButton
                  plaid={plaid}
                  customerID={customerID}
                  userPlaidLink={plaidLink}
                  linkBankAccounts={linkBankAccounts}
                />
              )}

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FFFFFF",
                  opacity: 1,
                  letterSpacing: "-0.28px",
                  lineHeight: 1.3,
                  fontSize: "14px",
                  fontWeight: "300"
                }}
              >
                No fee to make purchases with bank funds. Localight does not
                view or keep bank login information. We use <b>Plaid</b> to
                verify your bank account, and check to make sure you have enough
                funds to cover transactions. You can disconnect your bank
                account at any time.
              </Typography>

              <Box
                sx={{
                  backgroundImage: `url(${PayWithPhone})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: 200,
                  height: 200,
                  width: "100%",
                  borderRadius: 5,
                  my: 1
                }}
              />

              <Typography
                sx={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  opacity: 1,
                  letterSpacing: "-0.4px",
                  lineHeight: 1.3,
                  fontSize: "21px",
                  fontWeight: "300"
                }}
              >
                Pay with your phone. Earn points!
              </Typography>
            </Stack>
          </Slide>
        )}

        {step === 2 && (
          <>
            {showedAllSet ? (
              <Slide
                direction="up"
                in={showedAllSet}
                container={rootContainerRef.current}
              >
                <Box
                  sx={{
                    minWidth: 240,
                    p: 2,
                    my: 10,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  <Avatar
                    alt="lock Arrow"
                    sx={{
                      bgcolor: "transparent",
                      border: "1px solid",
                      borderColor: "#ffffff",
                      width: 175,
                      height: 175,
                      my: 1,
                      marginBottom: "-180px"
                    }}
                  >
                    <img src={AllSet} alt="client-logo" width={130} />
                  </Avatar>
                  <LottieAnimation animationData={successAnimationData} />
                </Box>
              </Slide>
            ) : (
              <Slide
                direction="up"
                in={!showedAllSet}
                container={rootContainerRef.current}
              >
                <Stack
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginTop: 2, p: 4 }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      opacity: 1,
                      letterSpacing: "-0.4px",
                      lineHeight: 1.3,
                      fontSize: "24px",
                      fontWeight: "300"
                    }}
                  >
                    With this purchase you'll earn{" "}
                    {currentTier === 0 && nextOffer < amount
                      ? "2%"
                      : currentTier === 1 && nextOffer < amount
                      ? "3%"
                      : currentTier === 2 && nextOffer < amount
                      ? "4%"
                      : currentTier >= 3 && nextOffer < amount
                      ? "5%"
                      : "2%"}{" "}
                    back to spend at Localight businesses
                  </Typography>

                  <Avatar
                    alt={business?.name || "Business profile picture"}
                    src={getPathFromUrl(business?.image) || BusinessAvatar}
                    sx={{
                      width: 110,
                      height: 110,
                      margin: 1,
                      border: "3px dotted #B1D5DD"
                    }}
                  />

                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      opacity: 1,
                      letterSpacing: "-0.4px",
                      lineHeight: 1.3,
                      fontSize: "20px",
                      fontWeight: "bold"
                    }}
                  >
                    {business?.name || ""}
                  </Typography>

                  {/* {!showCompanySelect ? (
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#FFFFFF80",
                        opacity: 1,
                        letterSpacing: "-0.28px",
                        lineHeight: 1,
                        fontSize: "14px",
                        fontWeight: "normal"
                      }}
                      onClick={() => setShowCompanySelect(true)}
                    >
                      Change
                    </Typography>
                  ) : (
                    <Select
                      options={options}
                      onChange={value => setCustomerBusiness(value)}
                    />
                  )} */}

                  <Stack
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <MoneyInput
                      customInput={AmountInput}
                      id="amount"
                      style={{
                        caretColor: "#007aff",
                        float: "right"
                      }}
                      onChange={e =>
                        setAmount(parseFloat(e.target.value).toFixed(2))
                      }
                      value={amount}
                    />
                    <ThemeProvider theme={payButtonTheme}>
                      <Button
                        variant="outlined"
                        size="small"
                        type="submit"
                        disabled={amount === 0 || isNaN(amount)}
                        onClick={submitAmount}
                        sx={{
                          m: 1,
                          borderRadius: 20,
                          borderColor:
                            amount === 0 || isNaN(amount)
                              ? "#FFFFFF"
                              : "#ababab",
                          color: "#56FFF6",
                          backgroundColor: "transparent"
                        }}
                        startIcon={<PayIcon color="#56FFF6" />}
                      >
                        Pay
                      </Button>
                    </ThemeProvider>
                  </Stack>

                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#FFFFFF",
                        opacity: 1,
                        letterSpacing: "-0.4px",
                        lineHeight: 1.3,
                        fontSize: "15px",
                        fontWeight: "300"
                      }}
                    >
                      Have the staff ring up your purchase as usual, then enter
                      the total above and tap PAY.
                    </Typography>
                  </Stack>
                  <div
                    style={{
                      backgroundColor: "#FFF",
                      padding: 10,
                      borderRadius: 10,
                      marginTop: 30,
                      textAlign: "center"
                    }}
                  >
                    <>
                      <LocalightLogoIconDark />
                      <span
                        style={{
                          marginLeft: 5,
                          color: "#ff4824",
                          verticalAlign: "super"
                        }}
                      >
                        {!isNaN(amount) && parseFloat(amount) === 0 ? (
                          <b>
                            Spend $
                            {Math.ceil(
                              isNaN(amount)
                                ? parseFloat(nextOffer)
                                : parseFloat(nextOffer) - parseFloat(amount)
                            )}{" "}
                            to get{" "}
                            {currentTier === 0
                              ? "2%"
                              : currentTier === 1
                              ? "3%"
                              : currentTier === 2
                              ? "4%"
                              : currentTier >= 3
                              ? "5%"
                              : "2%"}{" "}
                            back
                          </b>
                        ) : parseFloat(nextOffer) - parseFloat(amount) > 0 ? (
                          <b>
                            Spend $
                            {Math.ceil(
                              parseFloat(nextOffer) - parseFloat(amount)
                            )}{" "}
                            more to get{" "}
                            {currentTier === 0
                              ? "3%"
                              : currentTier === 1
                              ? "4%"
                              : currentTier === 2
                              ? "5%"
                              : currentTier >= 3
                              ? "5%"
                              : "2%"}{" "}
                            back
                          </b>
                        ) : parseFloat(nextOffer) - parseFloat(amount) < 0 ? (
                          <b>
                            You will earn{" "}
                            {currentTier === 0
                              ? "3%"
                              : currentTier === 1
                              ? "4%"
                              : currentTier === 2
                              ? "5%"
                              : currentTier >= 3
                              ? "5%"
                              : "2%"}{" "}
                            back
                          </b>
                        ) : null}
                      </span>
                    </>
                  </div>
                  <Link to="/">
                    <Button>Cancel Purchase</Button>
                  </Link>
                </Stack>
              </Slide>
            )}
          </>
        )}
        <PaymentModal
          navigateProfile={navigateProfile}
          customer={customer}
          lastTransaction={lastTransaction}
          onSendMoney={onSendMoney}
          business={business}
          customerBanks={customerBanks}
          amount={amount}
          open={showPaySubmit}
          handleClose={closePayment}
        />
      </Container>
    </>
  )
}

export default CustomerJoin
