
export const GET_PLAID_TOKEN = "GET_PLAID_TOKEN";
export const SET_PLAID_TOKEN = "SET_PLAID_TOKEN";
export const GS_PLAID_TOKEN_FAILED = "GS_PLAID_TOKEN_FAILED";

export const SET_BANK_INFO = 'SET_BANK_INFO';
export const REMOVE_BANK_INFO = 'REMOVE_BANK_INFO';



export const GET_PLAID_LINK_TOKEN = "GET_PLAID_LINK_TOKEN";
export const SET_PLAID_LINK_TOKEN = "SET_PLAID_LINK_TOKEN";
export const GS_PLAID_LINK_TOKEN_FAILED = "GS_PLAID_LINK_TOKEN_FAILED";

export const SEND_MONEY_REQUEST = "SEND_MONEY_REQUEST";
export const SEND_MONEY_SUCCESS = "SEND_MONEY_SUCCESS";
export const SEND_MONEY_FAILED = "SEND_MONEY_FAILED";


export const GET_USER_BANK = "GET_USER_BANK";
export const GET_USER_BANK_SUCCESS = "GET_USER_BANK_SUCCESS";
export const GET_USER_BANK_FAILED = "GET_USER_BANK_FAILED";

export const GET_BUSINESS_BANK = "GET_BUSINESS_BANK";
export const GET_BUSINESS_BANK_SUCCESS = "GET_BUSINESS_BANK_SUCCESS";
export const GET_BUSINESS_BANK_FAILED = "GET_BUSINESS_BANK_FAILED";


export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";

export const GET_COMPANY_TRANSACTIONS = "GET_COMPANY_TRANSACTIONS";
export const GET_COMPANY_TRANSACTIONS_SUCCESS = "GET_COMPANY_TRANSACTIONS_SUCCESS";
export const GET_COMPANY_TRANSACTIONS_FAILED = "GET_COMPANY_TRANSACTIONS_FAILED";