import React from "react";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import HomeContainer from '../../containers/Client/HomeContainer';

const Home = (props) => {


    return <>
        <HomeContainer {...props} />
    </>
};

export default Home;
