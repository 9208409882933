import React from "react"
import Link from "@mui/material/Link"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

import OnboardSteps from "../../components/OnboardSteps"
import OnboardStepsFinancial from "../../components/OnboardStepsFinancial/OnboardStepsFinancial"

const LearnButton = styled(Button)(() => ({
  color: "#fff",
  border: "1px solid",
  lineHeight: "52px",
  textDecoration: "none",
  fontWeight: 500,
  padding: "0px 40px",
  borderRadius: 3,
  marginTop: "20px",
  borderColor: "#e7eef5",
  backgroundColor: "#002634",
  "&:hover": {
    backgroundColor: "#45ccc5"
  }
}))

const AuthLayout = ({ children, steps = false, number, setStep, onboard }) => {
  return (
    <div className="body">
      <div className="single-layout">
        <div className="single-layout-row w-row">
          <div className="single-layout-col left w-col w-col-4">
            {steps ? (
              onboard === "financial" ? (
                <OnboardStepsFinancial setStep={setStep} number={number} />
              ) : (
                <OnboardSteps setStep={setStep} number={number} />
              )
            ) : (
              <Box className="single-layout-left" px={2}>
                <Link to="/" className="single-layout-logo w-inline-block">
                  <img
                    src={"/static/Localight-Logo-2021-wht.png"}
                    width="300"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 29vw, 27vw"
                    srcSet="/static/Localight-Logo-2021-wht-p-500.png 500w, /static/Localight-Logo-2021-wht-p-800.png 800w, /static/Localight-Logo-2021-wht.png 1120w"
                    alt=""
                  />
                </Link>
                <div>
                  <h4 className="white">
                    Put your business growth on autopilot.
                  </h4>
                  <p className="white text-large">
                    Your business needs automatic marketing the brings in new
                    customers, reward loyal customers, and increase your
                    revenue. Customer payments appear in your Localight
                    dashboard in real time.{" "}
                  </p>
                  {/* <p className="white text-large">Save your time and money!</p>
                  <Link href="/" underline="none">
                    <LearnButton variant="outlined">Learn more</LearnButton>
                  </Link> */}
                </div>
              </Box>
            )}
          </div>
          <div className="single-layout-col right w-col w-col-8">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
