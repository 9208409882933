import { request } from "../../utils/http"
import qs from "qs"
import Cookies from "js-cookie"
import { appConfig } from "../../config/app"
import GetCookieValue from "../../utils/getCookieValue"
import { push } from "connected-react-router"

function doLogin(payload) {
  let login = payload["login"]
  let password = payload["password"]

  const data = qs.stringify({
    username: login,
    password: password
  })
  return request
    .post("api/v1/login", data)
    .then(function (response) {
      const authToken = response.data?.token
      // Adds the token to the header

      localStorage.setItem("token", authToken)
      request.defaults.headers.common.Authorization = `Token ${authToken}`
      console.log(response)
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

function refreshToken(payload) {
  console.log("REFRESHING TOKEN")
  return request
    .post(
      "/api/v1/token/",
      qs.stringify({
        refresh_token: payload,
        grant_type: "refresh_token",
        client_id: appConfig.clientID,
        client_secret: appConfig.clientSecret
      })
    )
    .then(function (response) {
      let result = response.data
      const authToken = response.data?.access_token
      Cookies.set("token", authToken)
      Cookies.set("refresh_token", response.data?.refresh_token)
      Cookies.set("expires_in", response.data?.expires_in)
      console.log("TOKEN REFRESHED")
      // Adds the token to the header
      request.defaults.headers.common.Authorization = `Token ${authToken}`

      console.log("refresh token: " + JSON.stringify(result))
      return JSON.parse(JSON.stringify(result))
    })
    .catch(function (error) {
      console.log(error)
      localStorage.clear()
      push("/")
    })
}

function doLogout() {
  delete request.defaults.headers.common["Authorization"]
  localStorage.clear()
  return { success: true }
}

function doChangeProfile(payload) {
  let name = payload["name"]
  let phone_number = payload["phone_number"]
  console.log(request)
  return request
    .patch("api/v1/auth/user/", {
      name,
      phone_number
    })
    .then(function (response) {
      let result = JSON.parse(JSON.stringify(response.data))
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doRegister(payload) {
  let login = payload["email"]
  let password = payload["password"]
  let name = payload["name"]
  return request
    .post(
      "api/v1/signup",
      qs.stringify({
        email: login,
        password: password,
        name: name
      })
    )
    .then(function (response) {
      let result = JSON.parse(JSON.stringify(response.data))
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doPwdReset(payload) {
  let email = payload["email"]

  return request
    .post(
      "/api/v1/password_reset/",
      qs.stringify({
        email: email
      })
    )
    .then(function (response) {
      let result = JSON.parse(JSON.stringify(response.data))
      console.log("PWD RESET: " + result)
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doPwdResetValidate(payload) {
  let token = payload["token"]

  return request
    .post(
      "/api/v1/password_reset/validate_token/",
      qs.stringify({
        token: token
      })
    )
    .then(function (response) {
      let result = JSON.parse(JSON.stringify(response.data))
      console.log("PWD RESET Validate: " + result)
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doPwdResetConfirm(payload) {
  let email = payload["email"]
  let token = payload["token"]
  let password = payload["password"]

  return request
    .post(
      "/api/v1/password_reset/confirm/?",
      qs.stringify({
        email: email,
        token: token,
        password: password
      })
    )
    .then(function (response) {
      let result = JSON.parse(JSON.stringify(response.data))
      console.log("PWD RESET CONFIRM: " + result)
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doChangePassword(payload) {
  let old_password = payload["oldPassword"]
  let new_password = payload["newPassword"]
  let confirm_password = payload["confirmPassword"]

  request.defaults.xsrfHeaderName = "X-CSRFToken"
  request.defaults.xsrfCookieName = "csrftoken"

  return request
    .post(
      "/api/v1/auth/password/change/",
      qs.stringify({
        old_password: old_password,
        new_password1: new_password,
        new_password2: confirm_password
      }),
      { headers: { "X-CSRFToken": GetCookieValue("csrftoken") } }
    )
    .then(function (response) {
      let result = JSON.stringify(response.data)
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doAppleLogin(payload) {
  return request.post("/users/apple-login/", {
    email: payload.email,
    id: payload.id,
    token: payload.token
  })
}

function doGoogleLogin(payload) {
  return request
    .post(
      "/auth/convert-token",
      qs.stringify({
        token: payload.token,
        backend: "google-oauth2",
        grant_type: "convert_token",
        client_id: appConfig.clientID,
        client_secret: appConfig.clientSecret
      })
    )
    .then(function (response) {
      let result = response.data
      const authToken = response.data?.access_token
      Cookies.set("token", authToken)
      Cookies.set("refresh_token", response.data?.refresh_token)
      Cookies.set("expires_in", response.data?.expires_in)

      // Adds the token to the header
      request.defaults.headers.common.Authorization = `Token ${authToken}`

      console.log("doGoogleLogin: " + JSON.stringify(result))
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doFacebookLogin(payload) {
  return request
    .post("/users/facebook-login/", {
      token: payload.token
    })
    .then(function (response) {
      let result = response.data
      console.log("doFacebookLogin: " + JSON.stringify(result))
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doCustomerRegister(payload) {
  let email = payload["email"]
  let mobile_number = payload["mobile_number"]
  let first_name = payload["first_name"]
  let last_name = payload["last_name"]
  const zipcode = payload["zip_code"]
  return request
    .post(
      "api/v1/customer/signup",
      qs.stringify({
        email: email,
        mobile_number: mobile_number,
        first_name: first_name,
        last_name: last_name,
        zipcode: zipcode
      })
    )
    .then(function (response) {
      let result = JSON.parse(JSON.stringify(response.data))
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doSentOtp(payload) {
  let mobile_number = payload["mobile_number"]

  return request
    .post(
      "/api/v1/generate-otp",
      qs.stringify({
        mobile_number: mobile_number
      })
    )
    .then(function (response) {
      let result = { sent: false }
      if (response.status === 201) {
        result = { sent: true }
        console.log("otp sent")
      }
      return result
    })
    .catch(function (error) {
      throw error
    })
}

function doCustomerLogin(payload) {
  let mobile_number = payload["mobile_number"]
  const otp = payload["otp_code"]
  return request
    .post(
      "api/v1/login/customer",
      qs.stringify({
        mobile_number: mobile_number,
        otp_code: otp
      })
    )
    .then(function (response) {
      console.log("LOGIN RESPONSE", response)
      const authToken = response.data?.token

      localStorage.setItem("token", authToken)
      console.log("LOGIN RESPONSE TOKEN", authToken)
      request.defaults.headers.common.Authorization = `Token ${authToken}`
      let result = JSON.parse(JSON.stringify(response.data))
      return result
    })
    .catch(function (error) {
      throw error
    })
}

export const AuthApiServices = {
  doLogin,
  doRegister,
  doPwdReset,
  doAppleLogin,
  doGoogleLogin,
  doFacebookLogin,
  doChangeProfile,
  doLogout,
  doChangePassword,
  doPwdResetConfirm,
  doPwdResetValidate,
  refreshToken,
  doSentOtp,
  doCustomerRegister,
  doCustomerLogin
}
