import { put, call, spawn, all, takeEvery } from "redux-saga/effects"
import { PaymentApiServices } from "./services"
import * as types from "./constants"
import * as actions from "./actions"
import * as StoreUtil from "../storeUtil";
// import { push } from 'connected-react-router';


function* getPlaidLinkTokenWorker(action) {
  try {
    StoreUtil.logAction("getPlaidWorker", action);

    const result = yield call(PaymentApiServices.getPlaidLinkToken, action);
    if(result) {
      yield put(actions.setPlaidLinkToken(result));
    }
    console.log('plaid', result);
  } catch (err) {
    StoreUtil.logError("getPlaidWorker:", err);
    yield put(actions.getPlaidLinkTokenFailed(err));
  }
}
function* getPlaidLinkTokenWatcher() {
  yield takeEvery(types.GET_PLAID_LINK_TOKEN, getPlaidLinkTokenWorker)
}



function* updatePlaidTokenWorker(action) {
  try {
    StoreUtil.logAction("updatePlaidWorker", action);
    const result = yield call(PaymentApiServices.postPlaidToken, action);
    yield put(actions.setPlaidToken(result));
  } catch (err) {
    StoreUtil.logError("updatePlaidWorker: ", err);
    yield put(actions.getPlaidTokenFailed(err));
  }
}
function* updatePlaidTokenWatcher() {
  yield takeEvery(types.GET_PLAID_TOKEN, updatePlaidTokenWorker)
}


function* userSendMoneyWorker(action) {
  try {
    StoreUtil.logAction("send money", action);
    const result = yield call(PaymentApiServices.doSendMoney, action);
    if(result)
      yield put(actions.successSendMoney(result));
  } catch (err) {
    StoreUtil.logError("send money: ", err);
    yield put(actions.failedSendMoney(err));
  }
}
function* userSendMoneyWatcher() {
  yield takeEvery(types.SEND_MONEY_REQUEST, userSendMoneyWorker)
}


function* getUserBankWorker(action) {
  try {
    StoreUtil.logAction("customr banks", action);

    const result = yield call(PaymentApiServices.getCustomerBankInfo, action);
    if(result) {
      yield put(actions.setCustomerBank(result));
    }
    console.log('customr banks', result);
  } catch (err) {
    StoreUtil.logError("customr banks:", err);
    yield put(actions.getCustomerBankFailed(err));
  }
}
function* getUserBankWatcher() {
  yield takeEvery(types.GET_USER_BANK, getUserBankWorker)
}

function* getBusinessBankWorker(action) {
  try {
    StoreUtil.logAction("Business Banks", action);

    const result = yield call(PaymentApiServices.getCompanyBankInfo, action);
    if(result) {
      yield put(actions.setBusinessBank(result));
    }
    console.log('Business', result);
  } catch (err) {
    StoreUtil.logError("Business:", err);
    yield put(actions.getBusinessBankFailed(err));
  }
}
function* getBusinessBankWatcher() {
  yield takeEvery(types.GET_BUSINESS_BANK, getBusinessBankWorker)
}


function* getTransactionsWorker(action) {
  try {
    StoreUtil.logAction("transactions", action);

    const result = yield call(PaymentApiServices.getTransactions, action);
    if(result) {
      yield put(actions.setTransactions(result));
    }
    console.log('transactions', result);
  } catch (err) {
    StoreUtil.logError("transactions:", err);
    yield put(actions.getTransactionsFailed(err));
  }
}
function* getTransactionsWatcher() {
  yield takeEvery(types.GET_TRANSACTIONS, getTransactionsWorker)
}


function* getCompanyTransactionsWorker(action) {
  try {
    StoreUtil.logAction("company transactions", action);

    const result = yield call(PaymentApiServices.getCompanyTransactions, action);
    if(result) {
      yield put(actions.setCompanyTransactions(result));
    }
    console.log('company transactions', result);
  } catch (err) {
    StoreUtil.logError("company transactions:", err);
    yield put(actions.getCompanyTransactionsFailed(err));
  }
}
function* getCompanyTransactionsWatcher() {
  yield takeEvery(types.GET_COMPANY_TRANSACTIONS, getCompanyTransactionsWorker)
}


// export default companyRootSagas;

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* paymentRootSagas() {
  const sagas = [
    // Example watcher
    // getBalanceWatcher
    getPlaidLinkTokenWatcher,
    updatePlaidTokenWatcher,
    userSendMoneyWatcher,
    getUserBankWatcher,
    getBusinessBankWatcher,
    getTransactionsWatcher,
    getCompanyTransactionsWatcher,
  ]
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}