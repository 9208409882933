import * as React from "react"
import { styled } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import Toolbar from "@mui/material/Toolbar"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined"
import { useDispatch } from "react-redux"
import { DO_LOGOUT } from "../../store/auth/constants"
import { push } from "connected-react-router"
import filler from "../../assets/images/On-The-Edge-Miller.jpg"



const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
})

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}))

const MainDrawer = ({ open = true, handleDrawerClose, handleDrawerOpen }) => {
  const dispatch = useDispatch()

  const doLogout = () => {
    dispatch({ type: DO_LOGOUT })
    console.log("test")
    dispatch(push("/dashboard/login"))
    console.log("test 2")
  }

  const onMenuItemClick = index => {
    if (index === 0) dispatch(push("/dashboard"))
    if (index === 1) dispatch(push("/dashboard/location-profile"))
    if (index === 2) dispatch(push("/dashboard/account-settings"))
    if (index === 3) dispatch(push("/dashboard/payments"))
    if (index === 4) dispatch(push("/dashboard/customers"))
  }

  const onHelpMenuClick = index => {
    if (index === 0) {
      const mailto: string =
        "mailto:hello@localight.com?subject=Support&body=Support"
      window.location.href = mailto
    }
    if (index === 1) doLogout()
  }

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          background: "#003e56"
        }}
      />
      <List>
        {[
          "Dashboard",
          "Location Profiles",
          "Account Settings",
          "Payments"
          //"Customers"
        ].map((text, index) => (
          <ListItem button key={text} onClick={() => onMenuItemClick(index)}>
            <ListItemIcon>
              {index === 0 && <DashboardIcon />}
              {index === 1 && <AccountCircleRoundedIcon />}
              {index === 2 && <SettingsApplicationsIcon />}
              {index === 3 && <AccountBalanceIcon />}
              {/* {index === 4 && <ContactsOutlinedIcon />} */}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Help", "Logout"].map((text, index) => (
          <ListItem button key={text} onClick={() => onHelpMenuClick(index)}>
            <ListItemIcon>
              {index === 0 && <HelpOutlineIcon />}
              {index === 1 && <LogoutRoundedIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>

      {/*<List>
        <div style={{width:'94%', left:'3%', position:'relative', height:'200px'}} >
          <div>
            <img style={{width:'100%', borderRadius:'20px', height:'150px', objectFit:'cover'}} src={filler} />
          </div>
          <div style={{width:'90%', left:'5%', position:'relative'}}>
            <div style={{fontSize:'15px', fontWeight:'bold', color:'black'}}>
              Need short-term cash?<br/> Get a Business Line of Credit
            </div>
            <ul style={{color:'red', listStyleType:'disc', left:'-10px', position:'relative'}}>
              <li><div style={{color:'black'}}>$0 origination fee, and <br/> low variable rates </div></li>
              <li><div style={{color:'black'}}>Overdraft protection</div></li>
              <li><div style={{color:'black'}}>and much more...</div></li>
            </ul>
          </div>
          <div>
              
            <button
              style={{width:'80%', left:'10%', position:'relative', backgroundColor:'red', color:'white', height:'40px'}}
              onClick={(e) => {
                e.preventDefault();
                window.location.href='http://google.com';
                }}>
                Make an Appointment
            </button>
          </div>
        </div>
      </List>*/}

      <List style={{ marginTop: `auto` }}>
        <Divider />
        <ListItem button onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <ListItemIcon>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
          <ListItemText primary={open ? "Hide" : ""} />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default MainDrawer
