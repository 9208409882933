import { put, call, spawn, all, takeEvery } from "redux-saga/effects";
import { CustomerApiServices } from "./services"
import * as types from "./constants"
import * as actions from "./actions"
import * as StoreUtil from "../storeUtil";


function* getCustomerInfoWorker(action) {
  try {
    StoreUtil.logAction("customer info", action);
    const result = yield call(CustomerApiServices.getCustomerInfo, action);
    if(result)
      yield put(actions.setCustomer(result));
  } catch (err) {
    StoreUtil.logError("customer info: ", err);
    yield put(actions.getCustomerFailed(err));
  }
}
function* getCustomerInfoWatcher() {
  yield takeEvery(types.GET_CUSTOMER, getCustomerInfoWorker)
}


// export default companyRootSagas;

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* customerRootSagas() {
  const sagas = [
    // Example watcher
    // getBalanceWatcher
    getCustomerInfoWatcher,
  ]
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}