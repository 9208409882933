import { request } from "../../utils/http";
// import qs from 'qs';

function getPlaidLinkToken() {
  return request.get("/api/v1/plaid/link-token-create").then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function postPlaidToken(payload) {

  // console.log('payload', payload);

  return request.post("/api/v1/plaid/public-token-exchange",
  {
    "public_token": payload.public_token,
  },
    {
      headers: {
          'Content-Type': 'application/json',
      }
  }
  ).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function doSendMoney(payload) {

  // console.log('payload', payload);

  return request.post("/api/v1/transactions/send-money",
  {
    "bank_id": payload.bank_id,
    "company_id": payload.company_id,
    "amount": payload.amount
  },
    {
      headers: {
          'Content-Type': 'application/json',
      }
  }
  ).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function addCustomerBankAccount(payload) {

  // console.log('payload', payload);

  return request.post(`/api/v1/customers/${payload?.id}/add-bank`,
  {
    "name": payload.name,
    "plaid_bank_id": payload.plaid_bank_id,
    "plaid_access_token": payload.plaid_access_token
  },
    {
      headers: {
          'Content-Type': 'application/json',
      }
  }
  ).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function getCustomerBankInfo(payload) {
  return request.get(`/api/v1/customers/${payload?.id}/bank-accounts`).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function addCompanyBankAccount(payload) {

  // console.log('payload', payload);

  return request.post(`/api/v1/mycompanies/${payload?.id}/add-bank`,
  {
    "name": payload.name,
    "plaid_bank_id": payload.plaid_bank_id,
    "plaid_access_token": payload.plaid_access_token,
    'routingNumber': payload.routingNumber,
    'accountNumber': payload.accountNumber,
    'bankAccountType': payload.bankAccountType
  },
    {
      headers: {
          'Content-Type': 'application/json',
      }
  }
  ).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function getCompanyBankInfo(payload) {
  return request.get(`/api/v1/mycompanies/${payload?.id}/bank-accounts`).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function getTransactions() {
  return request.get(`/api/v1/transactions`).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


function getCompanyTransactions(payload) {
  return request.get(`/api/v1/mycompanies/${payload?.id}/transactions`).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}


export const PaymentApiServices = {
  getPlaidLinkToken,
  postPlaidToken,
  doSendMoney,
  getCustomerBankInfo,
  addCustomerBankAccount,
  getCompanyBankInfo,
  addCompanyBankAccount,
  getTransactions,
  getCompanyTransactions
}
