import React from 'react';
import Link from '@mui/material/Link';

const OnboardSteps = ({ number, setStep }) => {

    return <div className="single-layout-left steps">
        <Link href="/" underline="none" className="single-layout-logo w-inline-block"><img src="/static/Localight-Logo-2021-wht.png" width="300" sizes="(max-width: 767px) 100vw, (max-width: 991px) 29vw, 27vw" srcSet="/static/Localight-Logo-2021-wht-p-500.png 500w, /static/Localight-Logo-2021-wht-p-800.png 800w, /static/Localight-Logo-2021-wht.png 1120w" alt=""/></Link>
        <div className="onboarding-steps-container">
            <Link href="#" onClick={() => setStep(1)} underline="none" aria-current="page" className={`onboarding-steps w-inline-block ${number === 1 && 'w--current'}`}>
                <h6 className="white">Step 1</h6>
                <div className="text-large">Welcome</div>
            </Link>
            <Link href="#" onClick={() => setStep(2)} underline="none" className={`onboarding-steps w-inline-block ${number === 2 && 'w--current'}`}>
                <h6 className="white">Step 2</h6>
                <div className="text-large">Account type</div>
            </Link>
            <Link href="#" onClick={() => setStep(3)} underline="none" className={`onboarding-steps w-inline-block ${number === 3 && 'w--current'}`}>
                <h6 className="white">Step 3</h6>
                <div className="text-large">Company Details</div>
            </Link>
            <Link href="#" onClick={() => setStep(4)} underline="none" className={`onboarding-steps w-inline-block ${number === 4 && 'w--current'}`}>
                <h6 className="white">Step 4</h6>
                <div className="text-large">Location</div>
            </Link>
            <Link href="#" onClick={() => setStep(6)} underline="none" className={`onboarding-steps w-inline-block ${number === 6 && 'w--current'}`}>
                <h6 className="white">Step 4</h6>
                <div className="text-large">Location</div>
            </Link>
            <Link href="#" onClick={() => setStep(5)} underline="none" className={`onboarding-steps w-inline-block ${number === 5 && 'w--current'}`}>
                <h6 className="white">Step 5</h6>
                <div className="text-large">Personals</div>
            </Link>
        </div>
  </div>
}

export default OnboardSteps;