import * as React from "react"
import { useEffect, useState } from 'react'
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import AccountCircle from "@mui/icons-material/AccountCircle"
import Link from "@mui/material/Link"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import { useSelector } from "react-redux"

import logo from "../../assets/images/localightblck.png"

const AppBarHeader = ({ open, handleDrawerOpen }) => {
  const token = useSelector(state => state.authReducer.token)
  const companies = useSelector(state => state.companyReducer.company?.data)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [companyName, setCompanyName] = React.useState(null)
  const [width, setWidth] = useState(window.innerWidth)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    if (companies && companies[0]?.id) {
      setCompanyName(companies[0]?.name)
    }
  }, [companies])
  
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768;

  return (
    <AppBar
      position="fixed"
      sx={{
        paddingLeft: 0,
        zIndex: theme => theme.zIndex.drawer + 1,
        background: "#ffffff"
      }}
    >
      <Toolbar
        sx={{
          background: "white"
        }}
      >
        {/* Conditional rendering to display the logo only on desktop, so the name of the merchant shows up on mobile */}
        { !isMobile
          ? 
            <Box
              sx={{ minWidth: 240, mr: 3, background: "white" }}
              onClick={handleDrawerOpen}
            >
              <Link href="/dashboard" className="sidebar-navbar-brand w-nav-brand">
                <img
                  src={logo}
                  height="120"
                  sizes="(max-width: 991px) 100vw, 157.5px"
                  alt=""
                  className="sidebar-navbar-brand-logo"
                />
                <img
                  src={"/static/artboard-color-small.svg"}
                  height="20"
                  alt=""
                  className="sidebar-navbar-brand-logo-small"
                />
              </Link>
            </Box>
          : null
        }  
        <link rel="stylesheet" href="https://use.typekit.net/ysz0jej.css"/>
        <Typography
          variant="h6"
          component="div"
          sx={{
            top:'5px',
            fontFamily:'shelby',
            fontWeight:'700',
            fontStyle:'normal',
            fontSize:'40px',
            position:'relative',
            color: "black",
            display: "flex",
            flexGrow: 1,
            ...(!isMobile && {justifyContent:'right'}),
            ...(isMobile && {justifyContent:'left'})
          }}
        >
          {companyName || "Localight Business Admin"}
        </Typography>

        {console.log('companies for test: ', companies)}
        <img
              src={companies?.at(0).profile_image}
            
              style={{objectFit:'cover', width:'50px', height:'50px', borderRadius:'50%', position:'relative', left:'20px'}}
              alt=""
            />
        {token && (
          <div>
            
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default AppBarHeader
