import * as types from "./constants"

export const doLogout = _ => ({
  type: types.DO_LOGOUT
})

export const doLogoutSuccess = _ => ({
  type: types.DO_LOGOUT_SUCCESS
})

export const setStatus = status => ({
  type: types.SET_STATUS,
  status
})

export const doLogin = (login, password) => ({
  type: types.DO_LOGIN,
  login,
  password
})

export const doLoginSucceeded = response => ({
  type: types.DO_LOGIN_SUCCEEDED,
  response
})

export const doLoginFailed = error => ({
  type: types.DO_LOGIN_FAILED,
  error
})

export const doFBLogin = token => ({
  type: types.DO_FBLOGIN,
  token
})

export const doFBLoginSucceeded = response => ({
  type: types.DO_FBLOGIN_SUCCEEDED,
  response
})

export const doFBLoginFailed = error => ({
  type: types.DO_FBLOGIN_FAILED,
  error
})

export const doGoogleLogin = token => ({
  type: types.DO_GOOGLELOGIN,
  token
})

export const doGoogleLoginSucceeded = response => ({
  type: types.DO_GOOGLELOGIN_SUCCEEDED,
  response
})

export const doGoogleLoginFailed = error => ({
  type: types.DO_GOOGLELOGIN_FAILED,
  error
})

export const doRegister = (name, email, phone_number, password) => ({
  type: types.DO_REGISTER,
  name,
  email,
  phone_number,
  password
})

export const changeProfile = (name, phone_number) => ({
  type: types.CHANGE_PROFILE,
  name,
  phone_number
})

export const changePassword = (oldPassword, newPassword, confirmPassword) => ({
  type: types.CHANGE_PASSWORD,
  oldPassword,
  newPassword,
  confirmPassword
})

export const doRegisterSucceeded = response => ({
  type: types.DO_REGISTER_SUCCEEDED,
  response
})

export const doRegisterFailed = error => ({
  type: types.DO_REGISTER_FAILED,
  error
})

export const doPwdReset = email => ({
  type: types.DO_PWDRESET,
  email
})
export const doPwdResetStatus = status => ({
  type: types.DO_PWDRESET_STATUS,
  status
})
export const doPwdResetSucceeded = response => ({
  type: types.DO_PWDRESET_SUCCEEDED,
  response
})
export const doPwdResetFailed = error => ({
  type: types.DO_PWDRESET_FAILED,
  error
})

export const doOtpLogin = (otp_code, mobile_number) => ({
  type: types.DO_OTP_LOGIN,
  otp_code,
  mobile_number
})

export const doOtpLoginSucceeded = response => ({
  type: types.DO_OTP_LOGIN_SUCCEEDED,
  response
})

export const doOtpLoginFailed = error => ({
  type: types.DO_OTP_LOGIN_FAILED,
  error
})

export const doOtpSignup = mobile => ({
  type: types.DO_OTP_SIGNUP,
  mobile
})

export const doOtpSignupSucceeded = response => ({
  type: types.DO_OTP_SIGNUP_SUCCEEDED,
  response
})

export const doOtpSignupFailed = error => ({
  type: types.DO_OTP_SIGNUP_FAILED,
  error
})
