import React, { useState } from "react"
// import Link from '@mui/material/Link';
import {
  SET_PLAID_TOKEN
  // SET_BANK_INFO,
} from "../../store/payment/constants"
import { PaymentApiServices } from "../../store/payment/services"
// import Box from '@mui/material/Box';
import { useDispatch } from "react-redux"
import loadingAnimation from "../../assets/animations/loadingAnimation15.json"
import LottieAnimation from "../../components/Client/Animation"
import checkAnimation from "../../assets/animations/checkAnimation.json"
import { useForm } from "react-hook-form"
import Select from "react-select"
import { request } from "../../utils/http"
import "./func.css"

import RangeSlider from "./component/range_slider"
import {a} from "./component/range_slider";


import {
  usePlaidLink
  // PlaidLinkOptions,
  // PlaidLinkOnSuccess,
} from "react-plaid-link"

import PlaidFlow from "../../assets/images/Plaid-Flow.png"
import BankNumbers from "../../assets/images/bank-routing-account-numbers.png"

let b = 0
let min = 0

let atv = 0
let ttv = 0
/*
const submit_changes = async data =>{
  const header ={

  }
  request
  .post("/api/v1/mycompanies", data, {headers: header})
}*/


const Example = props => { 
  // const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()
  


  const [xtext, enableButton] = useState("");
  let ttv_over_atv
  const [showtext, showtextallow, setShowText] = useState("");
  
  const handle_ttv_change = event =>{
   ttv = event.target.value
   ttv_over_atv = ttv/atv
   ttv_over_atv = Math.round(100*ttv_over_atv)/100
   document.getElementById("increase").innerHTML = ("Great that's a " + ttv_over_atv + "x increase")
  }
  let show = 0

  const handle_atv_change = (event)=>{
    atv = event.target.value
    showtextallow(event.target.value)

  }
  
  return (

    <div style = {{paddingTop:'10px'}}>
      <div className={showtext ? "unshow":""} style={{height:'80px', backgroundColor:'white', width:'450px', left:'', position:'absolute', top:'500px', zIndex:10}}/> 

      <h4 className="mt-30 mb-30" style={{ textAlign: "left"}}>
      What does Localight do for me, the business owner?
      </h4>
      <div className="text-large">
        Our goal is to incentivize customers to choose local over major chains and 
        re-circulate money into the local economy. 
        But most importantly, we help independent businesses make more money
        by increasing their Average Transaction.
        
        
      
        <h4 className="mt-30 mb-30" style={{ textAlign: "left"}}>
        What is your current Average Transaction?
        </h4>
      </div>
      
      <div className="form-group">
      <label  className="label">
      </label>
      <div style={{display:'flex'}}>
        <div style={{fontSize:'20px', paddingTop:'8px', paddingRight:'5px'}}>$</div>
      <input
      style={{width:'100%'}}
        type="text"
        aria-invalid={errors.address ? "true" : "false"}
        {...register("address", {
          required: "The estimated ATV is required"
        })}
        className="input atv_input"
        maxLength="256"
        name="atv"
        placeholder="Enter your Average Transaction Value"
        id="atv"
        value={showtext}

        onChange={handle_atv_change}
      />
      </div>
      {errors.address && (
        <div className="error-message">
          <div>{errors.address.message}</div>
        </div>
      )}
    </div>
    <div>
    <div>
    <div >
      <label className="label">
        <div>
        </div>
      </label>
          {/*<RangeSlider />*/}
          <h4 className="mt-30 mb-30" style={{ textAlign: "left"}}>
        What would you like it to be?
        </h4>
      <div style={{display:'flex'}}>
            
    <div style={{fontSize:'20px', paddingTop:'8px', paddingRight:'5px', marginBottom: "20px"}}>$</div>
      <input
      style={{width:'100%'}}
        type="text"
        aria-invalid={errors.address ? "true" : "false"}
        {...register("address", {
          required: "The estimated TTV is required"
        })}
        className="input ttv_input"
        maxLength="256"
        name="ttv"
        placeholder="Enter your Target Transaction Value"
        id="ttv"
        onChange={handle_ttv_change}
      />
      
      </div>
          <h4 className={showtext ? "show":"unshow"} id ='increase' style ={{color:'#45CCC5'}}>

          </h4>

      <div>
      <div className={showtext ? "show":"unshow"}>
          <h3 style={{ paddingTop:'60px', textAlign:'center'}}>How it works</h3>
          <div>
          <h4>If the customer spends less than your Target Transaction Value:</h4>
          <h4>We charge 4.99% which includes</h4>
          <ul>
            <li>2.99% transaction fee, Credit/Debit</li>
            <li>2% returns to the customer in Localight Cash</li>
          </ul>
          </div>
          <div>
          <h4>If the customer spends more than your Target Transaction Value:</h4>
            <ul>
              <li>2.99% transaction fee, Credit/Debit</li>
              <li>6.99% funds the Localight Cash Back Rewards (3-6% back to the customer)</li>
            </ul>
          </div>
          <div>
            <h4>How does the Localight Cash Rewards program work?</h4>
            <ul>
              <li>The customer earns Localight Cash that can only be spent at Localight Businesses.</li>
              <li>They don’t get discounts that make you lose money, they get cash to spend at your businesses.</li>
            </ul>
          </div> 
        </div>
      
      </div>
    

          
        
        
    </div>
    

        </div>
        </div>
    
    </div>    
 
  )
      }
export {b};
export {min};
export {atv, ttv}
export default Example
