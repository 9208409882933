import React, { useState } from "react"
import Link from "@mui/material/Link"
import { DO_REGISTER, DO_GOOGLELOGIN } from "../../store/auth/constants"
import { useDispatch } from "react-redux"
import Checkbox from "@mui/material/Checkbox"
import GoogleButton from "../../components/SocialLogin/Google"

const SignupContainer = props => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()

  const submitLogin = e => {
    e.preventDefault()
    dispatch({
      type: DO_REGISTER,
      name: name,
      email: email,
      password: password
    })

    console.log(email, password)
  }

  const handleSocialLogin = user => {
    console.log(user)

    dispatch({
      type: DO_GOOGLELOGIN,
      token: user?._token?.accessToken,
      email: user?._profile?.email
    })
  }

  const handleSocialLoginFailure = err => {
    console.error(err)
  }

  return (
    <>
      <div className="single-layout-right-header">
        Already have account?{" "}
        <Link href="/dashboard/login" underline="none" className="link">
          <strong>Sign In</strong>
        </Link>
      </div>
      <div className="single-layout-right">
        <div className="single-layout-right-content">
          <div className="single-layout-logo-right">
            <Link href="/" underline="none" className="w-inline-block">
              <img
                src="/static/Localight-Logo-reverse.svg"
                width="150"
                alt=""
              />
            </Link>
          </div>
          <h3 className="text-center mb-40">
            Spotlight your business on Localight
          </h3>
          {/* <GoogleButton
                  provider="google"
                  appId="469399127987-og0798rgnlps91oud33gcuuiukdj1ijh.apps.googleusercontent.com"
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  Sign up with Google
                </GoogleButton> */}
          <div className="w-form">
            <form
              id="login-form"
              name="login-form"
              data-name="Login Form"
              onSubmit={submitLogin}
            >
              {/* <p className="text-center">or continue with email:</p> */}
              <label htmlFor="name" className="label">
                <strong>What should we call you?</strong>
              </label>
              <input
                type="text"
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}
                className="input w-input"
                maxLength="256"
                name="name"
                placeholder="Enter your name"
                id="name"
                required=""
              />
              <label htmlFor="email" className="label">
                <strong>What&#x27;s your email?</strong>
              </label>
              <input
                type="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                className="input w-input"
                maxLength="256"
                name="email"
                placeholder="Enter your email"
                id="email"
                required=""
              />
              <label htmlFor="password" className="label">
                <strong>Create a password</strong>
              </label>
              <input
                type="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value)
                }}
                className="input w-input"
                maxLength="256"
                name="password"
                placeholder="Enter your password"
                id="password"
                required=""
              />
              <label className="w-checkbox checkbox-field mb-40">
                <Checkbox
                  checked={checked}
                  onChange={e => setChecked(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="hint w-form-label">
                  I agree to the{" "}
                  <a href="/#" target="_blank" className="link">
                    Localight terms &amp; conditions
                  </a>
                </span>
              </label>
              <button
                type="submit"
                disabled={!checked}
                className="button button-primary button-block w-button"
              >
                Continue
              </button>
            </form>
            <div className="error-message w-form-fail">
              <div>
                Oops! Something went wrong while submitting the credetials.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignupContainer
