import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import LocationProfileContainer from '../../containers/LocationProfileContainer';

const LocationProfile = (props) => {


    return <DashboardLayout>
        <LocationProfileContainer {...props} />
    </DashboardLayout>
};

export default LocationProfile;
