import React from "react";

import TransactionsContainer from '../../containers/Client/TransactionsContainer';

const CustomerProfile = (props) => {


    return <>
        <TransactionsContainer {...props} />
    </>
};

export default CustomerProfile;
