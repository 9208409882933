import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import CustomersContainer from '../../containers/CustomersContainer';

const Customers = (props) => {


    return <DashboardLayout>
        <CustomersContainer {...props} />
    </DashboardLayout>
};

export default Customers;
