import React from 'react';
import styled from '@emotion/styled';
import SocialLogin from "react-social-login";

const Button = styled.button`
  color: turquoise;
`
class GoogleButton extends React.Component {
    render() {
      const { children, triggerLogin, ...props } = this.props;
      return (
        <Button onClick={triggerLogin} {...props} className="button button-google button-block w-button">
          {children}
        </Button>
      );
    }
  }


export default SocialLogin(GoogleButton);