import React, { useState, useEffect, createRef } from "react"
import Link from "@mui/material/Link"
import { useDispatch, useSelector } from "react-redux"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import LocationAddDialog from "../../components/LocationAddDialog"
import LocationEdit from "../../components/EditLocation"
import Avatar from "@mui/material/Avatar"
// import IconButton from '@mui/material/IconButton';
import { GET_COMPANY } from "../../store/company/constants"
import { getPathFromUrl } from "../../utils/getURL"
import VerifiedCustomer from "../../components/VerifiedCustomer"
import UploadDocument from "../../components/UploadDocument"
import HeartlandLogo from "../../assets/images/Heartland-first-promo.jpg"
import { CompanyApiServices } from "../../store/company/services"
import QueryString from "qs"
import Chatbot from "../../components/Chatbot/index"


const LocationProfileContainer = props => {
  const dispatch = useDispatch()
  // const status = useSelector(state => state.authReducer.login.status);
  const categories = useSelector(state => state.companyReducer.categories.data)
  const companies = useSelector(state => state.companyReducer.company?.data)

  const [tab, setTab] = React.useState("1")

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const [companyName, setCompanyName] = useState("")
  const [companyDescription, setCompanyDescription] = useState("")
  const [companyPhone, setCompanyPhone] = useState("")
  const [companyCategory, setCompanyCategory] = useState("")

  const [locatedIn, setLocatedIn] = useState("")
  const [locatedAdress, setLocatedAdress] = useState("")
  // const [locationDescription, setLocationDescription] = useState('');
  // const [locationCity, setLocationCity] = useState('');

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  // const [dateofBirth, setDateofBirth] = useState('');
  // const [gender, setGender] = useState('');
  const [email, setEmail] = useState("")
  // const [phone, setPhone] = useState('');

  const [companyProfilePicture, setcompanyProfilePicture] = useState(null)

  const [companyCoverPicture, setcompanyCoverPicture] = useState(null)

  const [openLocationModal, setOpenLocationModal] = useState(false)

  const handleOpenModal = () => {
    setOpenLocationModal(true)
  }

  const handleCloseModal = () => {
    setOpenLocationModal(false)
  }

  useEffect(() => {
    if (companies && companies[0]?.id) {
      console.log('Current companies: ', companies)
      setCompanyName(companies[0]?.name)
      setCompanyDescription(companies[0]?.description)
      setCompanyPhone(companies[0]?.phone_no)
      // setcompanyProfilePicture(companies[0].image);

      // eslint-disable-next-line array-callback-return
      !!categories?.length &&
        categories.find(item => {
          if (item.id === companies[0]?.category[0]) {
            setCompanyCategory({ value: item.id, label: item.name })
            return { value: item.id, label: item.name }
          }
        })
      setLocatedIn(companies[0]?.locations[0]?.location_name)
      setLocatedAdress(companies[0]?.locations[0]?.complete_address)
      setFirstName(companies[0]?.locations[0]?.personnels[0]?.first_name)
      setLastName(companies[0]?.locations[0]?.personnels[0]?.last_name)
      setEmail(companies[0]?.locations[0]?.personnels[0]?.email)
    }
  }, [companies, categories])

  const inputFileRef = createRef(null)

  const cleanup = () => {
    URL.revokeObjectURL(companyProfilePicture)
    inputFileRef.current.value = null
  }

  const setImage = newImage => {
    if (companyProfilePicture) {
      cleanup()
    }
    setcompanyProfilePicture(newImage)
  }

  const addProfilePicture = event => {
    const newImage = event.target?.files?.[0]

    if (newImage) {
      console.log('Currently updating the profile picture')
      setImage(URL.createObjectURL(newImage))
      let data = new FormData()
      data.append("profile_image", newImage)
      data.append("id", companies[0].id)
      console.log('This is the data for the profile picture: ', data.get("profile_image"))
      proPictureUpdate(data)
    }
  }

  const proPictureUpdate = async (data) => {
    console.log('Updating profile picture, data is: ', data.get("profile_image"))
    const result_update_picture = await CompanyApiServices.addCompanyImage(data)
    console.log("result_update_picture", result_update_picture)
    if (result_update_picture?.id) {
      dispatch({
        type: GET_COMPANY
      })
    }
  }

  const inputCoverFileRef = createRef(null)

  const cleanupCover = () => {
    URL.revokeObjectURL(companyCoverPicture)
    inputCoverFileRef.current.value = null
  }

  const setCoverImage = newImage => {
    if (companyCoverPicture) {
      cleanupCover()
    }
    setcompanyCoverPicture(newImage)
  }

  const addCoverPicture = event => {
    const newImage = event.target?.files?.[0]

    if (newImage) {
      setCoverImage(URL.createObjectURL(newImage))
      let data = new FormData()
      data.append("image", newImage)
      data.append('id', companies[0].id)
      CoverPictureUpdate(data)
    }
  }

  const CoverPictureUpdate = async (data) => {
    const result = await CompanyApiServices.addCompanyCoverImage(data)
    console.log(result, "res")
    if (result?.id) {
      dispatch({
        type: GET_COMPANY
      })
    }
  }

  return (
    <>
      <div className="app-main-layout">
        <Chatbot/>
        <div className="app-main-layout-header">
          <div className="app-main-layout-wrapper header">
            <div className="page-heading">
              <h2 className="heading-3">Location Profiles</h2>
            </div>
            <div className="page-tabs-menu">
              <a
                underline="none"
                href="/location-profile"
                aria-current="page"
                className="page-tab-link location-1 w--current"
              >
                {locatedIn}
              </a>
              <div className="add-location-button" onClick={handleOpenModal}>
                Add Location
              </div>
            </div>
          </div>
        </div>
        <div className="app-main-layout-wrapper">
          <div className="app-main-layout-content">
            <div className="section">
              <div className="row w-row">
                <div className="col w-col w-col-9">
                  <div className="card">
                    <input
                      ref={inputCoverFileRef}
                      accept="image/*"
                      id="cover-button-photo"
                      onChange={addCoverPicture}
                      type="file"
                      style={{ display: "none" }}
                    />
                    <div className="profile-bg">
                      <img
                        src={
                          companyCoverPicture ||
                          (companies
                            ? getPathFromUrl(companies[0]?.image)
                            : null)
                        }
                        height={150}
                        width={"100%"}
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          objectFit: "cover"
                        }}
                        alt="cover pic"
                      />
                      <label htmlFor="cover-button-photo">
                        <span className="edit-profile-bg w-inline-block"></span>
                      </label>
                    </div>
                    <div className="profile-info">
                      <div className="avatar big">
                        <input
                          ref={inputFileRef}
                          accept="image/*"
                          id="pro-button-photo"
                          onChange={addProfilePicture}
                          type="file"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="pro-button-photo">
                          <Avatar
                            alt="Profile picture"
                            src={
                              companyProfilePicture ||
                              (companies
                                ? getPathFromUrl(companies[0]?.profile_image)
                                : null)
                            }
                            sx={{
                              width: 130,
                              height: 130,
                              cursor: "pointer"
                            }}
                          ></Avatar>
                        </label>
                      </div>
                      <div className="profile-details">
                        <h5 className="profile-name">{companyName}</h5>
                        {/* <div>ontheedgecontemporary.com</div> */}
                      </div>
                      {/* <div className="profile-follow">
                        <a href="/#" className="button button-primary w-button">
                          Sync with Google
                        </a>
                      </div> */}
                    </div>
                    <TabContext value={tab}>
                      <div className="w-tabs">
                        <div className="tabs-menu w-tab-menu">
                          <TabList
                            onChange={handleTabChange}
                            aria-label="Company details"
                          >
                            <Tab
                              className="tab-link w-inline-block w-tab-link"
                              label="About"
                              value="1"
                            />
                            {/* <Tab
                              className="tab-link w-inline-block w-tab-link"
                              label="Edit Location"
                              value="2"
                            /> */}
                          </TabList>
                        </div>
                        <div className="tabs-content w-tab-content">
                          <TabPanel value="1">
                            <div>
                              <div className="profile-info-heading">
                                <strong>DESCRIPTION</strong>
                              </div>
                              <p className="paragraph">{companyDescription}</p>
                              <div className="row w-row">
                                <div className="col w-col w-col-6">
                                  <div className="flex-center profile-about-row">
                                    <div className="profile-info-heading">
                                      <strong>Categories</strong>
                                    </div>
                                    <div>{companyCategory?.label || ""}</div>
                                  </div>
                                  <div className="flex-center profile-about-row">
                                    <div className="profile-info-heading">
                                      <strong>Phone</strong>
                                    </div>
                                    <div>{companyPhone}</div>
                                  </div>
                                </div>
                                <div className="col w-col w-col-6">
                                  <div className="flex-center profile-about-row">
                                    <div className="profile-info-heading">
                                      <strong>LOCATED IN</strong>
                                    </div>
                                    <div>{locatedIn}</div>
                                  </div>
                                  <div className="flex-center profile-about-row">
                                    <div className="profile-info-heading">
                                      <strong>Location Address</strong>
                                    </div>
                                    <div>{locatedAdress}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="divider"></div>
                              <div className="profile-info-heading">
                                <strong>Owner or Manager</strong>
                              </div>
                              <p>{firstName + " " + lastName}</p>
                              <div className="profile-info-heading">
                                <strong>Email</strong>
                              </div>
                              <p>
                                <a
                                  href={`mailto:${email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {email}
                                </a>
                              </p>
                              <div className="divider transparent"></div>
                            </div>
                          </TabPanel>
                          <TabPanel value="2">
                            <div>
                              <div className="w-form">
                                <LocationEdit
                                  company={companies && companies[0]}
                                  location={0}
                                />
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </div>
                    </TabContext>
                  </div>
                </div>
                <div className="col w-col w-col-3">
                  <div className="section-inner">
                    <Link href="https://www.heartland.us/solutions/in-your-store">
                      <img src={HeartlandLogo} />
                    </Link>
                  </div>
                </div>
                {/* <div className="col w-col w-col-4">
                  <div className="section-inner">
                    <div className="card">
                      <div className="avatar block-center"></div>
                      <a
                        href="https://share.localight.com/"
                        className="button button-block w-button"
                      >
                        Manage Social Media
                      </a>
                      <div className="profile-social text-center">
                        <span className="social-link fb w-inline-block"></span>
                        <span className="social-link twitter w-inline-block">
                          {" "}
                        </span>
                        <span className="social-link insta w-inline-block">
                          {" "}
                        </span>
                      </div>
                      <div className="profile-card-columns">
                        <div className="row w-row">
                          <div className="col col-border-right text-center w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <div className="profile-card-col">
                              <div className="profile-card-col-number">
                                2012
                              </div>
                              <div className="hint">Following</div>
                            </div>
                          </div>
                          <div className="col text-center col-border-right w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <div className="profile-card-col">
                              <div className="profile-card-col-number">
                                20910
                              </div>
                              <div className="hint">Followers</div>
                            </div>
                          </div>
                          <div className="col text-center w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <div className="profile-card-col">
                              <div className="profile-card-col-number">32</div>
                              <div className="hint">Posts</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-inner">
                    <div className="card no-padding">
                      <div className="post-author">
                        <div className="google-update-icon">
                          <img
                            src="/static/icons8-google.svg"
                            width="50"
                            alt=""
                            className="google-my-business"
                          />
                        </div>
                        <div>
                          <div className="text-heading-grey">
                            Google Search and Maps
                          </div>
                          <div className="hint">Updated 12 days ago</div>
                        </div>
                      </div>
                      <div className="post-content">
                        <div className="profile-info-heading">
                          <strong>Previous Update</strong>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique.{" "}
                        </p>
                      </div>
                      <div className="post-feedback">
                        <div className="post-feedback-item">
                          <img
                            src="/static/icons8-facebook-like-100-1_1icons8-facebook-like-100-1.png"
                            width="25"
                            alt=""
                            className="post-feedback-icon"
                          />
                          <div className="hint">562</div>
                        </div>
                        <div className="post-feedback-item">
                          <img
                            src="/static/icons8-comments-100-1_1icons8-comments-100-1.png"
                            width="25"
                            alt=""
                            className="post-feedback-icon"
                          />
                          <div className="hint">6</div>
                        </div>
                        <div className="post-feedback-item">
                          <img
                            src="/static/icons8-share-100-1_1icons8-share-100-1.png"
                            width="25"
                            alt=""
                            className="post-feedback-icon"
                          />
                          <div className="hint">12</div>
                        </div>
                      </div>
                      <div className="post-form">
                        <div className="w-form">
                          <form
                            id="wf-form-profile"
                            name="wf-form-profile"
                            data-name="profile"
                          >
                            <textarea
                              placeholder="Post a new Google update"
                              maxLength="5000"
                              id="field-5"
                              name="field-5"
                              className="textarea w-input"
                            ></textarea>
                            <input
                              type="submit"
                              value="Post"
                              data-wait="Please wait..."
                              className="button button-primary w-button"
                            />
                          </form>
                          <div className="success-message w-form-done">
                            <div>Saved!</div>
                          </div>
                          <div className="error-message w-form-fail">
                            <div>
                              Oops! Something went wrong while submitting the
                              form.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* <div className="section">
              <div className="row w-row">
                <div className="col w-col w-col-6">
                  <div className="card">
                    <h4 className="card-heading-small">
                      Create A Business Verified Customer
                    </h4>
                    <VerifiedCustomer />
                  </div>
                </div>
                <div className="col w-col w-col-6">
                  <div className="card">
                    <div className="card-heading vertical">
                      <h4>Upload A Document</h4>
                    </div>
                    <UploadDocument />
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="section">
              <div className="row w-row">
                <div className="col w-col w-col-6">
                  <div className="card">
                    <div className="card-heading vertical">
                      <h4>Add owners</h4>
                      <div className="heading-text">
                        <p className="hint inline">
                          Due to regulatory requrements, we need to indentify
                          who owns 25% or more of the organization.
                          <em>
                            <br />
                          </em>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col w-col w-col-6"></div>
              </div>
            </div> */}
          </div>
          {/* <div className="footer">
            <div className="w-row">
              <div className="w-col w-col-6">
                <div className="hint">
                  © Copyright Localight Inc. All rights reserved.
                </div>
              </div>
              <div className="footer-right w-col w-col-6">
                <div className="hint">
                  <Link underline="none" href="#" className="footer-link">
                    Spotlight Business
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          <LocationAddDialog
            open={openLocationModal}
            handleClose={handleCloseModal}
          />
        </div>
      </div>
    </>
  )
}

export default LocationProfileContainer
