import React, { Component, useEffect, PureComponent } from "react"
import Link from "@mui/material/Link"
import Avatar from "@mui/material/Avatar"
import AvatarGroup from "@mui/material/AvatarGroup"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import moment from "moment"
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector, AreaChart, Area } from 'recharts';
import { GET_TRANSACTIONS } from "../../store/payment/constants"
import RangeSlider from './dashboardFunctions/range_slider.js'
import { graph, graph_total } from "./dashboardFunctions/graphs.js"
import { date_calc } from "./dashboardFunctions/date_calc.js"
import Chatbot from "../../components/Chatbot/index"

let x = 0
let y = 0

function put(key, value) {
  this.container[key] = value;
}
/*
function date_calc(){
  let week_total = 0
  let day_total = 0
  let month_total = 0
  const tdata = require('./sampdata.json');

  let num = 0

  tdata.forEach(item =>   
    {
      let in_date = item['created_on']
      console.log("num is: "  + num)
      let year = in_date.substring(0, 4 ) 
      let month = in_date.substring(5,7)
      let day = in_date.substring(8,10)
      
      console.log(in_date)
      console.log(year, month, day)
      console.log(month+'/'+day+'/'+year)


      var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      let year2 = utc.substring(0,4)
      utc = utc.substring(5,10) + "/" + year2
      console.log('utc: '+utc)
      dayOfYear1(new Date(month+'/'+day+'/'+year))

      
      dayOfYear2(new Date(utc))
      
      
      let difference = x - y
      
      

      //negative means the date happened before also resets every year
      if (year == year2){
        if (difference >= -7 && difference <=0){
          console.log('In the week')
          week_total = parseInt(tdata[num]['amount']) + week_total

        }
        if(difference >= -30 && difference <=0){
          console.log('In the month')
          month_total = parseInt(tdata[num]['amount']) + month_total
        }
        if(difference >=0 && difference <=0){
          console.log('In the Year')
          day_total = parseInt(tdata[num]['amount']) + day_total
        }
      }
      console.log("week = " + week_total)
      console.log("month = " + month_total)
      console.log("day = " + day_total)
      week = ("$" + week_total + ".00")
      monthly = ("$" + month_total + ".00")
      daily = ("$" + day_total + ".00")
      
      console.log(difference)

      console.log(x)
      console.log(y)
    num = num + 1
    })
}
*/


const dayOfYear1 = date => { x = (Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24)) }


const dayOfYear2 = date => { y = (Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24)) }

let crapfix = 0


function crapfixer() {
  crapfix = 1
}

const DashboardContainer = props => {


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: GET_TRANSACTIONS })
    console.log("getting transactions")
  }, [])
  const transactions = useSelector(state => state.paymentReducer.transactions)
  let alist = [['K', 'A', 'M', 'S', 'I', 'B', 'A'], ['Kevin M.', 'Angie D.', 'Maryam M.', 'Sue M.', 'Ian B.', 'Brenda W.', 'Adrian D.'], ['4:30pm, Sept 4', '4:29pm, Sept 4', '4:15pm, Sept 4', '3:49pm, Sept 4', '3:36pm, Sept 4', '3:30pm, Sept 4', '3:19pm, Sept 4'], ['$147.23', '$67.64', '$82.91', '$47.33', '$25.32', '$96.44', '$84.77'], [], [], []]
  let graph_data = require('./sample-graph-data.json');



  let prate = graph_data[0]['prate']
  let vdur = graph_data[0]['vdur']

  //sample data from production build




  let area_graph1 = graph('total_visiting', graph_data)
  let area_graph2 = graph('purchase_rate', graph_data)
  let area_graph3 = graph('visit_duration', graph_data)

  let graph_total1 = graph_total('total_visiting', graph_data)
  let graph_total2 = graph_total('purchase_rate', graph_data)
  let graph_total3 = graph_total('visit_duration', graph_data)

  let m = date_calc(graph_data)
  //console.log(m)
  let week = m[1]
  let daily = m[0]
  let monthly = m[2]



  /*
    const piedata=[
    {
      name:'Percentage of returning customers',
      Value: 50
    }
  
    ]
    const bardata = [
      {
        name: 'Jan',
        Total: 4000,
        Unique: 1500,
        amt: 2400,
      },
      {
        name: 'Feb',
        Total: 3000,
        Unique: 1398,
        amt: 2210,
      },
      {
        name: 'March',
        Total: 2000,
        Unique: 1500,
        amt: 2290,
      },
      {
        name: 'April',
        Total: 2780,
        Unique: 3908,
        amt: 2000,
      },
      {
        name: 'May',
        Total: 1890,
        Unique: 1200,
        amt: 2181,
      },
      {
        name: 'Jun',
        Total: 2390,
        Unique: 2000,
        amt: 2500,
      },
      {
        name: 'July',
        Total: 3490,
        Unique: 3300,
        amt: 2100,
      },
    ];
  
    
    const data = [
      { name: 'Group A', value: 400 },
      { name: 'Group B', value: 300 },
      { name: 'Group C', value: 300 },
    ];
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    
    let demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';
  
  
    useEffect(() => {
      setInterval(() => dispatch({ type: GET_TRANSACTIONS }), 10000)
    }, [dispatch])
  */
  const navigateToCustomers = () => {
    dispatch(push(`/dashboard/customers`))
  }

  const displayName = (customer_name) => {
    var stringArray = customer_name.split(/\s+/)
    var firstName = stringArray[0]
    console.log("stringArray", stringArray)
    console.log("firstName", firstName)
    if (stringArray.length >= 2) {
      var lastName = stringArray[1]
      console.log("lastName", lastName)
      return firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase() + "."
    } else {
      return firstName.charAt(0).toUpperCase() + "."
    }
  }
  //console.log(JSON.stringify(transactions?.data) + "maintransaction----------------")

  return (
    <>
      <script type="text/javascript" src="https://unpkg.com/react@latest/dist/react.js"></script>
      <script type="text/javascript" src="http://fb.me/JSXTransformer-0.13.3.js"></script>
      <script type="text/javascript" src="https://unpkg.com/react@15.6.0/dist/react.min.js"></script>
      <script type="text/javascript" src="https://unpkg.com/react-dom@15/dist/react-dom.min.js"></script>
      <script type="text/javascript" src="http://unpkg.com/fusioncharts/fusioncharts.js"></script>
      <script type="text/javascript" src="http://unpkg.com/fusioncharts/fusioncharts.charts.js"></script>
      <script type="text/javascript" src="http://unpkg.com/fusioncharts@3.12.1/themes/fusioncharts.theme.ocean.js"></script>
      <script type="text/javascript" src="http://unpkg.com/react-fusioncharts/dist/react-fusioncharts.js"></script>
      <div className="app-main-layout">
        <Chatbot />
        <div className="app-main-layout-header">
          <div className="app-main-layout-wrapper header">
            <div className="page-heading">
              <h2 className="heading-3">Dashboard</h2>
            </div>
            <div className="page-tabs-menu">
              <div
                aria-current="page"
                className="page-tab-link traffic w--current"
              >
                Overview
              </div>
              {/* <div className="page-tab-link revenue">Purchases</div> */}
              {/* <div className="page-tab-link projects">Offers</div> */}
            </div>
          </div>
        </div>
        <div className="app-main-layout-wrapper">
          <div className="app-main-layout-content">
            <div className="page-tabs-content">
              <div className="section">
                <div className="section-inner">
                  {transactions && !!transactions?.data?.length && (
                    <div className="card no-padding receipt-table">
                      <div className="receipt-row">
                        <div className="card-setting-text caption" style={{ paddingLeft: '3%', flex: '2' }}>
                          Recent Customers
                        </div>
                        <div className="card-setting-text caption">
                          Time &amp; Date
                        </div>
                        <div className="card-setting-text caption" style={{ justifyContent: 'right' }}>Spent</div>
                        <div className="card-setting-text caption" style={{ justifyContent: 'right', flex: '1.5', paddingRight: '3%' }}>Loyalty Level</div>
                        {/* <div className="card-setting-switcher">
                          <div className="receipt-download hidden"></div>
                        </div> */}
                      </div>

                      {transactions?.data?.map(item => (
                        <div className="receipt-row">
                          {console.log('current transaction', item)}
                          <div className="card-setting-text" style={{ paddingLeft: '3%', flex: '2' }}>
                            <div className="customer-photo-container">
                              <Avatar>
                                {item?.customer_name.substring(0, 1)}
                              </Avatar>
                              {/* <div className="online-status-2 on"></div> */}
                            </div>
                            <div style={{ paddingTop: '10px' }}>
                              {displayName(item?.customer_name)}
                            </div>
                          </div>
                          <div className="card-setting-text">
                            {moment(item?.created_on).format("LT, MMM D")}
                          </div>
                          <div className="card-setting-text primary-text" style={{ justifyContent: 'right' }}>
                            ${parseFloat(item?.amount).toFixed(2)}
                          </div>
                          <div className="card-setting-text primary-text" style={{ justifyContent: 'right', flex: '1.5', paddingRight: '3%' }}>
                            {item?.localcash_back_percent ? parseFloat(item?.localcash_back_percent) * 100 + '%' : ''}
                          </div>
                        </div>
                      ))}
                      {/* We will bring back the Customers page when we update it to what we want */}
                      {/* <div
                        className="receipt-row"
                        onClick={navigateToCustomers}
                      >
                        <AvatarGroup total={transactions?.data?.length || 0}>
                          {transactions?.data?.map(item => (
                            <Avatar>
                              {item.customer_name.substring(0, 1)}
                            </Avatar>
                          ))}
                        </AvatarGroup>
                      </div> */}
                    </div>
                  )}
                  {/*Sample Transaction data*/}
                  {transactions?.data?.length === 0 && (

                    <div>
                      <div className='card-setting-title-text'>*Sample Transaction Data</div>
                      <div className="card no-padding receipt-table">

                        <div className="receipt-row">

                          <div className="card-setting-text caption" style={{ paddingLeft: '3%', flex: '2' }}>
                            Recent Customers
                          </div>
                          <div className="card-setting-text caption">
                            Time &amp; Date
                          </div>
                          <div className="card-setting-text caption" style={{ justifyContent: 'right' }}>Spent</div>
                          <div className="card-setting-text caption" style={{ justifyContent: 'right', flex: '1.5', paddingRight: '3%' }}>Loyalty Level</div>
                        </div>
                      </div>
                      {
                        alist.map(function (object, i) {
                          {/*The customers are displayed by using a table with present variables and a function that labels the entire array. */ }
                          return (
                            <div className="card no-padding receipt-table">
                              <div className="receipt-row">
                                <div className="card-setting-text" style={{ paddingLeft: '3%', flex: '2' }}>
                                  <div className="customer-photo-container">
                                    <Avatar>
                                      {alist[0][i]}
                                    </Avatar>
                                  </div>
                                  <div style={{ paddingTop: '10px' }}>
                                    {alist[1][i]}
                                  </div>
                                </div>
                                <div className="card-setting-text">
                                  {alist[2][i]}
                                </div>
                                <div className="card-setting-text primary-text" style={{ justifyContent: 'right' }}>
                                  {alist[3][i]}
                                </div>
                                <div className="card-setting-text primary-text" style={{ justifyContent: 'right', flex: '1.5', paddingRight: '3%' }}>
                                  2%
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {transactions?.status === "L" && (
                    <div className="card no-padding receipt-table">
                      <div className="receipt-row">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* We will bring back this section in the future, with correct calculations, instead of "$0.00" */}
              {/* <div className="section">
                <div
                  className="flex row w-row"
                  style={{ position: "relative" , paddingTop:'10px'}}
                >
                  <div className="col w-col w-col-4">
                    <div className="card text-center">
                      <div className="card-setting-graph-text">Daily Purchases</div>
                      
                      <div className="hint-bold-big">{daily}</div>
                    </div>
                    
                  </div>
                  <div className="col w-col w-col-4">
                    <div className="card text-center">
                      <div className="card-setting-graph-text">Last 7 Days</div>
                      <div className="hint-bold-big">{week}</div>
                    </div>
                    
                  </div>

                  <div className="col w-col w-col-4">
                    <div className="card text-center">
                      <div className="card-setting-graph-text">Last 30 Days</div>
                      <div className="hint-bold-big">{monthly}</div>
                    </div>
                    
                  </div>
                  
                </div>
              </div> */}


              {/*
            <div className='section'>
              <RangeSlider/>
                </div>*/}
              {/*
              <div className="section">
              <div className="card no-padding" style = {{backgroundColor:""}}>
                <div style = {{display:'flex'}}>
                    
                  <div className="">
                    <div className="card-setting-text charts">Visitor Overview</div>
                    
                    <BarChart
                      width={500}
                      height={300}
                      data={bardata}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Total" fill="#8884d8" />
                      <Bar dataKey="Unique" fill="#82ca9d" />
                    </BarChart>
                      
                  </div>
                  <div style={{left:'100px'}} className="" >
                <div style={{left:'100px', backgroundColor:'white'}}>
                  <div className="card-setting-text charts">Loyalty</div>
               The first pie chart that is showing the different customer populations... dont know how we should properly implment this 
                    <PieChart width={400} height={240}>
                      <Pie
                        data={data}
                        cx={200}
                        cy={90}
                        innerRadius={50}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <legend></legend>
                    </PieChart>
                    <div style={{textAlign:'center'}}>
                    <div className='dotcolor1'>
                      Returning Customers
                    </div>
                    <div className='dotcolor2'>
                      New Customers
                    </div>
                    <div className='dotcolor3'>
                      Just Visiting
                    </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              
              </div>
*/}

              <div className="section">
                {/* 
              <div className="card no-padding receipt-table">
                
                <div style = {{display:'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom:'30px', paddingLeft:'20px', alignContent:'stretch'}}>
                    
                  <div style={{paddingTop:'30px'}} >
                   
                  <div className="card-setting-graph-text">Total Visits</div>
                  <div className="hint-bold-big">{graph_total1}</div>
                      <div className="hint-bold-small">per</div>
                    
                  <div style={{objectFit: 'cover'}} className="" >
                    <div className="card-setting-text charts"></div>
                   
                    <AreaChart width={285} height={100} data={area_graph1}
                      margin={{ top: 10, bottom: 0 }}>
                      <defs>
                      <YAxis axisLine={false}/>
                      <XAxis axisLine={false} />
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#2384d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#2384d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      
                    
                      <Tooltip />
                      <Area type="monotone" dataKey="uv" stroke="#2384d8" fillOpacity={1} fill="url(#colorUv)" />
                      <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                    </AreaChart>
                    </div>  
                  </div>
                  <div style = {{objectFit: 'cover', marginLeft:'20px', paddingTop:'30px', paddingBottom:'30px'}}>
                  <div className="card-setting-graph-text">Purchase Rate</div>
                  <div className="hint-bold-big">{prate}</div>
                      <div className="hint-bold-small">per</div>
                    <div className="card-setting-text charts"></div>
                   
                    <AreaChart width={285} height={100} data={area_graph2}
                      margin={{ top: 10, bottom: 0 }}>
                      <defs>
                      <YAxis axisLine={false}/>
                      <XAxis axisLine={false} />
                        <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#f44336" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#f44336" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#f44336" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#f44336" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      
                      
                      <Tooltip />
                      <Area type="monotone" dataKey="uv" stroke="#f44336" fillOpacity={1} fill="url(#colorUv2)" />
                      <Area type="monotone" dataKey="pv" stroke="#f44336" fillOpacity={1} fill="url(#colorPv2)" />
                    </AreaChart>
                      
                  </div>
                  <div style = {{objectFit: 'cover', marginLeft:'20px', paddingRight:'20px', paddingTop:'30px', paddingBottom:'30px'}}>
                  <div className="card-setting-graph-text">Visit Duration</div>
                  <div className="hint-bold-big">{vdur}</div>
                      <div className="hint-bold-small">per</div>
                    <div className="card-setting-text charts"></div>
                   
                    <AreaChart width={285} height={100} data={area_graph3}
                      margin={{ top: 10, bottom: 0 }}>
                      <defs>
                      <YAxis axisLine={false}/>
                      <XAxis axisLine={false} />
                        <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8824d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#4884d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv3" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      
                      
                      <Tooltip />
                      <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv3)" />
                      <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv3)" />
                    </AreaChart>
                      
                  </div>
                  
                </div>
              </div>

              */}

              </div>

              {/* We removed the analytics graphs section for now */}
              {/* <div className="section">
                <div
                  className="flex row w-row"
                  style={{ position: "relative" }}
                >
                  
  	
                  <div
                    style={{
                      flex: 1,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(255,255,255,0.8)",
                      zIndex: 10,
                      justifyContent: "center",
                      textAlign: "center",
                      lineHeight: "100%",
                      padding: "175px 0",
                      fontSize: "15pt"
                    }}
                  >
                    Analytics will be diplayed once you generated transactions
                    from 100 customers.
                  </div>
                  <div className="col w-col w-col-4">
                    <div className="card text-center">
                      <div className="card-heading center">
                        <div className="caption mb-0">Your loyalty rate</div>
                      </div>
                      <div className="progress-circle-container text-center">
                        <img
                          src="/static/Chart-Progress.svg"
                          width="200"
                          alt=""
                        />
                        <div className="budget-circle-text">12%</div>
                      </div>
                      <div className="data-status up">
                        <img
                          src="/static/icons8-low-priority-100-1_1icons8-low-priority-100-1.png"
                          width="20"
                          alt=""
                          className="data-status-icon"
                        />
                        <div className="data-status-text">+4.32%</div>
                      </div>
                      <div className="hint">from the last month</div>
                    </div>
                  </div>
                  <div className="col w-col w-col-8">
                    <div className="card">
                      <div className="card-heading">
                        <h4 className="mb-0">New Customer Channels</h4>
                      </div>
                      <div className="table">
                        <div className="row table-row table-header w-row">
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>Channel</div>
                          </div>
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>Sessions</div>
                          </div>
                          <div className="col table-col w-col w-col-6 w-col-small-4 w-col-tiny-4">
                            <div>Ratio</div>
                          </div>
                        </div>
                        <div className="row table-row w-row">
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>Joined at your business</div>
                          </div>
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>43,345</div>
                          </div>
                          <div className="col table-col w-col w-col-6 w-col-small-4 w-col-tiny-4">
                            <div className="progress-outer">
                              <div className="progress-inner _40"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row table-row w-row">
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>Other business referrals</div>
                          </div>
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>51,293</div>
                          </div>
                          <div className="col table-col w-col w-col-6 w-col-small-4 w-col-tiny-4">
                            <div className="progress-outer">
                              <div className="progress-inner _50"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row table-row last w-row">
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>Nonprofit supporters</div>
                          </div>
                          <div className="col table-col w-col w-col-3 w-col-small-4 w-col-tiny-4">
                            <div>14,022</div>
                          </div>
                          <div className="col table-col w-col w-col-6 w-col-small-4 w-col-tiny-4">
                            <div className="progress-outer">
                              <div className="progress-inner _15"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> 
              <div>
                *sample data
              </div> */}
            </div>
          </div>
          <div className="footer">
            <div className="w-row">
              <div className="w-col w-col-6">
                <div className="hint">
                  © Copyright Localight Inc. All rights reserved.
                </div>
              </div>
              <div className="footer-right w-col w-col-6">
                <div className="hint">
                  <Link underline="none" href="#" className="footer-link">
                    Spotlight Business
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardContainer
