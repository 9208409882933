
import React, { useState } from 'react';
import Link from '@mui/material/Link';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
import { AuthApiServices } from "../../store/auth/services";
import { useForm } from "react-hook-form";
// import { push } from 'connected-react-router';
// import { useDispatch } from "react-redux";

const PasswordResetContainer = (props) => {
    const [tokenSent, setTokenSent] = useState(false);
    const [found, setFound] = useState(null);

    const { handleSubmit, register, formState: { errors }  } = useForm();

    const submitResetPassword = async (data) => {
      const result = await AuthApiServices.doPwdReset({email: data.email});
      console.log(result, 'res');
      if(result?.status === "OK") setTokenSent(true);
      else setFound('false');
    }

    return <>
        <div className="single-layout-right">
          <div className="single-layout-right-content">
            <div className="single-layout-logo-right">
              <Link href="/" underline="none" className="w-inline-block"><img src="/static/Localight-Logo-reverse.svg" width="150" alt=""/></Link>
            </div>
            <h3 className="text-center">Password Reset</h3>
            <p className="mb-40">Enter your <strong>email address</strong> that you used to register. We&#x27;ll send you an email with a token for reset password.</p>
            <div className="w-form">
            {tokenSent ? 
                <>
                  <h5 className="text-center">Please check your email for the reset link.</h5>
                </>
                 :
                 <form id="passwordreset-form" name="passwordreset-form" onSubmit={handleSubmit(submitResetPassword)}>
                  <label htmlFor="email" className="label"><strong>What&#x27;s your email?</strong></label>
                  <input 
                      type="email"  
                      aria-invalid={errors.email ? "true" : "false"}
                      {...register("email", {
                        required: "required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format"
                        }
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="email"  
                      placeholder="Enter your email" 
                      id="email"
                      />
                      {(errors.email || found === 'false') &&
                      <div className="error-message">
                        <div>{errors.email.message}</div>
                        <div>{found === 'false' && "Email is not registered."}</div>
                      </div>
                    }
                  <button type="submit" className="button button-primary button-block w-button">Reset password</button>
                </form>
                }
            </div>
          </div>
        </div>
    </>
};

export default PasswordResetContainer;
