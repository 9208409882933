import React from 'react';
// import Button from '@mui/material/Button';

import { useForm } from "react-hook-form";

const LocationEdit = ({ company, location }) => {

  const { handleSubmit, register, formState: { errors }  } = useForm({
    defaultValues: {
      location_name: company.locations[location].location_name,
      company_locatedin: company.locations[location].located_in,
      company_address: company.locations[location].complete_address,
      location_description: company.locations[location].bio_description
    }
  });


  const submitCompanyLocation = async (data) => {

  }


  return (
    <form id="location-form" name="location-form" onSubmit={handleSubmit(submitCompanyLocation)}>
            <div className="w-form">
                <label htmlFor="location_name" className="label">Location Name</label>
                    <input 
                      type="text"  
                      aria-invalid={errors.location_name ? "true" : "false"}
                      {...register("location_name", {
                        required: "Company city is required.",
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="location_name"  
                      placeholder="Location name" 
                      id="location_name"
                      />
                      {errors.location_name &&
                      <div className="error-message">
                        <div>{errors.location_name.message}</div>
                      </div>
                      }
                    <label htmlFor="company_locatedin" className="label">Located in</label>
                    <input 
                      type="text"  
                      aria-invalid={errors.company_locatedin ? "true" : "false"}
                      {...register("company_locatedin", {
                        required: "Company location is required.",
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="company_locatedin"  
                      placeholder="Located in" 
                      id="company_locatedin"
                      />
                      {errors.company_locatedin &&
                      <div className="error-message">
                        <div>{errors.company_locatedin.message}</div>
                      </div>
                      }
                    <label htmlFor="company_address" className="label">Complete Address</label>
                    <input 
                      type="text"  
                      aria-invalid={errors.company_address ? "true" : "false"}
                      {...register("company_address", {
                        required: "Company address is required.",
                      })}
                      className="input w-input" 
                      maxLength="256" 
                      name="company_address"  
                      placeholder="Complete Address" 
                      id="company_address"
                      />
                      {errors.company_address &&
                      <div className="error-message">
                        <div>{errors.company_address.message}</div>
                      </div>
                      }
                
                    <label htmlFor="location_description" className="label">Location description</label>
                    <textarea 
                      aria-invalid={errors.location_description ? "true" : "false"}
                      {...register("location_description", {
                        required: "Location description is required.",
                      })}
                      className="textarea w-input" 
                      maxLength="5000" 
                      name="location_description"  
                      placeholder="Enter Location details" 
                      id="location_description"
                      />
                      {errors.location_description &&
                      <div className="error-message">
                        <div>{errors.location_description.message}</div>
                      </div>
                      }

                    <div className="text-center">
                        <button type="submit" className="button button-primary mt-20 button-block w-button">Update</button>
                    </div>
              </div>
        </form>
  );
}

export default LocationEdit;