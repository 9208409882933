
import React from "react";
import { makeStyles } from '@mui/styles';
import Carousel from "../Carousel";
import FeaturedOfferCards from "../OfferCards/Featured";


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 2,
    height: 190,
    '&.slick-slide:not(.slick-center)': {
      transform: 'scale(0.85)', 
    },
  },
}));



const FeaturedOfferSlider = ({ offers=[1,2,3,4,5,6,7,8,9,10,11,12], navigateBusiness }) => {
  const classes = useStyles();

  const offersLength = parseInt(offers?.length);

  const settings = {
    className: classes.root,
    dots: false,
    arrows: false,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    variableWidth: true,
    centerPadding: "30px",
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: offersLength <= 7 ? offersLength : 7,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: offersLength <= 5 ? offersLength : 5,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: offersLength <= 2 ? offersLength : 2,
        },
      },
    ],
  };

  return (
    <Carousel settings={settings}>
      {offers?.map((item, id) => {
        return (
          <div key={id} style={{ width: 165 }}>
             <FeaturedOfferCards carousel offer={item} navigateBusiness={navigateBusiness} />
          </div>
        );
      })}
    </Carousel>
  );
};

export default FeaturedOfferSlider;
