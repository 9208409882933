import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import DashboardContainer from '../../containers/DashboardContainer';

const Dashboard = (props) => {


    return <DashboardLayout>
        <DashboardContainer {...props} />
    </DashboardLayout>
};

export default Dashboard;
