import React from "react"
import Link from "@mui/material/Link"
// import Box from '@mui/material/Box';
import {
  useDispatch
  // useSelector
} from "react-redux"
import { useForm } from "react-hook-form"
import { CHANGE_PASSWORD } from "../../store/auth/constants"
import { request } from "../../utils/http"
import Chatbot from "../../components/Chatbot/index"


const MyAccountsContainer = props => {
  // const categories = useSelector(state => state.companyReducer.categories.data);

  const dispatch = useDispatch()

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm()
  let pwd = watch("new_password1")

  const submitNewPassowrd = async data => {
    dispatch({
      type: CHANGE_PASSWORD,
      oldPassword: data.current_password,
      newPassword: data.new_password1,
      confirmPassword: data.new_password2
    })
  }

  const saveCompanySettings = data => {
    const payload = {
      average_transaction_value: data.atv
    }
    console.log(data)
    //request.patch()
  }

  return (
    <>
      <div className="app-main-layout">        
        <Chatbot/>
        <div className="app-main-layout-header">
          <div className="app-main-layout-wrapper header">
            <div className="page-heading">
              <h2 className="heading-3">Account Settings</h2>
            </div>
            <div className="page-tabs-menu">
              <div
                aria-current="page"
                className="page-tab-link general-settings w--current"
              >
                General
              </div>
            </div>
          </div>
        </div>
        <div className="app-main-layout-wrapper">
          <div className="app-main-layout-content">
            <div className="section">
              <div className="row w-row">
                <div className="col w-col w-col-6">
                  <div className="card">
                    <h4 className="card-heading-small">Company settings</h4>
                    <div className="input-group">
                      <div className="input-group-item-left">
                        <form
                          
                          
                          onSubmit={handleSubmit(saveCompanySettings)}
                        >
                          <div className="form-group">
                            <label htmlFor="atv" className="label">
                              Average Transaction Value
                            </label>
                            <input
                              
                              aria-invalid={errors.atv ? "true" : "false"}
                              {...register("atv", {
                                required: "Current Password is required.",
                                minLength: {
                                  value: 8,
                                  message: "min length is 5"
                                }
                              })}
                              className="input w-input"
                              maxLength="256"
                              name="atv"
                              placeholder="Enter your ATV"
                              id="atv"
                            />
                            {errors.atv && (
                              <div className="error-message">
                                <div>{errors.atv.message}</div>
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="upsell" className="label">
                              Upsell Target
                            </label>
                            <input
                              className="input w-input"
                              maxLength="256"
                              name="upsell"
                              placeholder="Enter your Upsell Target"
                              id="upsell"
                            />
                            {errors.upsell && (
                              <div className="error-message">
                                <div>{errors.upsell.message}</div>
                              </div>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="button button-primary w-button"
                          >
                            Update Settings
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col w-col w-col-6">
                  <div className="card">
                    <div className="card-heading vertical">
                      <h4>Change password</h4>
                      <div className="heading-text">
                        <p className="hint inline">
                          Forgot your password?{" "}
                          <em>
                            <br />
                          </em>
                        </p>
                        <Link
                          underline="none"
                          href="/forgot-password"
                          className="hint modal-action"
                        >
                          Reset password
                        </Link>
                      </div>
                    </div>
                    <div className="w-form">
                      <form
                        id="password-form"
                        name="password-form"
                        data-name="password Form"
                        onSubmit={handleSubmit(submitNewPassowrd)}
                      >
                        <div className="form-group">
                          <label htmlFor="current_password" className="label">
                            Current password
                          </label>
                          <input
                            type="password"
                            aria-invalid={
                              errors.current_password ? "true" : "false"
                            }
                            {...register("current_password", {
                              required: "Current Password is required.",
                              minLength: {
                                value: 8,
                                message: "min length is 5"
                              }
                            })}
                            className="input w-input"
                            maxLength="256"
                            name="current_password"
                            placeholder="Enter your current password"
                            id="current_password"
                          />
                          {errors.current_password && (
                            <div className="error-message">
                              <div>{errors.current_password.message}</div>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="new_password1" className="label">
                            New password
                          </label>
                          <input
                            type="password"
                            aria-invalid={
                              errors.new_password1 ? "true" : "false"
                            }
                            {...register("new_password1", {
                              required: "New password is required.",
                              minLength: {
                                value: 8,
                                message: "min length is 5"
                              }
                            })}
                            className="input w-input"
                            maxLength="256"
                            name="new_password1"
                            placeholder="Enter your new password"
                            id="new_password1"
                          />
                          {errors.new_password1 && (
                            <div className="error-message">
                              <div>{errors.new_password1.message}</div>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="new_password2" className="label">
                            Repeat password
                          </label>
                          <input
                            type="password"
                            aria-invalid={
                              errors.new_password2 ? "true" : "false"
                            }
                            {...register("new_password2", {
                              required: "Repeat password is required.",
                              minLength: {
                                value: 8,
                                message: "min length is 5"
                              },
                              validate: value =>
                                value === pwd || "The passwords do not match"
                            })}
                            className="input w-input"
                            maxLength="256"
                            name="new_password2"
                            placeholder="Repeat your new password"
                            id="new_password2"
                          />
                          {errors.new_password2 && (
                            <div className="error-message">
                              <div>{errors.new_password2.message}</div>
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="button button-primary w-button"
                        >
                          Update password
                        </button>
                      </form>
                      <div className="success-message w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="error-message w-form-fail">
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="w-row">
              <div className="w-col w-col-6">
                <div className="hint">
                  © Copyright Localight Inc. All rights reserved.
                </div>
              </div>
              <div className="footer-right w-col w-col-6">
                <div className="hint">
                  <Link underline="none" href="#" className="footer-link">
                    Spotlight Business
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyAccountsContainer
