import authReducer from "./auth/reducers"
import companyReducer from "./company/reducers"
import paymentReducer from "./payment/reducers"
import customerReducer from "./customer/reducers"
import AppSagas from "./sagas"
// import authRootSaga from './auth/sagas';

import { combineReducers, createStore, applyMiddleware, compose } from "redux"
import { connectRouter, routerMiddleware } from "connected-react-router"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer, createTransform } from "redux-persist"
import storage from "redux-persist/lib/storage"
import omit from "lodash/omit"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()

const persistConfig = {
  key: "localight",
  storage
}

const blacklistPaths = [
  "errorMessage",
  "login.errorMessage",
  "register.errorMessage",
  "pwdReset.errorMessage"
]

const authPersistConfig = {
  key: "authReducerlc",
  storage,
  blacklist: blacklistPaths.filter(a => !a.includes(".")),
  transforms: [
    // nested blacklist-paths require a custom transform to be applied
    createTransform((inboundState, key) => {
      const blacklistPaths_forKey = blacklistPaths
        .filter(path => path.startsWith(`${key}.`))
        .map(path => path.substr(key.length + 1))
      return omit(inboundState, ...blacklistPaths_forKey)
    }, null)
  ]
}

const blacklistPaymentPaths = [
  "errorMessage",
  "banks",
  "lastTransaction",
  "transactions",
  "plaidLink.data"
]

const paymentPersistConfig = {
  key: "paymentReducerlc",
  storage,
  blacklist: blacklistPaymentPaths.filter(a => !a.includes(".")),
  transforms: [
    // nested blacklist-paths require a custom transform to be applied
    createTransform((inboundState, key) => {
      const blacklistPaymentPaths_forKey = blacklistPaymentPaths
        .filter(path => path.startsWith(`${key}.`))
        .map(path => path.substr(key.length + 1))
      return omit(inboundState, ...blacklistPaymentPaths_forKey)
    }, null)
  ]
}

const sagaMiddleware = createSagaMiddleware()

/**
 * this app uses React Debugger, but it works without it
 */

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middlewares = [
  routerMiddleware(history),
  sagaMiddleware /** more middlewares if any goes here */
]

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    authReducer: persistReducer(authPersistConfig, authReducer),
    companyReducer: companyReducer,
    paymentReducer: persistReducer(paymentPersistConfig, paymentReducer),
    customerReducer: customerReducer
  })

const persistedReducer = history =>
  persistReducer(persistConfig, rootReducer(history))

const store = createStore(
  persistedReducer(history),
  composeEnhancers(applyMiddleware(...middlewares))
)

sagaMiddleware.run(AppSagas)
const persistor = persistStore(store)

export { store, persistor, history }
