import * as types from "./constants";

export const getCustomer = id => ({
    type: types.GET_CUSTOMER, id
  })
  
export const setCustomer = response => ({
type: types.GET_CUSTOMER_SUCCESS, response
})

export const getCustomerFailed = error => ({
type: types.GET_CUSTOMER_FAILED, error
})


export const setCustomerBusiness = id => ({
  type: types.SET_CUSTOMER_BUSINESS, id
})

export const removeCustomerBusiness = () => ({
type: types.REMOVE_CUSTOMER_BUSINESS
})
  


