import * as types from "./constants"
import * as status from "../constants"
import * as StoreUtil from "../storeUtil"

const initialState = {
  token: null,
  user: null,
  userId: null,
  errorMessage: null,
  login: {
    status: ""
  },
  fbLogin: {
    status: ""
  },
  googleLogin: {
    status: ""
  },
  register: {
    status: ""
  },
  pwdReset: {
    status: ""
  },
  pwdChange: {
    status: ""
  }
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DO_OTP_LOGIN:
      return {
        ...state,
        login: {
          status: status.LOADING
        }
      }
    case types.DO_OTP_LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          status: status.SUCCESS
        },
        token: action.response.token,
        user: { customer_id: action.response.customer_id, type: "customer" }
      }
    case types.DO_OTP_LOGIN_FAILED:
      return {
        ...state,
        login: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        },
        token: null,
        user: null,
        userId: null
      }
    case types.DO_LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        login: {
          status: null
        },
        googleLogin: {
          status: null
        },
        register: {
          status: null
        }
      }
    case types.DO_LOGIN:
      return {
        ...state,
        login: {
          status: status.LOADING
        }
      }
    case types.DO_LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        user: null,
        login: {
          status: null
        },
        googleLogin: {
          status: null
        }
      }
    case types.DO_LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          status: status.SUCCESS
        },
        token: action.response.token,
        user: { ...action.response.user, type: "business" }
      }
    case types.DO_LOGIN_FAILED:
      return {
        ...state,
        login: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        },
        token: null,
        user: null,
        userId: null
      }

    case types.DO_FBLOGIN:
      return {
        ...state,
        fbLogin: {
          status: status.LOADING
        }
      }
    case types.DO_FBLOGIN_SUCCEEDED:
      return {
        ...state,
        fbLogin: {
          status: status.SUCCESS
        },
        token: {
          key: action.response.data.token
        },
        user: { ...action.response.user, type: "business" }
      }
    case types.DO_FBLOGIN_FAILED:
      return {
        ...state,
        fbLogin: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        },
        token: null,
        user: null,
        userId: null
      }
    case types.DO_GOOGLELOGIN:
      return {
        ...state,
        googleLogin: {
          status: status.LOADING
        }
      }
    case types.DO_GOOGLELOGIN_SUCCEEDED:
      return {
        ...state,
        googleLogin: {
          status: status.SUCCESS
        },
        token: action.response.token,
        user: { ...action.response.user, type: "business" }
      }
    case types.DO_GOOGLELOGIN_FAILED:
      return {
        ...state,
        googleLogin: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        },
        token: null,
        user: null,
        userId: null
      }
    case types.DO_REGISTER:
      return {
        ...state,
        register: {
          status: status.LOADING
        }
      }
    case types.CHANGE_PROFILE:
      return {
        ...state
      }
    case types.CHANGE_PASSWORD:
      return {
        ...state,
        pwdChange: {
          status: status.SUCCESS,
          time: Date.now()
        }
      }
    case types.DO_REGISTER_SUCCEEDED:
      return {
        ...state,
        register: {
          status: status.SUCCESS,
          errorMessage: null
        },
        userId: action.response.id
      }
    case types.SET_STATUS:
      return {
        ...state,
        register: {
          status: action.status
        }
      }
    case types.DO_REGISTER_FAILED:
      return {
        ...state,
        register: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        },
        token: null,
        user: null,
        userId: null
      }

    case types.DO_PWDRESET:
      return {
        ...state,
        pwdReset: {
          status: status.LOADING,
          errorMessage: null
        }
      }
    case types.DO_PWDRESET_STATUS:
      return {
        ...state,
        pwdReset: {
          status: action.status
        }
      }
    case types.DO_PWDRESET_SUCCEEDED:
      return {
        ...state,
        pwdReset: {
          status: status.SUCCESS,
          errorMessage: null
        }
      }
    case types.DO_PWDRESET_FAILED:
      return {
        ...state,
        pwdReset: {
          status: status.FAILED,
          errorMessage: StoreUtil.getErrorMessage(action)
        }
      }

    default: {
      return state
    }
  }
}

export default authReducer
