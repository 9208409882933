import React from "react";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';

const HeaderContainer = ({ children }) => {

  return (
      <AppBar color="inherit" position="static" 
        sx={{
          backgroundColor: '#152A38',
          boxShadow: "none",
          height: '80px',
        }}
        >
        <Container disableGutters 
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          {children}
        </Container>
      </AppBar>
  );
};

export default HeaderContainer;
