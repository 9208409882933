import React from "react"
import { makeStyles } from "@mui/styles"
import Carousel from "../Carousel"
import OfferCards from "../OfferCards"

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 2,
    height: 160
  }
}))

const OfferSlider = ({
  data,
  offers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  navigateBusiness
}) => {
  const classes = useStyles()

  const offersLength = parseInt(offers?.length)

  const settings = {
    className: classes.root,
    dots: false,
    arrows: false,
    // centerMode: true,
    autoplay: false,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    variableWidth: true,
    centerPadding: "30px",
    slidesToScroll: 3,
    slidesToShow: 3,
    infinite: data.length > 3,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: offersLength <= 7 ? offersLength : 7
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: offersLength <= 5 ? offersLength : 5
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: offersLength <= 2 ? offersLength : 2
        }
      }
    ]
  }

  return (
    <Carousel settings={settings}>
      {data?.map((item, id) => {
        console.log("ITEM ITEM ITEM")
        console.log(item)
        return (
          <div key={id} style={{ width: 130 }}>
            <OfferCards
              carousel
              offer={item}
              navigateBusiness={navigateBusiness}
            />
          </div>
        )
      })}
    </Carousel>
  )
}

export default OfferSlider
