import React, { useEffect, useState } from "react"
import Sidebar from "../../components/Sidebar"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import FinancialOnboarding from "../../pages/FinancialOnboarding/FinancialOnboarding"
import ExtraDocumentUpload from "../../components/ExtraUploadDocument/ExtraDocumentUpload"
import MicroDepositsUpload from "../../components/MicroDepositsUpload"
import { request } from "../../utils/http"
import QueryString from "qs"
import {
  GET_PLAID_LINK_TOKEN
} from "../../store/payment/constants"
import {
  usePlaidLink
} from "react-plaid-link"

const DashboardLayout = ({ children }) => {
  const dispatch = useDispatch()
  const status = useSelector(state => state.authReducer.login.status)
  const googleStatus = useSelector(
    state => state.authReducer.googleLogin.status
  )
  // const token = useSelector(state => state.authReducer.token);
  const [error, setError] = useState(false)
  const [onboarding, setOnboarding] = useState(false)
  const [verified, setVerified] = useState(false)
  const [dashboard, setDashboard] = useState(false)
  const [customer, setCustomer] = useState({})
  const [statusDwolla, setStatusDwolla] = useState("");
  const [statusProPay, setStatusProPay] = useState("");
  const [microDepositsNeeded, setMicroDepositsNeeded] = useState(false)
  const [microDepositsUrl, setMicroDepositsUrl] = useState('')
  const [microDepositsBankName, setMicroDepositsBankName] = useState('')
  const plaidLink = useSelector(state => state.paymentReducer.plaidLink.data)
  const [plaidLinkNew, setPlaidLinkNew] = useState('')
  const [plaidPanelOpened, setPlaidPanelOpened] = useState(false)

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    type: "business",
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    controller: {
      firstName: "",
      lastName: "",
      title: "",
      dateOfBirth: "",
      ssn: "",
      address: {
        address1: "",
        city: "",
        stateProvinceRegion: "",
        postalCode: "",
        country: ""
      }
    },
    businessClassification: "9ed38155-7d6f-11e3-83c3-5404a6144203",
    businessType: "soleProprietorship",
    businessName: "",
    ein: ""
  })

  useEffect(() => {
    console.log("status", status)
    const token = localStorage.getItem("token")
    if (!token) {
      dispatch(push("/dashboard/login"))
    }
  }, [status, dispatch, googleStatus])

  // We removed this functions for now because we stopped using dwolla
  // useEffect(() => {
  //   if (error === false && verified === false && onboarding === false ) {
  //       getDwollaCustomer()
  //   }
  //   initiateMicroDeposits()
  //   verifyMicroDeposits()
  //   getDwollaInformation()
  // })

  useEffect(() => {
    if(plaidLinkNew === ''){
        //Uncomment this line to refresh the Plaid Link
        //refreshPlaidLink()
    }
  }, [plaidLinkNew])

  const getDwollaCustomer = () => {
    request
      .get("/api/v1/dwolla/verify-customer")
      .then(data => {
        console.log('inside verify customer', data)
        setCustomer(data.data)
        setStatusDwolla(data.data.status)

        if (data.data.status !== "verified") {
          if (data.data.status === "unverified") {
            setOnboarding(true)
          } else {
            setError(true)
            console.log('error set because customer was neither verified or unverified', error)
          }
        } else {
          setDashboard(true)
          setVerified(true)
        }
      })
      .catch(e => {
        console.log(e.response.data)
        setOnboarding(true)
      })
  }

  const verifyMicroDeposits = () => {
    request
     .get("/api/v1/dwolla/funding-sources")
     .then(data => {
      var microDeposits = false
      var bankName = false
      console.log('Current funding sources:', data.data._embedded['funding-sources'])
      data.data._embedded['funding-sources'].forEach(item => {
        if(item._links["verify-micro-deposits"]){
          microDeposits = item._links["verify-micro-deposits"].href
          bankName = item.name
          console.log('microDeposits needed: ', microDeposits)
          console.log('name of bank that needs microDeposits: ', bankName)
        }
      })
//        const microDeposits = data.data._embedded['funding-sources']
//           [data.data._embedded['funding-sources'].length - 1]._links["verify-micro-deposits"].href
        if(microDeposits){
          console.log('microDeposits needed: ', microDeposits)
          setMicroDepositsNeeded(true)
          setMicroDepositsUrl(microDeposits)
          if(bankName){setMicroDepositsBankName(bankName)}
        }else{console.log('no microDeposits needed to verify the bank')}
     })
     .catch(e => {
       console.log("ERROR while getting funding sources", e)
     })
  }

  const initiateMicroDeposits = () => {
    request
     .get("/api/v1/dwolla/funding-sources")
     .then(data => {
       //        const microDeposits = data.data._embedded['funding-sources']
       //           [data.data._embedded['funding-sources'].length - 1]._links["initiate-micro-deposits"].href
        var microDeposits = false
        data.data._embedded['funding-sources'].forEach(item => {
          if(item._links["initiate-micro-deposits"]){
            microDeposits = item._links["initiate-micro-deposits"].href
            console.log('microDeposits needed: ', microDeposits)
          }
        })
        if(microDeposits){
          console.log('Initiating microDeposits needed: ', microDeposits)
            if(microDeposits !== ''){
              const payload = QueryString.stringify({
                micro_deposits_url: microDeposits
              })
              console.log('micro_deposits_url: ', payload)
              request
                .post("/api/v1/dwolla/micro-deposits", payload)
                .then(data => {
                  console.log('request sent: ', data)
                })
                .catch(e => {
                  console.log("ERROR while posting micro deposits", e)
                })
            }
        }else{console.log('no initiating microDeposits needed right now')}
     })
     .catch(e => {
       console.log("ERROR while getting funding sources", e)
     })
  }

  const getDwollaInformation = () => {
    request
     .get("/api/v1/dwolla/dwolla-account")
     .then(data => {
       console.log('Dwolla information: ', data)
     })
     .catch(e => {
       console.log("ERROR while getting dwolla information", e)
     })
  }

  //Refresh Plaid Link even if merchant is already completely onboarded, if need to retrieve information
  const access_token="access-production-7dadd348-cfa4-4da9-b271-0eee2a616174"
  const refreshPlaidLink = () => {
    console.log('Inside refreshPlaidLink')
    request
      .get("/api/v1/plaid/link-token-update", { params: { access_token: access_token } })
      .then(function (response) {
        //setPlaidToken(response.data.link_token)
        setPlaidLinkNew(response.data.link_token)
        console.log('Here is the Plaid Link token', response.data.link_token)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  console.log('Has plaidLinkNew been updated yet?', plaidLinkNew)
  var plaidConfig: PlaidLinkOptions = {
      onSuccess: (public_token, metadata) => {
        // postPlaidPublicToken(public_token, metadata)
        // dispatch({ type: SET_BANK_INFO, data: metadata });
        console.log("plaidSuccess", public_token, metadata)
      },
      onExit: (err, metadata) => {},
      onEvent: (eventName, metadata) => {},
      token: plaidLinkNew,
      // required for OAuth:
      receivedRedirectUri: null
    }

  var { open, exit, ready } = usePlaidLink(plaidConfig)
//  Uncomment these lines to refresh the Plaid Link
//  if(!plaidPanelOpened){
//    console.log('PlaidLinkNew has been updated', plaidLinkNew)
//    if(plaidConfig.token!=''){
//      console.log('PlaidLinkNew is ready', plaidConfig.token)
//      if (ready) {
//        console.log('Opening the Plaid Link update')
//        open()
//        setPlaidPanelOpened(true)
//      }
//    }
//  }

  return (
    <div className="body">
      <div className="app-layout">
        {/* Remove this to bring back the onboarding process */}
        {/* {microDepositsNeeded ? (
          <MicroDepositsUpload customer={customer} getDwollaCustomer={getDwollaCustomer} formValues={formValues}
            setFormValues={setFormValues} verified={verified} setVerified={setVerified} setDashboard={setDashboard}
            statusDwolla={statusDwolla} statusProPay={statusProPay} microDepositsUrl={microDepositsUrl}
            setMicroDepositsNeeded={setMicroDepositsNeeded} microDepositsBankName={microDepositsBankName}
          />
        ) : ( */}
        <div>
          {/* Remove this to bring back the onboarding process */}
          {/* {dashboard ? ( */}
            <div>
              <Sidebar>
                <div className="app-main-layout-container">{children}</div>
              </Sidebar>
            </div>
            {/* Remove this to bring back the onboarding process */}
            {/* ) : (
            <div>
              {onboarding ? (
                <FinancialOnboarding setDashboard={setDashboard} />
              ) : null} */}

              {error ? (
                <ExtraDocumentUpload customer={customer} getDwollaCustomer={getDwollaCustomer} formValues={formValues} setFormValues={setFormValues} verified={verified} setVerified={setVerified} setDashboard={setDashboard} statusDwolla={statusDwolla} statusProPay={statusProPay}/>
              ) : null}
            </div>
          {/* Remove this to bring back the onboarding process */}
          {/* )}
        </div>
        )} */}
      </div>
    </div>
  )
}

export default DashboardLayout

