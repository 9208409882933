import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { request } from "../../utils/http"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Finalize from "../../components/Finalize/Finalize"
import VerifiedCustomer from "../../components/VerifiedCustomer"
import checkAnimation from "../../assets/animations/checkAnimation.json"
import LottieAnimation from "../../components/Client/Animation"
import BeneficialOwner from "../../components/BeneficialOwner/beneficialOwner"

const ExtraDocumentUpload = ({ customer, getDwollaCustomer, formValues, setFormValues, verified, setVerified, statusDwolla, statusPropay, setDashboard }) => {

  const companies = useSelector(state => state.companyReducer.company?.data)
  const [documentType, setDocumentType] = useState("license")
  const [documents, setDocuments] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const [displayBeneficialOwners, setDisplayBeneficialOwners] = useState(false)

  const changeHandler = event => {
    setSelectedFile(event.target.files[0])
    console.log(event.target.files[0])
  }

  const {
    handleSubmit,
    register,
    formState: { errors }

  } = useForm()

  const [personelOnB, setpersonelOnB] = useState(false)

  const updatePersonel = async data => {
      const header = {
        "Content-Type": "application/json"
      }

      console.log(data)

      const payload = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email
      }
      request
        .patch("/api/v1/personnel/" + data.id, payload, { headers: header })
        .then(data => {
          setpersonelOnB(true)
        })
        .catch(e => {
          console.log(e)
        })
    }

    const postDwollaDocuments = () => {
      const formData = new FormData()
      formData.append("documentType", documentType)
      formData.append("file", selectedFile)

      request
        .post("/api/v1/dwolla/customer/documents", formData)
        .then(data => {
          getDwollaDocuments()
        })
        .catch(e => {
          console.log(e)
        })
    }

    const getDwollaDocuments = () => {
      request
        .get("/api/v1/dwolla/customer/documents")
        .then(data => {
          console.log("DOCS", data.data)
          setDocuments(data.data._embedded["documents"])
        })
        .catch(e => {
          console.log(e)
        })
    }



  return (
    <div className="body">
      <div className="single-layout">
        <div className="single-layout-row w-row">
          <div className="single-layout-col left w-col w-col-4" style={{display: "block"}}>
            <Box className="logo-left" px={2}>
                            <img
                              src={"/static/Localight-Logo-2021-wht.png"}
                              width="300"
                              sizes="(max-width: 767px) 100vw, (max-width: 991px) 29vw, 27vw"
                              srcSet="/static/Localight-Logo-2021-wht-p-500.png 500w, /static/Localight-Logo-2021-wht-p-800.png 800w, /static/Localight-Logo-2021-wht.png 1120w"
                              alt=""
                            />
          </Box>
            <Box className="single-layout-left" px={2}>

                <div  style={{marginTop: "70px"}}>
                  {customer.businessName ? (
                  <div>
                      <h4 className="white">Welcome back {customer.businessName}</h4>
                      <br/>
                      <br/>
                      <h4 className="white">
                        Your business is almost ready!
                      </h4>
                      <br/>
                      <p className="white text-large">
                        Let's just tie a few loose ends together.{" "}
                      </p>
                  </div>
                  ): null}
                </div>
              </Box>
          </div>
          <div className="single-layout-col right w-col w-col-8">
              <div className="single-layout-right">
                <div className="single-layout-right-content">
                    <div className="card" style={{ marginBottom: "100px", marginTop: "100px" }}>
                        {personelOnB === false ? (
                                        <form
                                          id="update-owner"
                                          name="update-owner"
                                          data-name="update Owner"
                                          onSubmit={handleSubmit(updatePersonel)}
                                        >
                                          <h3 className="text-center mb-30 ">Let's complete your registration together!</h3>
                                          <p className="text-center mb-30 ">First, let's double-check your information:</p>
                                          <div style={{ display: "none" }} className="form-group">
                                            <label htmlFor="userID" className="label">
                                              ID
                                            </label>
                                            <input
                                              type="text"
                                              {...register("id", {
                                                required: "ID is required."
                                              })}
                                              defaultValue={companies[0].locations[0].personnels[0].id}
                                              className="input w-input"
                                              maxLength="256"
                                              name="userID"
                                              id="userID"
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label htmlFor="firstName" className="label">
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              aria-invalid={errors.firstName ? "true" : "false"}
                                              {...register("firstName", {
                                                required: "First Name is required."
                                              })}
                                              defaultValue={
                                                companies[0].locations[0].personnels[0].first_name
                                              }
                                              className="input w-input"
                                              maxLength="256"
                                              name="firstName"
                                              placeholder="Enter First Name"
                                              id="firstName"
                                            />
                                            {errors.firstName && (
                                              <div firstName="error-message">
                                                <div>{errors.firstName.message}</div>
                                              </div>
                                            )}
                                          </div>

                                          <div className="form-group">
                                            <label htmlFor="lastName" className="label">
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              aria-invalid={errors.lastName ? "true" : "false"}
                                              {...register("lastName", {
                                                required: "Last Name is required."
                                              })}
                                              defaultValue={
                                                companies[0].locations[0].personnels[0].last_name
                                              }
                                              className="input w-input"
                                              maxLength="256"
                                              name="lastName"
                                              placeholder="Enter Last Name"
                                              id="lastName"
                                            />
                                            {errors.lastName && (
                                              <div lastName="error-message">
                                                <div>{errors.lastName.message}</div>
                                              </div>
                                            )}
                                          </div>

                                          <div className="form-group">
                                            <label htmlFor="email" className="label">
                                              E-mail
                                            </label>
                                            <input
                                              type="text"
                                              aria-invalid={errors.email ? "true" : "false"}
                                              {...register("email", {
                                                required: "Email is required."
                                              })}
                                              defaultValue={
                                                companies[0].locations[0].personnels[0].email
                                              }
                                              className="input w-input"
                                              maxLength="256"
                                              name="email"
                                              placeholder="Enter e-mail"
                                              id="email"
                                            />
                                            {errors.email && (
                                              <div email="error-message">
                                                <div>{errors.email.message}</div>
                                              </div>
                                            )}
                                          </div>
                                          <div style={{textAlign: "center", width: "100%"}}>
                                              <button
                                                type="submit"
                                                className="button button-primary mt-20 button-block w-button"
                                              >
                                                Confirm
                                              </button>
                                          </div>
                                        </form>
                                      ) : null}

                        {personelOnB === true && statusDwolla === "retry" && verified === false ? (
                            <div>
                              <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                                     <h4 className="text-left ">Action Required:</h4>
                                     <h4 className="text-left" style={{color: "#F54725"}}>Verify Information</h4>
                                 </div>
                              <p className="mb-40 text-center">
                                Something appears to be incorrect.
                                                    <br />
                                                    Please double-check your information above.
                              </p>
                            </div>
                        ) : null}

                        {personelOnB === true && displayBeneficialOwners === false && (statusDwolla === "retry" || statusDwolla === "document") && verified === false? (
                            <VerifiedCustomer
                                  customer={customer}
                                  getDwollaCustomer={getDwollaCustomer}
                                  formValues={formValues}
                                  setFormValues={setFormValues}
                                  setVerified={setVerified}
                                  verificationStatus={statusDwolla}
                                />
                            ) : null}

                            {customer && customer.businessType !== "soleProprietorship" ? (
                                <div>
                                    { displayBeneficialOwners ? (
                                        <div>
                                            <BeneficialOwner formValues={customer} setDisplayBeneficialOwners={setDisplayBeneficialOwners}/>
                                        </div>
                                    ) : (
                                        <div>
                                        {personelOnB === true ? (
                                        <Box style={{textAlign: "center"}}>
                                             <button
                                                 className="button button-primary-cancel button-block mt-20 w-button"
                                                 onClick={() => setDisplayBeneficialOwners(true)}
                                               >
                                               Edit Beneficial Owners
                                             </button>
                                        </Box>) : null}
                                        </div>
                                     )}
                                </div>
                            ) : null}

                            { verified ? (
                            <div style={{marginTop: "120px"}}>
                                <LottieAnimation
                                                    height={200}
                                                    width={200}
                                                    animationData={checkAnimation}
                                                    loop={false}
                                                  />
                                <h2 className="text-center mb-40">You're all set!</h2>
                                <p className="mb-40 text-center">
                                  Your business and its members have been successfully verified.
                                  <br/><br/>You can now receive your first transaction!
                                </p>
                                <div className="text-center">
                                        <button
                                            className="button button-primary-cancel button-block mt-20 w-button"
                                            onClick={() => setDashboard(true)}
                                          >
                                            Back to Dashboard
                                          </button>
                                </div>
                            </div>) : (
                                <Box style={{textAlign: "center"}}>
                                    <button
                                        className="button button-primary-cancel button-block mt-20 w-button"
                                        onClick={() => setDashboard(true)}
                                      >
                                        Back to Dashboard
                                      </button>
                                </Box>
                            )}
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraDocumentUpload

