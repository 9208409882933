import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import PasswordResetConfirmContainer from '../../containers/PasswordResetContainer/reset';

const PasswordResetConfirmPage = (props) => {

    return  <AuthLayout>
                <PasswordResetConfirmContainer {...props}/>
        </AuthLayout>
};

export default PasswordResetConfirmPage;
