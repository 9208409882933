export function graph(data, jsondata){
    let graph_data = jsondata
    let jtext = "["
    graph_data = graph_data[0]
    graph_data = graph_data[data] 
    let total= 0
    graph_data.forEach(item =>{
      jtext = jtext + "{\"uv\":" + item + "},"
      total = total + item
    })
  
    jtext = jtext.substring(0, jtext.length - 1);
    jtext = jtext + "]"
    
    const text = '[{"uv":155},{}]';
    
  
    const arra = JSON.parse(jtext)
    return arra
  }
export function graph_total(data, jsondata){
    let graph_data = jsondata
    graph_data = graph_data[0]
    graph_data = graph_data[data] 
    let total = 0
    graph_data.forEach(item =>{
      total = total + parseInt(item)
    })
    return total
  }

