function Analytics() {
    return (
      <>
        <script src="https://www.googletagmanager.com/gtag/js?id=G-YBRS15PB5S"></script>
        <script
          type='text/javascript'
          defer
          dangerouslySetInnerHTML={{
            __html: `
              // ========== Google Analytics ===========
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-YBRS15PB5S');
              `,
          }}
        />
      </>
    );
  }
  
  export default Analytics;
  