import React, { useState, useEffect } from "react"
import Link from "@mui/material/Link"
import { useDispatch } from "react-redux"
import { DO_LOGIN, DO_GOOGLELOGIN } from "../../store/auth/constants"
import GoogleButton from "../../components/SocialLogin/Google"
import Checkbox from "@mui/material/Checkbox"
import qs from "qs"
import loginimg from "../../assets/images/login_logo.png"
// import { push } from 'connected-react-router';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';

const LoginContainer = props => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [checked, setChecked] = useState(false)

  const[login_check, set_login_check] = useState("")

  console.log(checked)
  useEffect(() => {
    var email = qs.parse(props.location.search, { ignoreQueryPrefix: true }).u
    var pass = qs.parse(props.location.search, { ignoreQueryPrefix: true }).p
    if (email && pass) {
      setEmail(email)
      setPassword(pass)
    }
  }, [])

  const submitLogin = e => {
   
      e.preventDefault()
    dispatch({ type: DO_LOGIN, login: email, password: password })
    console.log(checked) 
    console.log('this is the reponse')
    
    console.log(email, password)
    
    setTimeout(set_login_check, 1000, 'true')
    
    

  }

  const handleSocialLogin = user => {
    console.log(user +"ww")

    dispatch({
      type: DO_GOOGLELOGIN,
      token: user?._token?.accessToken,
      email: user?._profile?.email
    })
  }

  const handleSocialLoginFailure = err => {
    console.error(err)
  }

  return (
    <>
      <div className="single-layout-right-header">
        Don&#x27;t have account?{" "}
        <Link href="/company/signup" underline="none" className="link">
          <strong>Sign Up</strong>
        </Link>
      </div>
      <div className="single-layout-right">
        <div className="single-layout-right-content">
          <div className="single-layout-logo-right">
            <Link href="/" underline="none" className="w-inline-block">
              <img
                src= {loginimg}
                width="600"
                alt=""
              />
            </Link>
          </div>
          <h3 className="text-center mb-40">Sign into your dashboard</h3>
          {/* <GoogleButton
            provider="google"
            appId="469399127987-og0798rgnlps91oud33gcuuiukdj1ijh.apps.googleusercontent.com"
            onLoginSuccess={handleSocialLogin}
            onLoginFailure={handleSocialLoginFailure}
          >
            Sign in with Google
          </GoogleButton> */}
          <div className="w-form">
            <form
              id="login-form"
              name="login-form"
              data-name="Login Form"
              onSubmit={submitLogin}
            >
              {/* <p className="text-center">or continue with email:</p> */}
              <input
                type="text"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                className="input w-input"
                maxLength="256"
                name="email"
                placeholder="Enter your email"
                id="email"
                required=""
              />
              <input
                type="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value)
                }}
                className="input w-input"
                maxLength="256"
                name="password"
                placeholder="Enter your password"
                id="password"
                required=""
              />
              <label className="w-checkbox checkbox-field mb-40">
                <Checkbox
                  checked={checked}
                  onChange={e => setChecked(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span
                  className="hint w-form-label"
                  onClick={e => {
                    setChecked(!checked)
                  }}
                >
                  Remember me
                </span>
              </label>
              <button
                type="submit"
                className="button button-primary button-block w-button"
              >
                Sign In
              </button>
              <p className="text-center hint">
                <Link underline="none" href="/forgot-password" className="link">
                  Forgot your password?
                </Link>
              </p>
            </form>
            <div className={login_check ? "show":"unshow"}>
              <div style={{color:'red', textAlign:'center'}}>
                Oops! Something went wrong while submitting the credetials.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginContainer
