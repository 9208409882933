import React from 'react';
// import Link from '@mui/material/Link';
import { 
  SET_PLAID_TOKEN, 
  // SET_BANK_INFO,
  GET_USER_BANK
} from "../../../store/payment/constants";
import { PaymentApiServices } from "../../../store/payment/services";
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import { useDispatch } from "react-redux";

import {
  usePlaidLink
  // PlaidLinkOptions,
  // PlaidLinkOnSuccess,
} from 'react-plaid-link';

const PlaidButton = ({ customerID, userPlaidLink, linkBankAccounts}) => {

    const dispatch = useDispatch();
    const plaidLink = userPlaidLink;

    const plaidConfig = {
      onSuccess: (public_token, metadata) => {
        postPlaidPublicToken(public_token, metadata);
        // dispatch({ type: SET_BANK_INFO, data: metadata });
        console.log('plaidSuccess', public_token, metadata);
      },
      onExit: (err, metadata) => {},
      onEvent: (eventName, metadata) => {},
      token: plaidLink?.link_token || '',
      // required for OAuth:
      receivedRedirectUri: null,
    }



    const saveBanks = (data, plaid) => {
      const banks = data.accounts || [];
      const token = plaid?.access_token;
      banks.forEach(item => {
        submitBanks({name: item.name, plaid_bank_id: item.id, plaid_access_token: token})
      });
      linkBankAccounts();
      exit();
    }

    const submitBanks = async (data) => {
      const result = await PaymentApiServices.addCustomerBankAccount({
        id: customerID,
        name: data.name, 
        plaid_bank_id: data.plaid_bank_id, 
        plaid_access_token: data.plaid_access_token
      });
      console.log(result, 'bank added');
      // if(result?.status === "OK") setTokenSent(true);
      if(result) dispatch({ type: GET_USER_BANK, id: customerID });
    }

    const { open, exit, ready } = usePlaidLink(plaidConfig);

    const connectPlaid = () => {
      if (ready) {
        open();
      }
    }

    const postPlaidPublicToken  = async (public_token, data) => {
      // public_token && dispatch({ type: SET_PLAID_TOKEN, response: public_token });
      const result = await PaymentApiServices.postPlaidToken({
        public_token: public_token,
      });
      if(result) {
        console.log('plaid', result);
        saveBanks(data, result);
        dispatch({ type: SET_PLAID_TOKEN, response: result });
      }
    }

    return <Button 
    variant="outlined" 
    size="medium"
    disabled={!plaidLink?.link_token} 
    onClick={connectPlaid}
    sx={{
        m: 1,
        p: 1.5,
        borderRadius: 20,
        fontSize: '18px',
        fontWeight: '400',
        borderColor: '#FFFFFF',
        color: '#000000',
        background: '#FFFFFF'
    }} 
    startIcon={<LockIcon />}
    >
    Link Bank Account
    </Button>
};

export default PlaidButton;
