import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import LoginContainer from '../../containers/LoginContainer';

const LoginPage = (props) => {

    return  <AuthLayout>
                <LoginContainer {...props}/>
        </AuthLayout>
};

export default LoginPage;
