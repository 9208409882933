import { all } from "redux-saga/effects"

import authRootSagas from './auth/sagas';
import companyRootSagas from './company/sagas';
import paymentRootSagas from './payment/sagas';
import customerRootSagas from './customer/sagas';

  
export default function* AppSagas() {
    yield all([
      authRootSagas(),
      companyRootSagas(),
      paymentRootSagas(),
      customerRootSagas(),
    ]);
}
