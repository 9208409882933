import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import PaymentsContainer from '../../containers/PaymentsContainer';

const Payments = (props) => {

    return <DashboardLayout>
        <PaymentsContainer {...props} />
    </DashboardLayout>
};

export default Payments;
