import React, { useState, useEffect } from "react"
// import Link from '@mui/material/Link';
// import Box from '@mui/material/Box';
// import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container"
// import Box from '@mui/material/Box';
import {
  useDispatch
  // useSelector,
} from "react-redux"
import { push } from "connected-react-router"
import { SET_CUSTOMER_BUSINESS } from "../../../store/customer/constants"

import BusinessProfile from "../../../components/Client/BusinessProfile"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
// import MerchantHero from '../../../assets/images/MerchantHero.png';
import { CompanyApiServices } from "../../../store/company/services"

const BusinessContainer = props => {
  const dispatch = useDispatch()
  const [business, setBusiness] = useState(null)

  const businessID = props.match.params.id

  useEffect(() => {
    if (businessID) getBusiness(businessID)
  }, [businessID])

  const getBusiness = async businessID => {
    const result = await CompanyApiServices.getCompanyDetails({
      id: businessID
    })
    // console.log(result, 'business');
    if (result) {
      console.log(result)
      setBusiness(result)
    }
  }

  const navigateJoinIn = () => {
    dispatch({ type: SET_CUSTOMER_BUSINESS, id: businessID })
    dispatch(push(`/customers/signup`))
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: "100%",
        minHeight: "100vh",
        opacity: 1,
        background: "#EDF3F4 0% 0% no-repeat padding-box"
      }}
    >
      {business?.id ? (
        <BusinessProfile business={business} navigateJoinIn={navigateJoinIn} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  )
}

export default BusinessContainer
