import * as types from "./constants";

export const getPlaidToken = (public_token) => ({
  type: types.GET_PLAID_TOKEN, public_token
})

export const setPlaidToken = response => ({
  type: types.SET_PLAID_TOKEN, response
})

export const getPlaidTokenFailed = error => ({
  type: types.GS_PLAID_TOKEN_FAILED, error
})

export const setBankInfo = data => ({
  type: types.SET_BANK_INFO, data
})

export const removeBankInfo = () => ({
  type: types.REMOVE_BANK_INFO
})

export const getPlaidLinkToken = () => ({
  type: types.GET_PLAID_LINK_TOKEN
})

export const setPlaidLinkToken = response => ({
  type: types.SET_PLAID_LINK_TOKEN, response
})

export const getPlaidLinkTokenFailed = error => ({
  type: types.GS_PLAID_LINK_TOKEN_FAILED, error
})


export const requestSendMoney = (bank_id, company_id, amount) => ({
  type: types.SEND_MONEY_REQUEST, bank_id, company_id, amount
})

export const successSendMoney = response => ({
  type: types.SEND_MONEY_SUCCESS, response
})

export const failedSendMoney = error => ({
  type: types.SEND_MONEY_FAILED, error
})

export const getCustomerBank = id => ({
  type: types.GET_USER_BANK, id
})

export const setCustomerBank = response => ({
type: types.GET_USER_BANK_SUCCESS, response
})

export const getCustomerBankFailed = error => ({
type: types.GET_USER_BANK_FAILED, error
})



export const getBusinessBank = id => ({
  type: types.GET_BUSINESS_BANK, id
})

export const setBusinessBank = response => ({
type: types.GET_BUSINESS_BANK_SUCCESS, response
})

export const getBusinessBankFailed = error => ({
type: types.GET_BUSINESS_BANK_FAILED, error
})


export const getTransactions = () => ({
  type: types.GET_TRANSACTIONS
})

export const setTransactions = response => ({
type: types.GET_TRANSACTIONS_SUCCESS, response
})

export const getTransactionsFailed = error => ({
type: types.GET_TRANSACTIONS_FAILED, error
})


export const getCompanyTransactions = (id) => ({
  type: types.GET_COMPANY_TRANSACTIONS, id
})

export const setCompanyTransactions = response => ({
type: types.GET_COMPANY_TRANSACTIONS_SUCCESS, response
})

export const getCompanyTransactionsFailed = error => ({
type: types.GET_COMPANY_TRANSACTIONS_FAILED, error
})


