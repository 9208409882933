export const anchors = {
  amiba: {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/62188839f7109136295ebfe4_0b1952e0.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "AMIBA and Black Achievers will sponsor the formation of The Common Good Alliance in Cincinnati, OH. The Common Good Alliance will function as an organized group of like-minded individuals who are actively willing to invest and share ownership in generating a localized black economy.",
    "Learn More Link Text": "Learn more at amiba.net",
    "Anchor Website URL": "https://amiba.net/amiba-equity-commitment/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Cincinnati Ohio",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "bixby-knolls": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/6218883c9404c11c3bd34c54_91efe0eb.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Nearly 4 times more money recirculates locally when we spend money at independent businesses. Help them revive and thrive!",
    "Learn More Link Text": "Learn more at bixbyknollsinfo.com",
    "Anchor Website URL": "https://bixbyknollsinfo.com/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Bixby Knolls, CA",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "city-of-bellflower": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/6218883a9404c10a4cd34b1b_d04f4322.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Nearly 4 times more money recirculates locally when we spend money at independent businesses. Help them revive and thrive!",
    "Learn More Link Text": "Learn more at bellflower.org/",
    "Anchor Website URL": "https://www.bellflower.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Bellflower, CA",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "common-good-alliance": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/621888399ec902ddd10844c2_68cd9883.png",
    "Call To Action": "Buy Black-Owned",
    "Anchor Description":
      "AMIBA and Black Achievers will sponsor the formation of The Common Good Alliance in Cincinnati, OH. The Common Good Alliance will function as an organized group of like-minded individuals who are actively willing to invest and share ownership in generating a localized black economy.",
    "Learn More Link Text": "Learn more at amiba.net",
    "Anchor Website URL": "https://amiba.net/amiba-equity-commitment/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Cincinnati Ohio",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "ggvcf-old": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/61c0ea0301033847c9d709bc_GGVCF-logo-lighter%402x.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "By doing so, you positively impact our community of businesses and nonprofits! We thank you!",
    "Learn More Link Text": "Learn more at www.ggvcf.org",
    "Anchor Website URL": "https://www.ggvcf.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 8% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Southern Arizona",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/61b7df548dcef2b0804d1133_Webapp-Meta-Image.jpg"
  },
  "greater-green-valley-community-foundation": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/621888381de6159c887eada6_72580eda.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Nearly 4 times more money recirculates locally when we spend money at independent businesses. Help them revive and thrive!",
    "Learn More Link Text": "Learn more at www.ggvcf.org",
    "Anchor Website URL": "https://www.ggvcf.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Southern Arizona",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "local-first-utah": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/62188838cbbc1f760fb8ca1c_47a549b4.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Shifting just 10% of your spending to locally owned businesses will keep $1.3 billion in Utah’s economy.",
    "Learn More Link Text": "Learn more at localfirst.org",
    "Anchor Website URL": "https://www.localfirst.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-utah-localight",
    "Near Anchor Region Text": "Near Salt Lake City",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "local-first-utah-old": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/61b64bc42cc73d887bf0dc52_Local-First-Utah-Logo%402x.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Shifting just 10% of your spending to locally owned businesses will keep $1.3 billion in Utah’s economy.",
    "Learn More Link Text": "Learn more at localfirst.org",
    "Anchor Website URL": "https://www.localfirst.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 8% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-utah-localight",
    "Near Anchor Region Text": "Near Salt Lake City",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/61b7df548dcef2b0804d1133_Webapp-Meta-Image.jpg"
  },
  "los-altos-village-association": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/6218883e34fd2805e77abf8f_b80205ed.jpeg",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Nearly 4 times more money recirculates locally when we spend money at independent businesses. Help them revive and thrive!",
    "Learn More Link Text": "downtownlosaltos.org",
    "Anchor Website URL": "https://downtownlosaltos.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Los Altos, CA",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "nogales-community-development": {
    "Anchor Partner Logo":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/6218883db4c370d1be6ba543_4c90dcf9.png",
    "Call To Action": "¡Compra local!",
    "Anchor Description":
      "Recircula aproximadamente 4 veces más dinero a nivel local cuando lo gastamos en negocios locales independientes.",
    "Learn More Link Text": "Learn more at nogalescdc.org",
    "Anchor Website URL": "https://nogalescdc.org/",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 7% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Near Nogales Arizona",
    "Meta & SEO Image":
      "https://uploads-ssl.webflow.com/61b64bc42cc73dc8d1f0dc21/620b6c115a0ae1780d54dfc1_073e2f39.jpeg"
  },
  "local-first-dmv": {
    "Anchor Partner Logo":
      "https://dl.airtable.com/.attachments/4817d1859323a7bf303531b73f679477/7c805440/Local-First-DMV-web.png",
    "Call To Action": "Shift Your Spending Local!",
    "Anchor Description":
      "Nearly 4 times more money recirculates locally when we spend money at independent businesses.",
    "Learn More Link Text": "Learn more at nogalescdc.org",
    "Anchor Website URL": "https://thinklocalfirstdc.org",
    "Anchor Invitation Message": "Invites you to join",
    "Anchor Offer Message": "Shop local & earn 2% to 5% back at",
    "Anchor Destination Message": "and indie businesses nearby.",
    "Anchor Reward Message": "Earn rewards with",
    "Business List Link": "",
    "Join Now Link": "https://join.localight.com/join-localight",
    "Near Anchor Region Text": "Washington DC",
    "Meta & SEO Image":
      "https://dl.airtable.com/.attachments/4817d1859323a7bf303531b73f679477/7c805440/Local-First-DMV-web.png"
  }
}
