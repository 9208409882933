import * as types from "./constants"
import * as status from '../constants'
import * as StoreUtil from '../storeUtil'

const initialState = {
    errorMessage: null,
    banks: {
        data: [],
        status: null,
    },
    customerBanks: {
        data: [],
        status: null,
    },
    transactions: {
        data: null,
        status: null
    },
    lastTransaction: {
        data: null,
        status: null
    },
    businessTransactions: {
        data: null,
        status: null
    },
    plaid: {
        data: null,
        status: null,
    },
    plaidLink: {
        data: null,
        status: null,
    }
}

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PLAID_TOKEN:
            return {
                ...state,
                plaid: {
                    status: status.LOADING,
                    data: null,
                },
            }
        case types.SET_PLAID_TOKEN:
            return {
                ...state,
                plaid: {
                    data: action.response,
                    status: status.SUCCESS,
                    errorMessage: null
                }
            }
        case types.GS_PLAID_TOKEN_FAILED:
            return {
                ...state,
                plaid: {
                    status: status.FAILED,
                    data: null,
                    errorMessage: StoreUtil.getErrorMessage(action)
                }
            }

        case types.SET_BANK_INFO:
            return {
                ...state,
                banks: {
                    status: status.SUCCESS,
                    data: action.data,
                },
            }
        case types.REMOVE_BANK_INFO:
            return {
                ...state,
                banks: {
                    data: [],
                }
            }
        case types.GET_USER_BANK:
            return {
                ...state,
                customerBanks: {
                    status: status.LOADING,
                    data: []
                },
            }
        case types.GET_USER_BANK_SUCCESS:
            return {
                ...state,
                customerBanks: {
                    status: status.SUCCESS,
                    data: action.response,
                },
            }
        case types.GET_USER_BANK_FAILED:
            return {
                ...state,
                customerBanks: {
                    status: status.FAILED,
                    data: [],
                    errorMessage: StoreUtil.getErrorMessage(action)
                },
            }
        case types.GET_BUSINESS_BANK:
            return {
                ...state,
                banks: {
                    status: status.LOADING,
                    data: []
                },
            }
        case types.GET_BUSINESS_BANK_SUCCESS:
            return {
                ...state,
                banks: {
                    status: status.SUCCESS,
                    data: action.response,
                },
            }
        case types.GET_BUSINESS_BANK_FAILED:
            return {
                ...state,
                banks: {
                    status: status.FAILED,
                    data: [],
                    errorMessage: StoreUtil.getErrorMessage(action)
                },
            }

        case types.GET_TRANSACTIONS:
            return {
                ...state,
                transactions: {
                    status: status.LOADING,
                    data: state.transactions?.data
                },
            }
        case types.GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: {
                    status: status.SUCCESS,
                    data: action.response,
                },
            }
        case types.GET_TRANSACTIONS_FAILED:
            return {
                ...state,
                transactions: {
                    status: status.FAILED,
                    data: [],
                    errorMessage: StoreUtil.getErrorMessage(action)
                },
            }
        case types.GET_COMPANY_TRANSACTIONS:
            return {
                ...state,
                businessTransactions: {
                    status: status.LOADING,
                    data: []
                },
            }
        case types.GET_COMPANY_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                businessTransactions: {
                    status: status.SUCCESS,
                    data: action.response,
                },
            }
        case types.GET_COMPANY_TRANSACTIONS_FAILED:
            return {
                ...state,
                businessTransactions: {
                    status: status.FAILED,
                    data: [],
                    errorMessage: StoreUtil.getErrorMessage(action)
                },
            }
        case types.GET_PLAID_LINK_TOKEN:
            return {
                ...state,
                plaidLink: {
                    status: status.LOADING,
                    data: null,
                },
            }
        case types.SET_PLAID_LINK_TOKEN:
            return {
                ...state,
                plaidLink: {
                    data: action.response,
                    status: status.SUCCESS,
                    errorMessage: null
                }
            }
        case types.GS_PLAID_LINK_TOKEN_FAILED:
            return {
                ...state,
                plaidLink: {
                    status: status.FAILED,
                    data: null,
                    errorMessage: StoreUtil.getErrorMessage(action)
                }
            }
        case types.SEND_MONEY_REQUEST:
            return {
                ...state,
                lastTransaction: {
                    status: status.LOADING,
                    data: null
                },
            }
        case types.SEND_MONEY_SUCCESS:
            return {
                ...state,
                lastTransaction: {
                    status: status.SUCCESS,
                    data: action.response,
                },
            }
        case types.SEND_MONEY_FAILED:
            return {
                ...state,
                lastTransaction: {
                    status: status.FAILED,
                    errorMessage: StoreUtil.getErrorMessage(action)
                },
            }
        default: {
            return state;
        }
    }
}

export default paymentReducer;
