import { put, call, spawn, all, takeEvery } from "redux-saga/effects"
import { CompanyApiServices } from "./services"
import * as types from "./constants"
import * as actions from "./actions"
import * as StoreUtil from "../storeUtil";
import { push } from 'connected-react-router';


function* getCompanyWorker(action) {
  try {
    StoreUtil.logAction("getCompanyWorker", action);

    const result = yield call(CompanyApiServices.getCompany, action);
    if(!!result?.length) {
      yield put(actions.setCompany(result));
    }
    else yield put(push('/dashboard/onboarding'))
    console.log('company', result);
  } catch (err) {
    StoreUtil.logError("getCompanyWorker:", err);
    yield put(actions.gsuCompanyFailed(err));
  }
}
function* getCompanyWatcher() {
  yield takeEvery(types.GET_COMPANY, getCompanyWorker)
}



function* updateCompanyWorker(action) {
  try {
    StoreUtil.logAction("updateCompanyWorker", action);
    const result = yield call(CompanyApiServices.addCompany, action);
    yield put(actions.setCompany(result));
  } catch (err) {
    StoreUtil.logError("updateCompanyWorker: ", err);
    yield put(actions.gsuCompanyFailed(err));
  }
}
function* updateCompanyWatcher() {
  yield takeEvery(types.UPDATE_COMAPNY, updateCompanyWorker)
}


function* addCompanyWorker(action) {
  try {
    StoreUtil.logAction("addCompanyWorker", action);
    const result = yield call(CompanyApiServices.addCompany, action);
    if(result?.id) {
      yield put(actions.setCompany([{...result}]));
      yield put(push('/dashboard'));
    }
  } catch (err) {
    StoreUtil.logError("addCompanyWorker: ", err);
    yield put(actions.gsuCompanyFailed(err));
  }
}
function* addCompanyWatcher() {
  yield takeEvery(types.ADD_COMAPNY, addCompanyWorker)
}


function* getLocationsWorker(action) {
  try {
    StoreUtil.logAction("getLocationsWorker", action);
    const result = yield call(CompanyApiServices.getLocations, action);
    yield put(actions.setLocation(result));
  } catch (err) {
    StoreUtil.logError("getLocationsWorker: ", err);
    yield put(actions.gsuLocationFailed(err));
  }
}
function* getLocationsWatcher() {
  yield takeEvery(types.GET_LOCATION, getLocationsWorker)
}

function* getCategoriesWorker(action) {
  try {
    StoreUtil.logAction("getCategoriesWorker", action);
    const result = yield call(CompanyApiServices.getCategories, action);
    yield put(actions.setCategories(result));
  } catch (err) {
    StoreUtil.logError("getCategoriesWorker", err);
    yield put(actions.getCategoriesFailed(err));
  }
}
function* getCategoriesWatcher() {
  yield takeEvery(types.GET_CATEGORIES, getCategoriesWorker)
}

// export default companyRootSagas;

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* companyRootSagas() {
  const sagas = [
    // Example watcher
    // getBalanceWatcher
    getCompanyWatcher,
    updateCompanyWatcher,
    addCompanyWatcher,
    getLocationsWatcher,
    getCategoriesWatcher,
  ]
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}