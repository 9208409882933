import { request } from "../../utils/http";
// import qs from 'qs';

function getCustomerInfo(payload) {
  return request.get(`/api/v1/customers/${payload?.id}`).then(function (response) {
    return JSON.parse(JSON.stringify(response.data));
  }).catch(function (error) {
    throw error;
  })
}




export const CustomerApiServices = {
  getCustomerInfo,
}
