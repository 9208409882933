import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import { CardActionArea } from "@mui/material"
import Typography from "@mui/material/Typography"
import { getPathFromUrl, convertToSlug } from "../../../utils/getURL"

import cardimage from "../../../assets/images/Card-Box.png"

const OfferCards = ({ offer, navigateBusiness }) => {
  const navigate = () => {
    navigateBusiness(offer?.slug, convertToSlug(offer?.name))
  }
  return (
    <Card sx={{ width: 116, height: 150, borderRadius: 5 }}>
      <CardActionArea onClick={navigate}>
        <CardMedia
          sx={{
            minHeight: "150px"
          }}
          component="img"
          image={
            (offer?.profile_image && getPathFromUrl(offer?.profile_image)) ||
            (offer?.image && getPathFromUrl(offer?.image)) ||
            cardimage
          }
          alt={offer?.name || "green iguana"}
        />
        <CardContent
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{
              textAlign: "center",
              color: "#FFFFFF",
              opacity: 1,
              textShadow: "0px 0px 8px #000000",
              letterSpacing: "-0.22px",
              lineHeight: 1.1,
              fontSize: "11px",
              fontWeight: 600
            }}
          >
            {offer?.name || "Argentina’s Best Empanadas"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OfferCards
