
import React, { useState, Component, useEffect, FetchData  } from "react"
import { useForm } from "react-hook-form"
import Select, { NonceProvider } from "react-select"
import moment from "moment"
import DatePicker from "react-datepicker"
import ReactInputDateMask from "react-input-date-mask"
import { request } from "../../utils/http"
import checkAnimation from "../../assets/animations/checkAnimation.json"
import loadingAnimation from "../../assets/animations/loadingAnimation15.json"
import LottieAnimation from "../../components/Client/Animation"
import BeneficialOwner from "../../components/BeneficialOwner/beneficialOwner"
import Airtable from "airtable"
import { KeyboardReturnRounded } from "@mui/icons-material"
import {b, rangefun} from "../../components/Example/component/range_slider"
import{atv, ttv} from "../../components/Example/Example"

let input_change = 0

function handleClick(event) {
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
    modal.style.display = "none";
  })
}

const VerifiedCustomer = ({ setStep, customer, getDwollaCustomer, formValues, setFormValues, setVerified, setVerificationStatus, verificationStatus, checked, setChecked}) => {
  // const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()


  const [bname, setbname] = useState("");
  const [businessType, setBusinessType] = useState(customer.businessType ? customer.businessType : "")
  const [xtext, enableButton] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date())
  const [isDateIsValid, setIsDateIsValid] = useState(false)
  const [agreeA, setAgreeA] = useState(false)
  const [agreeB, setAgreeB] = useState(false)
  // Different status states are first, reply and document
  const [id, setId] = useState("")
  const [status, setStatus] = useState("first")
  const [selectedFile, setSelectedFile] = useState()
  const [documentType, setDocumentType] = useState("license")
  const [documents, setDocuments] = useState([])
  const [documentUploaderHidden, setDocumentUploaderHidden] = useState(false)
  const [formDisplayed, setFormDisplayed] = useState("retry")
  const [isSmallLoading, setIsSmallLoading] = useState(false)
  const [documentUploading, setDocumentUploading] = useState(false)
  const [documentUploadingError, setDocumentUploadingError] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [lastStatus, setLastStatus] = useState("pending")
  const [noOwners, setNoOwners] = useState(false)
  const [beneficialOwnerAdded, setBeneficialOwnerAdded] = useState(false)

  const handleCheckboxChange = () => {
    setChecked(!checked)
  }

  const updateDate = date => {
    if (dateIsValid(date)){
        setIsDateIsValid(true)
        setDateOfBirth(date)
    }
  }

  const postDwollaCustomer = async data => {
    console.log(data)
    setIsSmallLoading(true)
    const header = {
      "Content-Type": "application/json"
    }
    request
      .post("/api/v1/dwolla/verify-customer", data, { headers: header })
      .then(data => {
        getDwollaCustomer()
        if (setVerificationStatus){
            setVerificationStatus(data.data.status)
        }
        if (data.data.status === "retry") {
          setFormValues(
            Object.assign({}, formValues, { controller: { ssn: "" } })
          )
        }

        if (data.data.status === "document") {
          setFormDisplayed("document")
          getBeneficialOwners()
        }
        setIsSmallLoading(false)
      })
      .catch(e => {
        setIsSmallLoading(false)
        console.log("ERROR", e)
        if (e.response.data) {
            setErrorText(e.response.data._embedded.errors[0].message);
        }
        console.log(e.response.data)
      })
  }

  const postDwollaDocuments = () => {
      setDocumentUploading(true)
      const formData = new FormData()
      formData.append("documentType", documentType)
      formData.append("file", selectedFile)

      request
        .post("/api/v1/dwolla/customer/documents", formData)
        .then(data => {
          setDocumentUploaderHidden(true)
          getDwollaDocuments()
          setDocumentUploading(false)
        })
        .catch(e => {
          console.log(e)
          setDocumentUploading(false)
          setDocumentUploadingError(true)
        })
    }

    const getDwollaDocuments = () => {
      request
        .get("/api/v1/dwolla/customer/documents")
        .then(data => {
          console.log("DOCS", data.data)
          setDocuments(data.data._embedded["documents"])

          documents.map(item => {
            setLastStatus(item.documentVerificationStatus)
          })
        })
        .catch(e => {
          console.log(e)
        })
    }

  const submitCustomerInfo = async data => {
    setErrorText("")
    const payloadProPay = {
   PersonalData: {
    FirstName: data.firstName,
    MiddleInitial: "",
    LastName: data.lastName,
    DateOfBirth: moment(dateOfBirth).format("MM-DD-YYYY"),
    SocialSecurityNumber: data.controllerSsn,
    SourceEmail: data.email,
    PhoneInformation: {
     DayPhone: "8888888888",
     EveningPhone: "8888888888"
    },
    NotificationEmail: data.email
   },
   SignupAccountData: {
    CurrencyCode: "USD",
    Tier: "Test"
   },
   BusinessData: {
    BusinessLegalName: data.businessName,
    DoingBusinessAs: data.businessName,
    EIN: data.ein,
    MerchantCategoryCode: "5999",
    WebsiteURL: "http://Propay.com",
    BusinessDescription: "Accounting Services",
    MonthlyBankCardVolume: "",
    AverageTicket: 100,
    HighestTicket: 250
   },
   Address: {
    ApartmentNumber: "1",
    Address1: "3400 N Ashton Blvd",
    Address2: "Suite 200",
    City: "Lehi",
    State: "UT",
    Country: "USA",
    Zip: "84043"
   },
   BusinessAddress: {
    Address1: "RR 123445",
    Address2: "SW",
    City: "Tooele",
    State: "UT",
    Country: "USA",
    Zip: "84074"
   },
   BankAccount: {
    AccountCountryCode: "USA",
    BankAccountNumber: "123456789",
    RoutingNumber: "011306829",
    AccountOwnershipType: "Business",
    BankName: "MERCHANTILE BANK UT",
    AccountType: "Checking",
    AccountName: null,
    Description: null
   },
   BeneficialOwnerData: {
    OwnerCount: "1",
    Owners: [
     {
      FirstName: "First1",
      LastName: "Last1",
      SSN: "123456789",
      DateOfBirth: "01-01-1981",
      Email: "test1@qamail.com",
      Address: "Address",
      City: "Lehi",
      State: "UT",
      Zip: "84010",
      Country: "USA",
      Title: "CEO",
      Percentage: "100"
     }
    ]
   }
  }

    if (businessType === "soleProprietorship") {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: customer.email,
        type: "business",
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
        ssn: data.controllerSsn,
        address1: data.address,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        businessName: data.businessName,
        businessType: businessType ? businessType : "soleProprietorship",
        businessClassification: "9ed38155-7d6f-11e3-83c3-5404a6144203",
      }
      setFormValues(payload);
      postDwollaCustomer(payload)
      send_to_airtable_single(payload)
    } else {
      const payload = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: customer.email,
          type: "business",
          address1: data.address,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          controller: {
                  firstName: data.controllerFirstName,
                  lastName: data.controllerLastName,
                  title: data.controllerTitle,
                  dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
                  ssn: data.controllerSsn,
                  address: {
                    address1: data.controllerAddress,
                    city: data.controllerCity,
                    stateProvinceRegion: data.controllerState,
                    postalCode: data.controllerPostalCode,
                    country: data.controllerCountry,
                  }
                },
          businessClassification: "9ed38155-7d6f-11e3-83c3-5404a6144203",
          businessType: businessType ? businessType : 'llc',
          businessName: data.businessName,
          ein: data.ein,
      }
      
      setFormValues(payload);
      postDwollaCustomer(payload)
      send_to_airtable_controller(payload)


    }
  }

  var businessOptions = []
  if(checked){
    businessOptions = [
      { value: "", label: "---" },
      { value: "llc", label: "Limited Liability Company" },
      { value: "corporation", label: "Business Corporation" }
    ]
  }else{
    businessOptions = [
      { value: "", label: "---" },
      { value: "soleProprietorship", label: "Sole Proprietorship" },
      { value: "partnership", label: "Partnership" },
      { value: "llc", label: "Limited Liability Company" },
      { value: "corporation", label: "Business Corporation" }
    ]
  }

  function myFunction() {
    var popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
  }

  const dateIsValid = date => {
    return !Number.isNaN(new Date(date).getTime());
  }

  function send_to_airtable(){
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'keybe5SGDRZRNDg4M'}).base('appdlniIdCPw3w4pf');
    let Multiplier = (ttv/atv).toString()
    base('Temp ATV TTV').create([
      {
        "fields": {
          "Name": document.getElementById("businessName").value,
          "ATV": atv,
          "Multiplier": Multiplier
        }
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });
  }

  function send_to_airtable_controller(payload){
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'keybe5SGDRZRNDg4M'}).base('appdlniIdCPw3w4pf');
    base('Business Data').create([
      {
        "fields": {
          "Email": payload.email,
          "Names": (document.getElementById("firstName").value + " " + document.getElementById("lastName").value),
          "Address": (document.getElementById("address")).value.toString(),
          "City": (document.getElementById("city")).value.toString(),
          "State": (document.getElementById("state")).value.toString(),
          "Postal": (document.getElementById("postalCode")).value.toString(),
          //"Classification": (document.getElementById("")).value.toString(),
          //"Type": (document.getElementById("")).value.toString(),
          "Ein": (document.getElementById("ein")).value.toString(),
          "con_firstName": (document.getElementById("controllerFirstName")).value.toString(),
          "con_lastName": (document.getElementById("controllerLastName")).value.toString(),
          "con_title": (document.getElementById("controllerTitle")).value.toString(),
          "con_dateOfBirth": (payload.dateOfBirth),
          "con_ssn": (document.getElementById("controllerSSN")).value.toString(),
          "con_address1": (document.getElementById("controllerAddress")).value.toString(),
          "con_city": (document.getElementById("controllerCity")).value.toString(),
          "con_stateProvinceRegion": (document.getElementById("controllerState")).value.toString(),
          "con_postalCode": (document.getElementById("controllerPostalCode")).value.toString(),
          "con_country": (document.getElementById("controllerCountry")).value.toString(),  
        }
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });
  }
  function send_to_airtable_single(payload){
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'keybe5SGDRZRNDg4M'}).base('appdlniIdCPw3w4pf');
    base('Business Data').create([
      {
        "fields": {
          "Email": payload.email,
          "Names": (document.getElementById("firstName").value + " " + document.getElementById("lastName").value),
          "Address": (document.getElementById("address")).value.toString(),
          "City": (document.getElementById("city")).value.toString(),
          "State": (document.getElementById("state")).value.toString(),
          "Postal": (document.getElementById("postalCode")).value.toString(),
          //"Classification": (document.getElementById("")).value.toString(),
          //"Type": (document.getElementById("")).value.toString(),
          "con_ssn": (document.getElementById("controllerSsn")).value.toString(),
        }
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });
  }

  const changeHandler = event => {
      setSelectedFile(event.target.files[0])
      console.log(event.target.files[0])
    }

    useEffect(() => {
        setFormDisplayed(verificationStatus);
        if (verificationStatus === "document"){
            getDwollaDocuments();
            getBeneficialOwners();
        }
    }, [])

  const getBeneficialOwners = () => {
      request
        .get("/api/v1/dwolla/beneficial/owner")
        .then(data => {

          if (data.data._embedded["beneficial-owners"]) {
            if (data.data._embedded["beneficial-owners"].length === 0) {
               setNoOwners(true);
            }
          }
          console.log(beneficialOwnerAdded)
        })
        .catch(e => {
          console.log(e)
        })
    }

  return (
    <div>
      
    {formDisplayed === "document" ? (
                    <div>
                    {noOwners && beneficialOwnerAdded === false ? (
                        <div>
                            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                               <h4 className="text-left ">Action Required:</h4>
                               <h4 className="text-left" style={{color: "#F54725"}}>Add Beneficial Owner(s)</h4>
                           </div>
                           <h4 className="text-center mt-30">Add Beneficial Owners</h4>
                                         <p className="mb-40 text-center">
                                           Add all shareholders that own more than 25% shares.{" "}
                                         </p>
                           <BeneficialOwner formValues={customer} setBeneficialOwnerAdded={setBeneficialOwnerAdded}/>
                       </div>
                    ) : null}

                    {documents.length > 0 ? (
                          <div style={{ marginBottom: "10px",}}>
                          {lastStatus ? (
                          <div>
                            <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                                   <h4 className="text-left ">Document status:</h4>
                                   <h4 className="text-left" style={{color: "orange"}}>Awaiting Review</h4>
                               </div>

                            <LottieAnimation
                                height={200}
                                width={200}
                                animationData={checkAnimation}
                                loop={false}
                              />
                            <p className="mb-30 mt-30 text-left">Thank you for uploading your document.</p>
                            <p className="mb-30 mt-30 text-left">The review process takes 1-2 days, once your document is approved, you'll be able to receive your first transaction!</p>
                            <p className="mb-30 mt-30 text-left">Login again in a few days to check on your verification status.</p>
                            <h5>Document(s) Uploaded:</h5>
                            {documents.map(item => {
                              return (
                                <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                                       <p className="text-left">Document: {item.type} </p>
                                       <p className="text-left">Status: {item.documentVerificationStatus}</p>
                                   </div>
                              )
                            })}
                            </div>
                            ) : (
                                <div>

                                <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                                       <h4 className="text-left ">Status:</h4>
                                       <h4 className="text-left" style={{color: "#F54725"}}>Review failed</h4>
                                   </div>

                                <h5 className="text-center">Previous document(s) Uploaded:</h5>
                                {documents.map(item => {
                                  return (
                                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                                           <p className="text-left">Document: {item.type} </p>
                                           <p className="text-left">Status: {item.documentVerificationStatus}</p>
                                       </div>
                                  )
                                })}
                                <p className="mb-30 mt-30 text-left">Looks like the last document you submitted failed verification.</p>
                                <p className="mb-30 mt-30 text-left">Please try again making sure the Name and/or Business Name matches the ones on file.</p>
                                </div>
                            )}
                            {documentUploaderHidden === true ? (
                            <button
                                className="button button-primary-cancel button-block mt-20 w-button"
                                onClick={() => setDocumentUploaderHidden(false)}
                              >
                                Upload another document
                              </button>) : null}

                          </div>
                        ) : (
                        <div>
                           <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: "10px"}}>
                               <h4 className="text-left ">Action Required:</h4>
                               <h4 className="text-left" style={{color: "#F54725"}}>Upload Document</h4>
                           </div>
                           <p className=" text-left mb-30 mt-30">
                             We just need to verify your business, to help us do so please upload a document such as a EIN Letter or your Driver's Licence if you
                             are a Sole Proprietor.
                           </p>
                           <p className="text-left mb-30">
                               Please make sure the Name and/or Business Name on your document matches the ones on file.
                           </p>
                        </div>
                        )}
                      {documentUploadingError ? (
                        <div>
                            <h4 style={{ color: "red", textAlign: "center"}}>Something went wrong :(</h4>
                            <h4 style={{marginBottom: "50px", textAlign: "center"}}>If the issue persists, try again later or contact us for assistance.</h4>
                        </div>
                      ): null}
                      {documentUploaderHidden === false ? (
                          <div>
                        { documentUploading ? (
                            <LottieAnimation
                                height={100}
                                width={100}
                                animationData={loadingAnimation}
                                loop={true}
                              />
                        ) : (
                            <div>
                            <input
                                className="input w-input"
                                type="file"
                                name="file"
                                accept="image/jpg, image/jpeg, application/pdf, image/png"
                                onChange={changeHandler}
                              />
                                <div className="mb-10">Document Type</div>
                                <select
                                  className="input w-input"
                                  onChange={e => {
                                    setDocumentType(e.target.value)
                                    console.log(e.target.value)
                                  }}
                                >
                                  <option defaultChecked="true" value={"license"}>
                                    License
                                  </option>
                                  <option value={"passport "}>Passport</option>
                                  <option value={"idCard"}>ID Card</option>
                                  <option value={"other"}>Document</option>
                                </select>
                                <div>
                                  <button
                                    className="button button-primary button-block mt-20 w-button"
                                    onClick={postDwollaDocuments}
                                  >
                                    Submit
                                  </button>
                                </div>
                            </div>
                        )}
                        </div>
                    ) : null}
                    <button
                        className="button button-primary-cancel button-block mt-20 w-button"
                        onClick={() => setFormDisplayed("retry")}
                      >
                        Edit Business Information
                      </button>
                    </div>
                  ) : (

    <div className="w-form" style={{margin: "0 0 0px"}}>
      <form
        id="verify-customer"
        name="verify-customer"
        data-name="verify customer"
        onSubmit={handleSubmit(submitCustomerInfo)}
      >
            <div>
                  <label style={{ color: "black" }}>
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                    &nbsp; Are you a Non-Profit organization?
                  </label>
                </div>
            <div style={{ width: "48%", display: "inline-block", marginRight: "2%", marginTop: "30px" }}>
                  <label htmlFor="firstName" className="label">
                    First Name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.firstName ? "true" : "false"}
                    {...register("firstName", {
                      required: "First name is required."
                    })}
                    defaultValue={customer.firstName}
                    className="input w-input"
                    maxLength="256"
                    name="firstName"
                    placeholder="Your first name"
                    id="firstName"
                  />
                  {errors.firstName && (
                    <div className="error-message">
                      <div>{errors.firstName.message}</div>
                    </div>
                  )}
            </div>
            <div style={{ width: "48%", display: "inline-block", marginLeft: "2%" }}>
                  <label htmlFor="lastName" className="label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.last_name ? "true" : "false"}
                    {...register("lastName", {
                      required: "Last name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="lastName"
                    defaultValue={customer.lastName}
                    placeholder="Your last name"
                    id="lastName"
                  />
                  {errors.last_name && (
                    <div className="error-message">
                      <div>{errors.last_name.message}</div>
                    </div>
                  )}
            </div>
        <div className="form-group mt-10">
            <div className="form-group" >
                  <label htmlFor="businessName" className="label">
                    Legal Business name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.businessName ? "true" : "false"}
                    {...register("businessName", {
                      required: "Business name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    defaultValue={customer.businessName}
                    name="businessName"
                    placeholder="Enter your company name"
                    id="businessName"
                    onchange={()=> setbname(document.getElementById("businessName").value)}
                  />
                  {errors.businessName && (
                    <div className="error-message">
                      <div>{errors.businessName.message}</div>
                    </div>
                  )}
            </div>
                <div className="mb-20">
                <label htmlFor="businessType" className="label">
                  Business Type
                </label>
                <Select className="form-dropdown"
                  name="businessType"
                  id="businessType"
                  options={businessOptions}
                  defaultValue={
                  customer.businessType === ""
                      ? businessOptions[0]
                      : customer.businessType === "soleProprietorship"
                      ? businessOptions[1]
                      : customer.businessType === "partnership"
                      ? businessOptions[2]
                      : customer.businessType === "llc"
                      ? businessOptions[3]
                      : customer.businessType === "partnership"
                      ? businessOptions[4]
                      : businessOptions[0]
                  }
                  onChange={e => {
                    setBusinessType(e.value)
                  }}
                />
                </div>
        </div>
        {businessType === "soleProprietorship" ? (
          <div>
            <div className="mb-10">
                <label htmlFor="controllerDateOfBirth" className="label">
                  Date of Birth
                </label>
                <ReactInputDateMask
                mask='mm-dd-yyyy'
                showMaskOnFocus={true}
                className={"w-input"}
                name="controllerDateOfBirth"
                id="controllerDateOfBirth"
                onChange={(date) => updateDate(date)}
                value={""}
                showMaskOnHover={true}/>
            </div>
            <div className="form-group">
              <label htmlFor="controllerSsn" className="label">
                  Social Security Number
              </label>
              <input
                type="number"
                aria-invalid={errors.ownerssn ? "true" : "false"}
                {...register("controllerSsn", {
                  required: "Your SSN (Social Security Number) is required."
                })}
                className="input w-input"
                maxLength="9"
                name="controllerSsn"
                placeholder={"SSN e.g. 123-56-7890"}
                id="controllerSsn"
                />
              {errors.ssn && (
                <div className="error-message">
                  <div>{errors.ssn.message}</div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {businessType !== "" ? (
        <div>
        <h4 className="mt-30 mb-30" style={{ textAlign: "center"}}>Business Information</h4>
        <div className="form-group">
          <label htmlFor="address" className="label">
            Address
          </label>
          <input
            type="text"
            aria-invalid={errors.address ? "true" : "false"}
            {...register("address", {
              required: "The business' Address is required."
            })}
            className="input w-input"
            maxLength="256"
            defaultValue={customer.address1}
            name="address"
            placeholder="Enter Business Address"
            id="address"
           />
          {errors.address && (
            <div className="error-message">
              <div>{errors.address.message}</div>
            </div>
          )}
        </div>
        <div
          className="form-group"
          style={{ width: "30%", display: "inline-block", marginRight: "3%" }}
        >
          <label htmlFor="city" className="label">
            City
          </label>
          <input
            type="text"
            aria-invalid={errors.city ? "true" : "false"}
            {...register("city", {
              required: "The business' City is required"
            })}
            className="input w-input"
            maxLength="256"
            defaultValue={customer.city}
            name="city"
            placeholder="City"
            id="city"
            />
          {errors.city && (
            <div className="error-message">
              <div>{errors.city.message}</div>
            </div>
          )}
        </div>
        <div
          className="form-group"
          style={{ width: "30%", display: "inline-block", margin: "1.5%" }}
        >
          <label htmlFor="state" className="label">
            State
          </label>
          <input
            type="text"
            aria-invalid={errors.state ? "true" : "false"}
            {...register("state", {
              required: "The business' State is required."
            })}
            className="input w-input"
            maxLength="2"
            defaultValue={customer.state}
            name="state"
            placeholder="e.g. UT"
            id="state"
            />
          {errors.state && (
            <div className="error-message">
              <div>{errors.state.message}</div>
            </div>
          )}
        </div>
        <div
          className="form-group"
          style={{ width: "30%", display: "inline-block", marginLeft: "3%" }}
        >
          <label htmlFor="postalCode" className="label">
            Postal Code
          </label>
          <input
            type="text"
            aria-invalid={errors.postalCode ? "true" : "false"}
            {...register("postalCode", {
              required: "The business' Postal Code is required."
            })}
            className="input w-input"
            maxLength="256"
            defaultValue={customer.postalCode}
            name="postalCode"
            placeholder="Postal Code"
            id="postalCode"
             />
          {errors.postalCode && (
            <div className="error-message">
              <div>{errors.postalCode.message}</div>
            </div>
          )}
        </div>
        {businessType !== "soleProprietorship" ? (
          <div className="form-group">
            <label htmlFor="ein" className="label">
              EIN
            </label>
            <input
              type="text"
              aria-invalid={errors.ein ? "true" : "false"}
              {...register("ein", {
                required: "The business' EIN (Employer Identification Number) is required."
              })}
              className="input w-input"
              maxLength="256"
              defaultValue={customer.ein}
              name="ein"
              placeholder="EIN e.g 12-3456789"
              id="ein"
              />
            {errors.ein && (
              <div className="error-message">
                <div>{errors.ein.message}</div>
              </div>
            )}
            <h4 className="mb-30 mt-30" style={{ textAlign: "center"}}>Controller Information</h4>
              <div className="form-group" style={{ width: "48%", display: "inline-block", marginRight: "2%" }} >
                  <label htmlFor="controllerFirstName" className="label">
                    First Name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerFirstName ? "true" : "false"}
                    {...register("controllerFirstName", {
                      required: "The controller's First name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="controllerFirstName"
                    defaultValue={customer.controller ? customer.controller.firstName : ""}
                    placeholder="Your first name"
                    id="controllerFirstName"
                    />
                  {errors.controllerFirstName && (
                    <div className="error-message">
                      <div>{errors.controllerFirstName.message}</div>
                    </div>
                  )}
                </div>
                <div
                  className="form-group"
                  style={{ width: "48%", display: "inline-block", marginLeft: "2%" }}
                >
                  <label htmlFor="controllerLastName" className="label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerLastName ? "true" : "false"}
                    {...register("controllerLastName", {
                      required: "The controller's Last name is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="controllerLastName"
                    defaultValue={customer.controller ? customer.controller.lastName : ""}
                    placeholder="Your last name"
                    id="controllerLastName"
                    />
                  {errors.controllerLastName && (
                    <div className="error-message">
                      <div>{errors.controllerLastName.message}</div>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="controllerTitle" className="label">
                    Title
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerTitle ? "true" : "false"}
                    {...register("controllerTitle", {
                      required: "The controller's Title is required."
                    })}
                    className="input w-input"
                    name="controllerTitle"
                    defaultValue={customer.controller ? customer.controller.title : ""}
                    placeholder={("President, CFO, ...")}
                    id="controllerTitle"
                    />
                  {errors.controllerTitle && (
                    <div className="error-message">
                      <div>{errors.controllerTitle.message}</div>
                    </div>
                  )}
                </div>

                <div className="mb-10">
                    <label htmlFor="controllerDateOfBirth" className="label">
                      Date of Birth
                    </label>
                    <ReactInputDateMask
                    mask='mm-dd-yyyy'
                    showMaskOnFocus={true}
                    className={"w-input"}
                    defaultValue={customer.controller ? customer.controller.dateOfBirth : ""}
                    name="controllerDateOfBirth"
                    id="controllerDateOfBirth"
                    onChange={(date) => updateDate(date)}
                    value={""}
                    showMaskOnHover={true}/>
                </div>
                <div className="form-group">
                  <label htmlFor="controllerSsn" className="label">
                    SSN
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerSsn ? "true" : "false"}
                    {...register("controllerSsn", {
                      required: "The controller's SSN (Social Security Number) is required."
                    })}
                    className="input w-input"
                    maxLength="9"
                    defaultValue={customer.controller ? customer.controller.ssn : ""}
                    name="controllerSsn"
                    placeholder={"SSN e.g. 123-56-7890"}
                    />
                  {errors.ssn && (
                    <div className="error-message">
                      <div>{errors.ssn.message}</div>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="controllerAddress" className="label">
                    Address
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerAddress ? "true" : "false"}
                    {...register("controllerAddress", {
                      required: "The controller's Address is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="controllerAddress"
                    defaultValue={customer.controller ? customer.controller.address.address1 : ""}
                    placeholder="Enter the Controller's Address"
                    id="controllerAddress"
                    />
                  {errors.address && (
                    <div className="error-message">
                      <div>{errors.address.message}</div>
                    </div>
                  )}
                </div>
                <div
                  className="form-group"
                  style={{ width: "30%", display: "inline-block", marginRight: "3%" }}
                >
                  <label htmlFor="controllerCity" className="label">
                    City
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerCity ? "true" : "false"}
                    {...register("controllerCity", {
                      required: "The controller's City is required."
                    })}
                    className="input w-input"
                    defaultValue={customer.controller ? customer.controller.address.city : ""}
                    maxLength="256"
                    name="controllerCity"
                    placeholder="City"
                    id="controllerCity"
                    />
                  {errors.city && (
                    <div className="error-message">
                      <div>{errors.city.message}</div>
                    </div>
                  )}
                </div>
                <div
                  className="form-group"
                  style={{ width: "30%", display: "inline-block", margin: "1.5%" }}
                >
                  <label htmlFor="controllerState" className="label">
                    State
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerState ? "true" : "false"}
                    {...register("controllerState", {
                      required: "The controller's State is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    defaultValue={customer.controller ? customer.controller.address.stateProvinceRegion : ""}
                    name="controllerState"
                    placeholder="e.g. UT"
                    id="controllerState"
                    />
                  {errors.state && (
                    <div className="error-message">
                      <div>{errors.state.message}</div>
                    </div>
                  )}
                </div>
                <div
                  className="form-group"
                  style={{ width: "30%", display: "inline-block", marginLeft: "3%" }}
                >
                  <label htmlFor="controllerPostalCode" className="label">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.controllerPostalCode ? "true" : "false"}
                    {...register("controllerPostalCode", {
                      required: "The Postal Code is required."
                    })}
                    className="input w-input"
                    maxLength="256"
                    name="controllerPostalCode"
                    defaultValue={customer.controller ? customer.controller.address.postalCode : ""}
                    placeholder="Postal Code"
                    id="controllerPostalCode"
                     />
                  {errors.postalCode && (
                    <div className="error-message">
                      <div>{errors.postalCode.message}</div>
                    </div>
                  )}
                </div>
                <label htmlFor="controllerCountry" className="label">
                  Country
                </label>
                <input
                  type="text"
                  aria-invalid={errors.controllerCountry ? "true" : "false"}
                  {...register("controllerCountry", {
                    required: "The controller's Country is required."
                  })}
                  className="input w-input"
                  maxLength="2"
                  name="controllerCountry"
                    defaultValue={customer.controller ? customer.controller.address.country : ""}
                  placeholder="e.g. US"
                  id="controllerCountry"
                  />
                {errors.controllerCountry && (
                  <div className="error-message">
                    <div>{errors.controllerCountry.message}</div>
                  </div>
                )}
          </div>
        ) : null}

      <div className="mb-20">
        <input
          name="isGoing"
          type="checkbox"
          checked={agreeA}
          style={{ margin: 10 }}
          onChange={() => setAgreeA(!agreeA)}
        />
        I hereby certify that the information provided {businessType !== "soleProprietorship" ? ("for the Controller ") : null}is complete and correct.
      </div>

      <div className="mb-20 mt-30">
          <input
            name="isGoing"
            type="checkbox"
            checked={agreeB}
            style={{ margin: 10 }}
            onChange={() => {
            setAgreeB(!agreeB)
            console.log(formValues)}}
          />
          By checking this box you agree to our{" "}
          <a href="https://join.localight.com/policies#merchant-tos">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="https://join.localight.com/policies#privacy">
            Privacy Policy
          </a>
          , as well as Dwolla's{" "}
          <a href="https://www.dwolla.com/legal/tos/">Terms of Service</a> and{" "}
          <a href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a>
        </div>

      <div className="form-group">
        {agreeA === true && agreeB === true ? (
          <div>
            <button onClick = {send_to_airtable} type="submit" style={{ alignSelf: "center" }}  className="button button-primary mt-20 button-block w-button">
              Submit Business Details
            </button>
            {isSmallLoading ? (
              <LottieAnimation
                height={100}
                width={100}
                animationData={loadingAnimation}
                loop={true}
                />) : (
                <div>
                {errorText !== "" ? (
                  <div>
                    <h4 className="mt-20 mb-10 text-center">Something went wrong when uploading the form:</h4>
                    <p className="mb-40 text-center">{errorText}</p>
                  </div>
                    ) : null }
                </div>
              )}
          </div>
          ) : (
            <button
              type="button"
              disabled="true"
              className="button button-disabled mt-20  button-block w-button"
            >
              Agree to Continue
            </button>
          )}

          { verificationStatus === "retry" && isSmallLoading === false ? (
            <div>
              <h2 className="text-center" style={{color: "#F54725"}}>Oops! Let's Try Again</h2>
              <p className="mt-20 mb-40 text-center">
                Something appears to be incorrect.
                <br />
                Please double-check your information above.
              </p>
            </div>
          ) : null}
      </div>

        </div>
        ) : <h4 className="mt-30" style={{ textAlign: "center"}}> Select a Business Type to continue</h4>}
      </form>
      <div className="success-message w-form-done">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="error-message w-form-fail">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
      {verificationStatus === "document" ? (
      <button
          className="button button-primary-cancel button-block mt-20 w-button"
          onClick={() => setFormDisplayed("document")}
        >
          Upload Document
        </button>
      ) : null}
    </div>

    )}
    </div>
  )
}

export default VerifiedCustomer;

