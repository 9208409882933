import React from "react";

import AuthContainer from '../../containers/Client/AuthContainer';

const CustomerSignup = (props) => {


    return <>
        <AuthContainer {...props} />
    </>
};

export default CustomerSignup;
